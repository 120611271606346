import React from "react";
import "react-toastify/dist/ReactToastify.css";

  function Salary(props) {

  


  return (
    <div>
      
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
              <div className="card-title">Salary Under Construction</div>{" "}
              <br/>
              <br/>
              <br/>
             
            
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
 
 
    
    </div>
  );
}
 
export default Salary;