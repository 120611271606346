import React, { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "../api/baseurl";
import { toast} from "react-toastify";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";

const CreateLabCorporate = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate();

  const url = "/api/CreateCompanyandLab";
  const [labCorporateDetails, setLabCorporateDetails] = useState(
    {
     username:"",
     role:"ROLE_LAB_CORPORATE",
     labCorporate:{
     email:"",
     name:"",
     orgcode:"",
     contact:"",
     pan:"",
     gstin:"",
     tan:"",
     address:"",
     city:"",
     pincode:"",
     state:"",
     clientId:""
     }
      },
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      try {
        const emailCheckResponse = await axios.get(`/api/accounts/getByEmail=${labCorporateDetails.email}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (emailCheckResponse.data) {
          toast.error("Email already exist.");
          setLoading(false);
          return;
        }
      } catch (error) {
        handleError(error);
        setLoading(false);
        return;
      }
      axios
        .post(url, {
          username: labCorporateDetails.email,
          role: "ROLE_LAB_CORPORATE",
          labCorporate: {
            email:labCorporateDetails.email,
            name: labCorporateDetails.name,
            orgcode:labCorporateDetails.orgcode,
            contact:labCorporateDetails.contact,
            pan:labCorporateDetails.pan,
            gstin:labCorporateDetails.gstin,
            tan:labCorporateDetails.tan,
            address:labCorporateDetails.address,
            city:labCorporateDetails.city,
            pincode:labCorporateDetails.pincode,
            state:labCorporateDetails.state,
            clientId:labCorporateDetails.clientId
          }
        }, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          setLoading(false);
          onRequestClose();
          setLabCorporateDetails("");
          toast("Lab Corporate account created successfully and Password sent to their email");
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
  }

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  function rhandle(e) {
    const { id, value } = e.target;
    setLabCorporateDetails((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
    <Modal.Title>Add Lab Corporate Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {loading && <div className="loading"></div>} 
    <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="name">Name <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      placeholder="Name"
                      value={labCorporateDetails.name}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                   </div>
                  </div>
               
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="orgcode">Orgcode <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="orgcode"
                      type="text"
                      placeholder="Orgcode"
                      value={labCorporateDetails.orgcode}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
               </div>
               </div>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="email">Email <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      placeholder="Email"
                      value={labCorporateDetails.email}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="contact">Contact Number <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="contact"
                      type="text"
                      placeholder="contact number"
                      value={labCorporateDetails.contact}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>

               
                <div className="row">
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="gstin">GSTIN <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="gstin"
                      type="text"
                      placeholder="GSTIN"
                      value={labCorporateDetails.gstin}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="pan">PAN </label>
                    <input
                      className="form-control"
                      id="pan"
                      type="text"
                      placeholder="PAN"
                      value={labCorporateDetails.pan}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="tan">TAN</label>
                    <input
                      className="form-control"
                      id="tan"
                      type="text"
                      placeholder="TAN"
                      value={labCorporateDetails.tan}
                      onChange={(e) => rhandle(e)}
                    />
                    
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="clientId">Client Code<span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="clientId"
                      type="text"
                      placeholder="Client Code"
                      value={labCorporateDetails.clientId}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>
                <br/>
                <div><h5>Lab Corporate Address:</h5></div>
             
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="address">Address<span style={{color:"red"}}>* </span></label>
                    <textarea
                      className="form-control"
                      id="address"
                      type="text"
                      placeholder="Address"
                      value={labCorporateDetails.address}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="city">City <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="city"
                      type="text"
                      placeholder="City"
                      value={labCorporateDetails.city}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="pincode">Pincode <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="pincode"
                      type="text"
                      placeholder="Pincode"
                      value={labCorporateDetails.pincode}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="state">State <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="state"
                      type="text"
                      placeholder="State"
                      value={labCorporateDetails.state}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>
                
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
            </div>
          </div>
        </div>
              </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
}
export default CreateLabCorporate;
