import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import "./Spinner.css";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
const DownloadDocuments = ({ isOpen, onRequestClose}) => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
  
    const getData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/documentation`, {
        });
        setDocuments(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    };
  
    const handleError = (error) => {
      if (error.response && error.response.status === 401) {
        navigate("/signin");
        toast.error("Your session has expired. Please login.");
      } else {
        toast.error(
          error.response?.data?.message || error.response?.data?.code || "An error occurred."
        );
      }
    };
  
    const handleDownload = (fileMeta, fileContent, fileName) => {
        const linkSource = `data:${fileMeta};base64,${fileContent}`; // Assuming base64 encoded data
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName || "document.pdf"; // Use provided fileName or default
        downloadLink.click();
      };
      
    useEffect(() => {
      getData();
    }, []);
  
    return (
      <Modal show={isOpen} onHide={onRequestClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <div className="loading"></div>}
          <table className="table">
          <thead>
                <tr>
                  <th>Document Name</th>
                  <th>File</th>
                </tr>
              </thead>
              <tbody>
                {documents.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No Documents Available
                    </td>
                  </tr>
                ) : (
                  documents.map((doc) => (
                    <tr key={doc.id}>
                      <td>{doc.fileName}</td>
                      <td> <button
       
        style={{ cursor: "pointer" }}
        onClick={() => handleDownload(doc.fileMeta, doc.file, doc.fileName)}
      >
        <FontAwesomeIcon icon={faDownload} /> Download
      </button></td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
  };
  
  export default DownloadDocuments;
  