import React, { useState, useEffect } from "react";
import "./Loader.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
function ViewAdminProfileBySuper() {
    const {userid} =useParams();
  const url = `/api/user/profile/userid=${userid}`;

  const [image, setImage] = useState("");
  const [password, setPassword] = useState('');

  const [profile, setProfile] = useState({
    userId: "",

    name: "",

    lastname: "",

    phone: "",

    email: "",

    company: "",

    country: "",

    image: null,

    imageMeta: "",
    firstname: "",
    orgProfile:{
      officeLatitude:"",
      officeLongitude:"",
      godownLatitude:"",
      godownLongitude:"",  
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode 
  }
  });
  const [loading, setLoading] = useState(false);
  
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handleImageChange = async (event) => {
    const convertedImage = await getBase64FromFile(event.target.files[0]);

    const imageObject = {
      ...profile,

      imageMeta: convertedImage.metadata,

      image: convertedImage.content,
    };

    setProfile(imageObject);
  };

  const getData = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setLoading(false);
    setProfile(data);
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      const body = {
        id: userid,
        password: password,
      };

      await axios.post(`/api/accounts/update-password`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success("Password Updated Successfully");
      setPassword("");
    } catch (error) {
     handleError(error);
    } finally {
      setLoading(false);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  const handleChange = (event, field) => {
    if (field in profile.orgProfile) {
      setProfile({
        ...profile,
        orgProfile: { ...profile.orgProfile, [field]: event.target.value },
      });
    } else {
      setProfile({ ...profile, [field]: event.target.value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(profile.image);

    // const token = JSON.parse(localStorage.getItem("data")).token;

    //const convertedImage = await getBase64FromFile(profile.image);

    //console.log(convertedImage);

    console.log(profile);

    const token = JSON.parse(localStorage.getItem("data")).token;

    // const imageObject = {

    //   ...profile,

    //   imageMeta: convertedImage.metadata   ,

    //      image:convertedImage.content,

    // };

    //submit the data to server to generate token

    const res1 = axios

      .post("/api/user/profile/update", profile, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((response) => response.data)

      .then((result) => {
        console.log(result);
        setLoading(false);
        localStorage.setItem("profile", JSON.stringify(result));
        toast.success("Profile Updated");

        navigate("/users/adminprofile");
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 404) {
          toast.error(error.response.data.message);
        } else {
          toast.error("something went wrong on server !!");
        }
        setLoading(false);
      });
  };

  return (
    <div>
    <div>
      <AdminDashboard />
    </div>    
     {loading && <div className="loading"></div>} 
    
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
              <div className="card-title">Profile Details</div>{" "}
              <br/>
              <br/>
              <br/>
              <div >
                {profile.image ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="image"
                    src={`${profile?.imageMeta},${profile?.image}`}
                    alt="User Profile"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="image"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",

                    }}
                  >
                    {/* <FaRegUserCircle size={250} /> */}
                  </div>
                )}
                <input
                  className="col-7"
                  type="file"
                  id="image"
                  // style={{ marginLeft: "10%" }}
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    handleImageChange(event);
                  }}
                  accept="image/*"
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label for="name">Name :</label>
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      value={profile.name}
                      onChange={(e) => handleChange(e, "name")}
                    />
                  </div>
                  </div>
                
              
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label for="ohone">Phone Number :</label>
                    <input
                      className="form-control"
                      id="phone"
                      type="text"
                      value={profile.phone}
                      onChange={(e) => {
                        const inputText = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                        const limitedText = inputText.slice(0, 10); // Limit to 10 characters
                        setProfile({ ...profile, phone: limitedText }); // Update the state with limited text
                      }}
                    />
                  </div>
                </div>
                

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label for="organization">Email ID :</label>
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      value={profile.email}
                      readOnly
                      // onChange={(e) => handleChange(e,"email")}
                    />
                  </div>
                </div>
        
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label for="dob">Date Of Birth :</label>
                    <input
                      className="form-control"
                      id="dob"
                      type="date"
                      value={profile.dob}
                      onChange={(e) => handleChange(e, "dob")}
                    />
                  </div>
                </div>
          

                {/* <div className="row"> */}
               

                {/* <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="pan">PAN :</label>
                    <input
                      className="form-control"
                      id="pan"
                      type="text"
                      value={profile.pan}
                      onChange={(e) => handleChange(e, "pan")}
                    />
                  </div>
                </div> */}
                {/* </div> */}

                {/* <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="adharNumber">Aadhar Number :</label>
                    <input
                      className="form-control"
                      id="adharNumber"
                      type="text"
                      value={profile.adharNumber}
                      onChange={(e) => handleChange(e, "adharNumber")}
                    />
                  </div>
                </div>
               
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="addressName">Address :</label>
                    <textarea
                      className="form-control"
                      id="addressName"
                      type="text"
                      value={profile.address}
                      onChange={(e) => handleChange(e, "address")}
                      rows="2"
                    />
                  </div>
                </div>
                </div> */}
          
                {/* <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="addressProof">Upload Address Proof</label>
    {handleFilePreview(profile.imageMeta, profile.addressProof)}

    <input
      className="form-control"
      id="addressProof"
      type="file" 
      onChange={(e) => handleImageChange0(e, "addressProof")} 
      accept="image/jpeg, image/png, application/pdf" 
    />
  </div>
</div>
                
               */}
            
                <br />
                <input
                  type="submit"
                  value="save changes"
                  className="btn btn-primary"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mx-xl-auto ps-xl-10 mt-3">
      <div className="card card-raised mb-5">
        <div className="card-body">
      <div className="card-title mb-3">Update Password</div>
      <form className="d-flex justify-content-center" onSubmit={handlePasswordSubmit}>
            <div className="col-md-6 mt-1">
              <div className="form-floating-sm mb-2">
                <label htmlFor="password">Password :</label>
                <input
                  className="form-control"
                  id="password"
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="row justify-content-center">
          <div className="col-md-6 text-center mt-4">
            <button type="submit" className="btn btn-primary">Update</button>
          </div>
        </div>
          </form>
    </div>
  </div>
  </div>
    </div>
  );
}
export default ViewAdminProfileBySuper;
