import React, { useEffect, useState } from "react";
import {Tab, Tabs } from 'react-bootstrap';  
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import CompanyProfile from "./companyProfile";
import EmployeesDetails from "./EmployeesDetails";
import Designations from "./Designations";
import InvoicesForCompany from "./invoicesForCompany";
import CompanyDoctorsDetails from "./companyDoctorsDetails";
import GeoLocations from "./geoLocations";
 
  function CompanyDetailsTab() {

  const { orgcode } = useParams();


  const[loading ,setLoading]=useState(false);
  const [activeTab, setActiveTab] = useState('companyprofile');
  // const [selectedOrgcode, setSelectedOrgcode] = useState(orgcode);

  useEffect(() => {
  
  }, []);

  

  return (
    <div>
      <AdminDashboard />
      <br/>
              <br/>
              <br/>
      <br />


<div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <Tabs
              id="consumer-bio-tabs"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
              className="mb-3"
            >
              <Tab eventKey="companyprofile" title="Company Profile">
              {activeTab === 'companyprofile' && (
                <CompanyProfile selectedOrgcode={orgcode} />
              )}
              </Tab>
              <Tab eventKey="employees" title="Employees Details">
              {activeTab === 'employees' && (
                <EmployeesDetails selectedOrgcode={orgcode} />
              )}
              </Tab>

              <Tab eventKey="doctors" title="Doctors Details">
              {activeTab === 'doctors' && (
                <CompanyDoctorsDetails selectedOrgcode={orgcode} />
              )}
              </Tab>

              <Tab eventKey="designations" title="Designations">
                {activeTab === "designations" && <Designations selectedOrgcode={orgcode}/>}
              </Tab>

              <Tab eventKey="invoices" title="Invoices">
                {activeTab === "invoices" && <InvoicesForCompany selectedOrgcode={orgcode}/>}
              </Tab>

              <Tab eventKey="locations" title="Locations">
                {activeTab === "locations" && <GeoLocations selectedOrgcode={orgcode}/>}
              </Tab>
          
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default CompanyDetailsTab;