import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Pagination, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AddDoctors from "./addDoctors";
import UploadDoctorsCsvFile from "./uploadDoctorsCsv";
import DoctorCsvFormat from "./doctorCsvFormat";

function CompanyDoctorsDetails({selectedOrgcode}) {
  const [doctorsDetails, setDoctorsDetails] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCsvUplaodModalOpen, setIsCsvUplaodModalOpen] = useState(false);
const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(0);
const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (searchTerm) {
      searchData();
    } else {
      getData(currentPage);
    }
  }, [searchTerm, currentPage]);

 
  const searchData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
   

    try {
      const searchUrl =`/api/doctordetails/getByCompanycode/${selectedOrgcode}`;
      const response = await axios.get(searchUrl,  {
        headers: { Authorization: `Bearer ${token}` },
      });

      setDoctorsDetails(response.data);
      setLoader(false);

    } catch (error) {
      handleError(error);
    }
  };
  const getData = async (pageNumber = 0) => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      const trans = await axios.get(`/api/doctordetails/companycode=${selectedOrgcode}`, {
        params: { page: pageNumber }, 
        headers: { Authorization: `Bearer ${token}` },
      });
  
      console.log(trans);
  
      setDoctorsDetails(trans.data.content);
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };
  function onClickhandler(e, id, disabled) {
    // setLoading(id);
    setLoading(true)
    const checked = e.target.checked;
   console.log(checked);
    const token = JSON.parse(localStorage.getItem("data")).token;
   
    console.log(token);
    axios.post(`/api/doctordetails/update`, {
      id: id,
      disabled: disabled
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false);
        toast.success("Status updated successfully");
      })
      .catch((error)=>{
        setLoading(false);

        handleError(error);
      })
  }
  const handleAddEmployee = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();

  };

  const handleUploadEmployeeCsv = () => {
    setIsCsvUplaodModalOpen(true);
  };

  const handleCloseUploadCsvModal = () => {
    setIsCsvUplaodModalOpen(false);
    getData();

  };
  const filteredData = doctorsDetails.filter((agent) =>
    (agent.email && agent.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (agent.doctorName && agent.doctorName.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  

  const openUserProfileModal = async (userid) => {
    navigate(`/users/doctor-profile/${userid}`);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const [showImageModal, setShowImageModal] = useState(false);

  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  
 
  return (
    <div>
    
    {loading && <div className="loading"></div>} 
    <div className="doctors-details">
      <div className="card card-raised mb-5">
        <div className="card-body">
      
            <div className="card-title form-group d-flex align-items-center justify-content-between"> 
              <span>Doctors Details</span>
  <div>
  <button className="btn btn-primary me-3" onClick={handleShowImageModal}>
                View Format
              </button>
    <button className="btn btn-primary me-3" onClick={handleUploadEmployeeCsv}>
      UPLOAD CSV
    </button>
    <button className="btn btn-primary" onClick={handleAddEmployee}>
      ADD
    </button>
  </div>
           </div>

            <div className="row">
      <div style={{ position: "relative", display: "flex", alignItems: "center",  left:"380px" }}>

      <input
      type='text'
      className='form-control form-control w-450px ps-14'
      placeholder='Search by Name or Email'
      value={searchTerm}
      style={{ width: "210px" }}

      onChange={(e) => setSearchTerm(e.target.value)}
    />
        <span className="position-absolute" style={{ left: "14px", top: "50%", transform: "translateY(-50%)" }}>
                  <FontAwesomeIcon icon={faSearch} />
                </span>
       
       
      </div>
      </div>
            <br/>
            <Row>
            {filteredData.map((accd) => (
              
            <Col key={accd.id} md={4} className="mb-3">
<div className="card card-raised h-100" onClick={() => openUserProfileModal(accd.userid)} style={{cursor:"pointer"}} title="View Profile">  
    <div className="card-body">
<Card>
                <Card.Body>
                <Card.Text className="d-flex justify-content-end">
                          {/* {loading == accd.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "} */}
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={accd.disabled === false}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onClickhandler(e, accd.id, !accd.disabled);
                            }}
                            title="Update Status"
                            value={accd.id}
                          />
                         
                    {/* <MdDeleteForever
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteAccount(accd.id);
                      }}
                      title="Delete Account"
                    /> */}
                                           </Card.Text>
                {/* <Card.Text className="d-flex justify-content-end">
       
                    <MdDeleteForever
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteAccount(accd.id);
                      }}
                      title="Delete Account"
                    />
                                           </Card.Text> */}
                {/* <Card.Text>Id: {accd.id}</Card.Text> */}
             
                <div className="card-title text-center">
        {accd.image && accd.image ? (
          <img 
            src={`${accd.imageMeta},${accd.image}`} 
            alt="User Profile" 
            style={{ width: '100px', height: '100px', borderRadius: '50%' }} 
          />
        ) : (
          <FaRegUserCircle />
        )}
      </div>
                   <div className="card-title text-center">
 
                <Card.Text>{accd.doctorName}</Card.Text>
                </div>
                  <br/>
                  <Card.Text>Email: {accd.email}</Card.Text>
                  <Card.Text>Phone Number: {accd.phoneNumber}</Card.Text>
                  {/* <Card.Text>Designation: {accd.designation}</Card.Text>
                  <Card.Text>Date of Joining: {accd.dateOfJoining}</Card.Text> */}
                  {/* <Card.Text className="card-footer bg-light">Employee Id: {accd.empid}</Card.Text> */}
               
                </Card.Body>
              </Card>
              </div>
              </div>
             
              </Col>
             
          ))}
                </Row>
                <div className="d-flex justify-content-center">
                <Pagination>
                  <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                  {renderPageNumbers()}
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                  <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
                </Pagination>
              </div>
            </div>
          </div>
        {isModalOpen && (
          <AddDoctors isOpen={isModalOpen} onRequestClose={handleCloseModal} companycode={selectedOrgcode}/>
        )}
         {isCsvUplaodModalOpen && (
              <UploadDoctorsCsvFile isOpen={isCsvUplaodModalOpen} onRequestClose={handleCloseUploadCsvModal} companycode={selectedOrgcode} />
                )}

{showImageModal && (
           <DoctorCsvFormat isOpen={showImageModal} onRequestClose={handleCloseImageModal} />
           )}
        </div>
      </div>
    );
}

export default CompanyDoctorsDetails;
