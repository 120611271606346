import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import "./Spinner.css";
import "./Loader.css";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import UploadLabsCsvFile from "./uploadLabCsvFile";
import CreateLabDetails from "./addLabDetails";
import LabCsvFormat from "./labCsvFormat";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

function CorporateLabDetails() {
  const [labDetails, setLabDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCsvUplaodModalOpen, setIsCsvUplaodModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLabs, setFilteredLabs] = useState([]);

  const navigate = useNavigate();
  const userid = JSON.parse(localStorage.getItem("data")).userid;
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      const trans = await axios.get(`/api/labcorporate-labs-mapping/userid=${userid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(trans);
      setLabDetails(trans.data);
      setFilteredLabs(trans.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleCreateLab = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const uploadCsv = () => {
    setIsCsvUplaodModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsCsvUplaodModalOpen(false);
    getData();
  };
  const handleViewLabDetails = (orgcode) => {
    navigate(`/users/corporate-lab-profile/${orgcode}`);
  };

  useEffect(() => {
    getData();
  }, []);

  const [showImageModal, setShowImageModal] = useState(false);

  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = labDetails.filter(
      (lab) =>
        lab.labDetails?.labname.toLowerCase().includes(term) || lab.labDetails?.labcode.toLowerCase().includes(term)
    );
    setFilteredLabs(filtered);
  };

  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <div>
        <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
      
          <div className="card card-raised ">
            
            
            <div className="card-body ">
           
            <div className="card-title form-group d-flex align-items-center justify-content-between">
                <span>Lab Details</span>
                <div className="form-group position-relative" style={{ width: '300px' }}> {/* Adjust width as needed */}
  <input
    type="search"
    className="form-control pl-4"
    placeholder="Search by Lab name or Lab code"
    value={searchTerm}
    onChange={handleSearch}
    style={{ paddingLeft: "2rem", width: "100%" }} // Ensure the input takes the full width of the parent div
  />
  <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
    <FontAwesomeIcon icon={faSearch} />
  </span>
</div>
               
              </div>
             
              <br />
              <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                    <th>Lab Name</th>
                    <th>Lab Code</th>
                    <th>Email</th>
                    <th>Contact Number</th>
                  </tr>
                </thead>
                <tbody>
                {filteredLabs.length === 0 ? (
                  
            <tr>
               {/* setLoader(false); */}
              <td colSpan="7" className="text-center">
                No Data Available
              </td>
            </tr>
          
          ) : (

                  filteredLabs.map((accd) => {
                    return (
                      <tr key={accd.name}>
 <td 
  style={{ 
    cursor: 'pointer'
  }}
  onClick={() => handleViewLabDetails(accd.labDetails.labcode)}
  onMouseOver={(e) => { e.target.style.color = 'blue'; e.target.style.textDecoration = 'underline'; }}
  onMouseOut={(e) => { e.target.style.color = ''; e.target.style.textDecoration = ''; }}
>
  {accd.labDetails.labname}
</td>
                        <td>{accd.labDetails.labcode}</td>
                        <td>{accd.labDetails.username}</td>
                        <td>{accd.labDetails.contact}</td>

                        {/* <td  style={{ cursor: 'pointer' }}>
                          <button className="btn btn-link" onClick={() => handleOrganizationDetailsClick(accd.orgcode,accd.userid,accd.username)}>
                            View Profile
                          </button>
                        </td> */}

                       
                        
                      </tr>
                    );
                  }))}
                </tbody>
              </table>
              
              {isModalOpen && (
                  <CreateLabDetails isOpen={isModalOpen} onRequestClose={handleCloseModal} />
                )}
                {isCsvUplaodModalOpen && (
                  <UploadLabsCsvFile isOpen={isCsvUplaodModalOpen} onRequestClose={closeUploadModal} />
                )}

          {showImageModal && (
           <LabCsvFormat isOpen={showImageModal} onRequestClose={handleCloseImageModal} />
           )}
            </div>
          </div>
        </div>
        {loader && <div className="loading"></div>} 
      </div>
    </div>
  );
}

export default CorporateLabDetails;
