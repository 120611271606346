import React, { useState,useEffect } from "react";
import { useNavigate, Link, useParams, } from "react-router-dom";
import axios from "../api/baseurl";
import { toast} from "react-toastify";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";

const AddDoctors = ({ isOpen, onRequestClose, companycode}) => {
  const navigate = useNavigate();
  const url = "/api/CreateCompanyandLab"
  const [doctorDetails, setDoctorDetails] = useState(
    {
     username:"",
     role:"ROLE_DOCTOR",
     doctorDetails:{
        doctorName:"",
        phoneNumber:"",
        gender:"",
        dob:"",
        adharNumber:"",
        companyCode:companycode,
        email:"",
        address:"",
        pan:"",
        image:null,
        imageMeta:"",
     }
      },
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      try {
        const emailCheckResponse = await axios.get(`/api/accounts/getByEmail=${doctorDetails.email}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (emailCheckResponse.data) {
          toast.error("Email already exist.");
          setLoading(false);
          return;
        }
      } catch (error) {
        handleError(error);
        setLoading(false);
        return;
      }
      axios
        .post(url, {
          username: doctorDetails.email,
          role:"ROLE_DOCTOR",
          doctorDetails: {
            doctorName:doctorDetails.doctorName,
            phoneNumber:doctorDetails.phoneNumber,
            gender:doctorDetails.gender,
            dob:doctorDetails.dob,
            adharNumber:doctorDetails.adharNumber,
            companyCode:companycode,
            email:doctorDetails.email,
            address:doctorDetails.address,
            pan:doctorDetails.pan,
            image:doctorDetails.image,
            imageMeta:doctorDetails.imageMeta
          }
        }, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          setLoading(false);
          const regiteredusername = {
            username: doctorDetails.username,
            orgcode: doctorDetails.companyCode,
          };
          localStorage.setItem(
            "RegistrationData",
            JSON.stringify(regiteredusername)
          );
         
          onRequestClose();
          setDoctorDetails("");
          toast("Doctor account created successfully and Password sent to their email");
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
          setDoctorDetails("");
        });
  
  }

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

   
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       const [metadata, content] = reader.result.split(",");
       resolve({ metadata, content });};
       reader.onerror = function (error) {
       reject(error);};}); }

 const handleImageChange=async(event)=>{
   const convertedImage= await getBase64FromFile(event.target.files[0]);
   const imageObject = {...doctorDetails,
         imageMeta: convertedImage.metadata,
         image:convertedImage.content,};
         setDoctorDetails(imageObject);
 }

  function rhandle(e) {
    const { id, value } = e.target;
    setDoctorDetails((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  const [gender, setGender] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const gender = await axios.get(`/api/gender`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setGender(gender.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch designations.");
      }
    };
    fetchData();
  }, []);


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
    <Modal.Title>Create Doctor Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {loading && <div className="loading"></div>} 
    <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-2">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column align-items-center">
              <div className="mb-4">
                {doctorDetails.image ? (
                  <img
                  className="img-fluid mb-1"
                  id="image"
                  src={`${doctorDetails?.imageMeta},${doctorDetails?.image}`}
                  alt="User Profile"
                  style={{
                    width: "150px",
                    height: "150px",
                    display: "block",
                    background: "#ccc",
                    marginTop: "20px",
                    borderRadius: "50%"
                  }}
                />
              ) : (
                <div
                  className="img-fluid mb-1"
                  id="image"
                  style={{
                    width: "150px",
                    height: "150px",
                    display: "block",
                    background: "#ccc",
                    marginTop: "20px",
                    borderRadius: "50%"
                  }}
                ></div>
              )}
              <input
                className="col-7"
                type="file"
                id="image"
                onChange={(event) => {
                  console.log(event.target.files[0]);
                  handleImageChange(event);
                }}
                accept="image/*"
              />
            </div>
            </div>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="doctorName">Name <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="doctorName"
                      type="text"
                      placeholder="Name"
                      value={doctorDetails.doctorName}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                   </div>
                  </div>
                
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="email">Email Id <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      placeholder="Email"
                      value={doctorDetails.email}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="contact">Phone Number <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="phoneNumber"
                      type="text"
                      placeholder="Phone Number"
                      value={doctorDetails.phoneNumber}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
          
                <div className="col-md-6">
                <div className="form-floating-sm mb-2">
                    <label htmlFor="gender">
                      Gender <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="gender"
                      value={doctorDetails.gender}
                      onChange={(e) => rhandle(e)}
                      required
                    >
                      <option value="">Select Gender</option>
                      {gender.map((cat) => (
                        <option key={cat.id} value={cat.gender}>
                          {cat.gender}
                        </option>
                      ))}
                    </select>
                    </div>
                </div>
                </div>
                
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="dob">DOB <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="dob"
                      type="date"
                      placeholder="DOB"
                      value={doctorDetails.dob}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
       
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="pan">PAN <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="pan"
                      type="text"
                      placeholder="PAN"
                      value={doctorDetails.pan}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                    
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="adharNumber">Aadhar Number <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="adharNumber"
                      type="text"
                      placeholder="Aadhar Number"
                      value={doctorDetails.adharNumber}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="address">Address <span style={{color:"red"}}>* </span></label>
                    <textarea
                      className="form-control"
                      id="address"
                      type="text"
                      placeholder="Address"
                      value={doctorDetails.address}
                      onChange={(e) => rhandle(e)}
                      rows="3"
                      required
                    />
                 
                </div>
                </div>
                </div>
                <br/>

                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
            </div>
          </div>
        </div>
              </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
}
export default AddDoctors;
