import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";

 
 
function ViewLabProfile() {
  const userid = JSON.parse(localStorage.getItem("data")).userid;

    const[loading ,setLoading]=useState(false);
    const [password, setPassword] = useState('');
  
    const [orgProfile, setOrgProfile] = useState({
      username:"",
      labname:"",
      labcode:"",
      contact:"",
      description:"",
      pan:"",
      gstin:"",
      tan:"",
      addressLine1:"",
      addressLine2:"",
      city:"",
      pincode:"",
      state:"",
      createddate:"",
      latitude:"",
      longitude:""
    });
    useEffect(() => {
      const fetchProfile = async () => {
        try {
          setLoading(true);
          const token = JSON.parse(localStorage.getItem("data")).token;
          const response = await axios.get(`/api/labdetails/userid=${userid}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const userProfileDetails = response.data;
          setOrgProfile(userProfileDetails);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          handleError(error);
        }
      };
  
      fetchProfile();
    }, []);
  
    function getBase64FromFile(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
         const [metadata, content] = reader.result.split(",");
         resolve({ metadata, content });};
         reader.onerror = function (error) {
         reject(error);};}); }
  
   const handleImageChange=async(event)=>{
     const convertedImage= await getBase64FromFile(event.target.files[0]);
     const imageObject = {...orgProfile,
           imageMeta: convertedImage.metadata,
           image:convertedImage.content,};
           setOrgProfile(imageObject);
   }
  
  
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
  
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        await axios.post(`/api/labdetails/update`, orgProfile, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Lab Profile Updated Successfully");
        setLoading(false);
      } catch (error) {
        setLoading(false);
       handleError(error);   
      }
    };

    const navigate=useNavigate();
    function handleError(error) {
      if (error.response) {
        if (error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        }  else if (error.response.data && error.response.data.code) {
          toast.error(error.response.data.code);
        }
         else {
          toast.error("An error occurred.");
        }
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred.");
      }
    }
  
  const handleChange = (event, field) => {
      let actualValue = event.target.value;
  
      setOrgProfile({ ...orgProfile, [field]: actualValue });
      
    };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
   
      {loading && <div className="loading"></div>} 
        <div className="col-13  mx-xl-auto ps-xl-10">
          <div className="card card-raised " style={{marginTop:"10%"}}>
            <div className="card-body " >
              <div className="card-title">Lab Profile</div>
              <div className="d-flex">
              <div className="me-4">
              {orgProfile.image ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="image"
                    src={`${orgProfile?.imageMeta},${orgProfile?.image}`}
                    alt="User Profile"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="image"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",

                    }}
                  >
                    {/* <FaRegUserCircle size={250} /> */}
                  </div>
                )}
                <input
                  className="col-7"
                  type="file"
                  id="image"
                  // style={{ marginLeft: "10%" }}
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    handleImageChange(event);
                  }}
                  accept="image/*"
                />
              </div>
              <div className="flex-grow-1">
                <form onSubmit={handleSubmit} className="w-100">

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organizationname">Lab Name:</label>
                      <input
                        className="form-control"
                        id="labname"
                        type="text"
                        value={orgProfile.labname}
                        onChange={(e) => handleChange(e, "labname")}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Lab Code:</label>
                      <input
                        className="form-control"
                        id="labcode"
                        type="text"
                        value={orgProfile.labcode}
                        readOnly
                        // onChange={(e) => handleChange(e, "labcode")}
                      />
                    </div>
                    </div>
                  </div>

                  <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Email ID:</label>
                      <input
                        className="form-control"
                        id="username"
                        type="email"
                        value={orgProfile.username}
                        readOnly
                        // onChange={(e) => handleChange(e, "username")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Contact Number:</label>
                      <input
                        className="form-control"
                        id="contact"
                        type="text"
                        value={orgProfile.contact}
                        onChange={(e) => handleChange(e, "contact")}
                      />
                    </div>
                  </div>
                  </div>

                  <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">GSTIN:</label>
                      <input
                        className="form-control"
                        id="gstin"
                        type="text"
                        value={orgProfile.gstin}
                        onChange={(e) => handleChange(e, "gstin")}
                        readOnly
                      />
                    </div>
                  </div>
                 
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="state">PAN:</label>
                      <input
                        className="form-control"
                        id="pan"
                        type="text"
                        value={orgProfile.pan}
                        onChange={(e) => handleChange(e, "pan")}
                        readOnly
                      />
                    </div>
                  </div>

                <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">TAN:</label>
                      <input
                        className="form-control"
                        id="tan"
                        type="text"
                        value={orgProfile.tan}
                        onChange={(e) => handleChange(e, "tan")}
                        readOnly
                      />
                    </div>
                  </div>
                 </div>

                 <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="addressLine1">Address Line 1:</label>
                      <input
                        className="form-control"
                        id="addressLine1"
                        type="text"
                        value={orgProfile.addressLine1}
                        onChange={(e) => handleChange(e, "addressLine1")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="addressLine2">Address Line 2:</label>
                      <input
                        className="form-control"
                        id="addressLine2"
                        type="text"
                        value={orgProfile.addressLine2}
                        onChange={(e) => handleChange(e, "addressLine2")}
                      />
                    </div>
                  </div>
                  </div>

                  <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="latitude">Latitude</label>
                    <input
                      className="form-control"
                      id="latitude"
                      type="text"
                      placeholder="Latitude"
                      value={orgProfile.latitude}
                      onChange={(e) => handleChange(e, "latitude")}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="longitude">Longitude</label>
                    <input
                      className="form-control"
                      id="longitude"
                      type="text"
                      placeholder="Longitude"
                      value={orgProfile.longitude}
                      onChange={(e) => handleChange(e, "longitude")}
                    />
                  </div>
                </div>
                </div>

                  <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">City:</label>
                      <input
                        className="form-control"
                        id="city"
                        type="text"
                        value={orgProfile.city}
                        onChange={(e) => handleChange(e, "city")}
                      />
                    </div>
                  </div>

               
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="pincode">Pincode:</label>
                      <input
                        className="form-control"
                        id="pincode"
                        type="text"
                        value={orgProfile.pincode}
                        onChange={(e) => handleChange(e, "pincode")}
                      />
                    </div>
                  </div>
                
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="state">State:</label>
                      <input
                        className="form-control"
                        id="state"
                        type="text"
                        value={orgProfile.state}
                        onChange={(e) => handleChange(e, "state")}
                      />
                    </div>
                  </div>
                  </div>

                <br />
                <input
                  type="submit"
                  value="Save Changes"
                  className="btn btn-primary"
                />
              </form>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLabProfile;