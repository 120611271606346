import React, { useEffect, useState } from "react";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import "./Spinner.css";
import { useNavigate } from "react-router";

const UploadLocationsCsvFile = ({ isOpen, onRequestClose, companycode }) => {

    const [locations, setLocations] = useState({
        file: null,
        companyCode:companycode
      });
    
     
      const [loading, setLoading] = useState(false);
      const [csvFile, setCsvFile] = useState(null);
    
      const url = "/api/geolocation/upload";
    
      useEffect(() => {
    
      }, []);
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("data")).token;
        console.log("CSV File:", csvFile);
    
        const formData = new FormData();
        console.log(formData);
        formData.append("file", csvFile);
        formData.append("companyCode", companycode);
    
        try {
          const response = await axios.post(url, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });
          setLoading(false);
          toast.success("CSV file uploaded successfully");
          onRequestClose();
          setLocations("");
          setCsvFile(null);
        } catch (error) {
          setLoading(false);
          handleError(error);
        }
      };

      const navigate = useNavigate();

      function handleError(error) {
        if (error.response) {
          if (error.response.status === 401) {
            navigate(`/signin`);
            toast.error("Your session has expired. Please login.");
          } else if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          }  else if (error.response.data && error.response.data.code) {
            toast.error(error.response.data.code);
          }
           else {
            toast.error("An error occurred.");
          }
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred.");
        }
      }
    
    
      const handleCsvFileChange = (e) => {
        const file = e.target.files[0];
        setCsvFile(file);
      
        const formData = new FormData(); 
        formData.append("file", file); 

      };
    
      return (
        <Modal show={isOpen} onHide={onRequestClose} centered >
          <Modal.Header closeButton>
            <Modal.Title>Upload Locations CSV</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {loading && <div className="loading"></div>}
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={handleSubmit}>
                  <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="comapnyCode">Company Code</label>
                        <input
                          className="form-control"
                          id="comapnyCode"
                          type="text"
                          value={locations.companyCode}
                        //   onChange={rhandle}
                        readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="file" className="me-1">Upload File:</label>
                        <input
                          className="form-control smaller-input flex-grow-1"
                          id="file"
                          type="file"
                          placeholder="Upload document"
                          onChange={handleCsvFileChange}
                        />
                      </div>
                    </div>
                    <br/>
                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                      disabled={loading}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="secondary" onClick={onRequestClose}>
                      Close
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer />
        </Modal>
      );
    };

export default UploadLocationsCsvFile;
