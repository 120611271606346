import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";

const CreateDesignations = ({ isOpen, onRequestClose, companycode, designation }) => {
  const navigate = useNavigate();
  const url = designation ? `/api/designation/update` : "/api/designation/create";
  const [admin, setAdmin] = useState({
    companycode: companycode,
    designation: "",
    id: designation ? designation.id : null
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (designation) {
      setAdmin({
        companycode: companycode,
        designation: designation.designation,
        id: designation.id
      });
    }
  }, [designation, companycode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    if (admin.designation) {
      try {
        const response = await axios.post(url, admin, {
          headers: { Authorization: `Bearer ${token}` }
        });

        setLoading(false);
        onRequestClose();
        setAdmin({ companycode: companycode, designation: "", id: null });
        toast(designation ? "Designation Updated Successfully" : "Designation Added Successfully");
      } catch (error) {
        setLoading(false);
        handleError(error);
        setAdmin({ companycode: companycode, designation: "", id: null });
      }
    } else {
      setLoading(false);
      toast.error("Please fill out all the required fields.");
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  function rhandle(e) {
    const { id, value } = e.target;
    setAdmin((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{designation ? "Update Designation" : "Add Designation"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="companycode">Company Code</label>
                    <input
                      className="form-control"
                      id="companycode"
                      type="text"
                      placeholder="Company Code"
                      value={admin.companycode}
                      readOnly
                    />
                  </div>

                  <div className="form-floating-sm mb-2">
                    <label htmlFor="designation">Designation <span style={{ color: "red" }}>* </span></label>
                    <input
                      className="form-control"
                      id="designation"
                      type="text"
                      placeholder="Designation"
                      value={admin.designation}
                      onChange={rhandle}
                      required
                    />
                  </div>
                </div>

                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default CreateDesignations;
