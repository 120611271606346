import React, {useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import FamilyAppointments from "./familyAppointments";
import FamilyProfile from "./familyProfile";
 
  function FamilyProfileTab() {

  const[loading ,setLoading]=useState(false);
  const [activeTab, setActiveTab] = useState('profile');

  return (
    <div>
      <AdminDashboard />
    
      <div className="d-flex align-items-center ">
        <div className="col-10  align-items-center mx-xl-auto  ps-xl-10">
      <div
        className="container-xl p-10 px-11"
        style={{ position: "relative", left: "100px" }}
      >
        <mwc-tab-bar style={{ marginBottom: "-1px", marginLeft:'-220px'}}  activeIndex="0"
       >
            <mwc-tab
              label="Family Profile"
              icon="person"
              stacked
              active={activeTab === "profile"}
              onClick={() => setActiveTab("profile")}

            ></mwc-tab>

         <mwc-tab
              label="Appointments"
              icon="calendar_today"
              stacked
              active={activeTab === "Appointment"}
              onClick={() => setActiveTab("Appointment")}
            ></mwc-tab>

        </mwc-tab-bar>
        </div>
        </div>
        </div>
       
            {activeTab === "profile" && (
            <FamilyProfile/>
          )}

          
          {activeTab === "Appointment" && (
              <FamilyAppointments />
          )}

        </div>
        
  );
}
 
export default FamilyProfileTab;