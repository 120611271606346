import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import "./Spinner.css";
import { useNavigate } from "react-router";
const AddDocument = ({ isOpen, onRequestClose, id }) => {
    const [document, setDocuments] = useState({
      id: id || null,
      file: null,
      fileMeta: "",
      fileName: "",
    });
  
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
  
    // URL for document creation and update
    const url = id ? `/api/documentation/update` : "/api/documentation/create";
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      // Construct the document data, including the id for update
      const documentData = {
        id: id || null,  // Include id in the request body for update
        file: document.file,
        fileMeta: document.fileMeta,
        fileName: document.fileName,
      };
  
      try {
        const response = await axios.post(url, documentData, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        if (id) {
          toast.success("Document updated successfully");
        } else {
          toast.success("Document uploaded successfully");
        }
  
        setLoading(false);
        onRequestClose();
        resetForm();
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    };
  
    // Reset form after submission or modal close
    const resetForm = () => {
      setDocuments({ file: null, fileMeta: "", fileName: "", id: null });
    };
  
    // Handle potential errors
    function handleError(error) {
      if (error.response) {
        if (error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.response.data && error.response.data.code) {
          toast.error(error.response.data.code);
        } else {
          toast.error("An error occurred.");
        }
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred.");
      }
    }
  
    // Convert file to base64
    function getBase64FromFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
  
        reader.onload = function () {
          const [metadata, content] = reader.result.split(",");
          resolve({ metadata, content });
        };
  
        reader.onerror = function (error) {
          reject(error);
        };
      });
    }
  
    // Handle file change and conversion
    const handlePdfFileChange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        const convertedPdf = await getBase64FromFile(file);
        const pdfObject = {
          ...document,
          file: convertedPdf.content,
          fileName: file.name,
          fileMeta: convertedPdf.metadata,
        };
        setDocuments(pdfObject);
      }
    };
  
    // UseEffect for loading existing document data if in edit mode
    useEffect(() => {
      if (id) {
        setDocuments((prevDocument) => ({
          ...prevDocument,
          id: id,
        }));
      }
    }, [id]);
  
    return (
      <Modal show={isOpen} onHide={onRequestClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{id ? "Update Document" : "Upload Document"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <div className="loading"></div>}
          <div className="col-xl-10 mx-xl-auto">
            <div className="card card-raised mb-3">
              <div className="card-body p-4">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="file" className="me-1">
                        {id ? "Change File:" : "Upload File:"}
                      </label>
                      <input
                        className="form-control smaller-input flex-grow-1"
                        id="file"
                        type="file"
                        placeholder="Upload document"
                        onChange={(e) => handlePdfFileChange(e)}
                      />
                    </div>
                  </div>
                  <br />
                  <input
                    type="submit"
                    value={id ? "UPDATE" : "SAVE"}
                    className="btn btn-primary"
                    disabled={loading}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
  };
  
  export default AddDocument;
  