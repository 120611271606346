import React, { useEffect, useState } from "react"; 
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";

  function FamilyProfile() {

  const { memberid } = useParams();

  const [loader, setLoader] = useState(false);

  const[loading ,setLoading]=useState(false);
  const [password, setPassword] = useState('');
  const [isEmployee, setIsEmployee] = useState(false);
  const userRole = JSON.parse(localStorage.getItem("data")).role;
  if (userRole && userRole.role === "ROLE_EMPLOYEE") {
    setIsEmployee(true);
  }

  const [profile, setProfile] = useState({
    name:"",
    lastname:"",
	aadhaarnumber:"",
	relationship:"",
	phoneNumber:"",
	gender:"",
    dob:"",
	address:"",
	pan:"",
	image:null,
	imageMeta:"",
  });
  
  useEffect(() => {
    setLoading(true);
    
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/familydetails/${memberid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails = response.data;
       setProfile(userProfileDetails);
        setLoading(false);
      } catch (error) {
        setLoading(false);
handleError(error);
      }
    };

    fetchProfile();

  }, [memberid]);
  
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       const [metadata, content] = reader.result.split(",");
       resolve({ metadata, content });};
       reader.onerror = function (error) {
       reject(error);};}); }

       const handleImageChange=async(event)=>{
        const convertedImage= await getBase64FromFile(event.target.files[0]);
        const imageObject = {...profile,
              imageMeta: convertedImage.metadata,
              image:convertedImage.content,};
              setProfile(imageObject);
      }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios.post(`/api/familydetails/update`, profile, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Profile Updated Successfully");
      setLoading(false);

    } catch (error) {
     handleError(error);
    } finally {
      setLoading(false);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setProfile({ ...profile, [field]: actualValue });
    
  };

const navigate = useNavigate();
const handleBack = () => {
  navigate(-1);
};
  return (
    <div>
               
     {loading && <div className="loading"></div>} 
    
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="mb-2">  
        <button className="btn btn-primary" onClick={handleBack}>
      Back
    </button>
        </div>
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
              <div className="card-title">Profile Details</div>{" "}
              <br/>
              <br/>
              <br/>
              {JSON.parse(localStorage.getItem("data")).role ==='ROLE_EMPLOYEE' ? (
                <>
              <div >
                {profile.image ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="image"
                    src={`${profile?.imageMeta},${profile?.image}`}
                    alt="User Profile"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="image"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",

                    }}
                  >
                    {/* <FaRegUserCircle size={250} /> */}
                  </div>
                )}
                <input
                  className="col-7"
                  type="file"
                  id="image"
                  // style={{ marginLeft: "10%" }}
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    handleImageChange(event);
                  }}
                  accept="image/*"
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="name">Name :</label>
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      value={profile.name}
                      onChange={(e) => handleChange(e, "name")}
                    />
                  </div>
                </div>
              
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="organization">Phone Number :</label>
                    <input
                      className="form-control"
                      id="phoneNumber"
                      type="text"
                      value={profile.phoneNumber}
                      onChange={(e) => {
                        const inputText = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                        const limitedText = inputText.slice(0, 10); // Limit to 10 characters
                        setProfile({ ...profile, phone: limitedText }); // Update the state with limited text
                      }}
                    />
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="dob">Date Of Birth :</label>
                    <input
                      className="form-control"
                      id="dob"
                      type="date"
                      value={profile.dob}
                      onChange={(e) => handleChange(e, "dob")}
                    />
                  </div>
                </div>
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="gender">Gender :</label>
                    <input
                      className="form-control"
                      id="gender"
                      type="text"
                      value={profile.gender}
                      onChange={(e) => handleChange(e, "gender")}
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="pan">PAN :</label>
                    <input
                      className="form-control"
                      id="pan"
                      type="text"
                      value={profile.pan}
                      onChange={(e) => handleChange(e, "pan")}
                    />
                  </div>
                </div>
            
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="aadhaarnumber">Aadhar Number :</label>
                    <input
                      className="form-control"
                      id="aadhaarnumber"
                      type="text"
                      value={profile.aadhaarnumber}
                      onChange={(e) => handleChange(e, "aadhaarnumber")}
                    />
                  </div>
                </div>
               
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="addressName">Address :</label>
                    <textarea
                      className="form-control"
                      id="addressName"
                      type="text"
                      value={profile.address}
                      onChange={(e) => handleChange(e, "address")}
                      rows="2"
                    />
                  </div>
                </div>
                </div>

                <br />
                <input
                  type="submit"
                  value="save changes"
                  className="btn btn-primary"
                />
              </form>
              </>
              ):(
                <>
  <div >
                {profile.image ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="image"
                    src={`${profile?.imageMeta},${profile?.image}`}
                    alt="User Profile"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="image"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",

                    }}
                  >
                    {/* <FaRegUserCircle size={250} /> */}
                    
                  </div>
                )}
              
              </div>
              <form>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="name">Name :</label>
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      value={profile.name}
                      // onChange={(e) => handleChange(e, "name")}
                    />
                  </div>
                </div>
              
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="organization">Phone Number :</label>
                    <input
                      className="form-control"
                      id="phoneNumber"
                      type="text"
                      value={profile.phoneNumber}
                      // onChange={(e) => {
                      //   const inputText = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                      //   const limitedText = inputText.slice(0, 10); // Limit to 10 characters
                      //   setProfile({ ...profile, phone: limitedText }); // Update the state with limited text
                      // }}
                    />
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="dob">Date Of Birth :</label>
                    <input
                      className="form-control"
                      id="dob"
                      type="date"
                      value={profile.dob}
                      // onChange={(e) => handleChange(e, "dob")}
                    />
                  </div>
                </div>
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="gender">Gender :</label>
                    <input
                      className="form-control"
                      id="gender"
                      type="text"
                      value={profile.gender}
                      // onChange={(e) => handleChange(e, "gender")}
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="pan">PAN :</label>
                    <input
                      className="form-control"
                      id="pan"
                      type="text"
                      value={profile.pan}
                      // onChange={(e) => handleChange(e, "pan")}
                    />
                  </div>
                </div>
            
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="aadhaarnumber">Aadhar Number :</label>
                    <input
                      className="form-control"
                      id="aadhaarnumber"
                      type="text"
                      value={profile.aadhaarnumber}
                      // onChange={(e) => handleChange(e, "aadhaarnumber")}
                    />
                  </div>
                </div>
               
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="addressName">Address :</label>
                    <textarea
                      className="form-control"
                      id="addressName"
                      type="text"
                      value={profile.address}
                      // onChange={(e) => handleChange(e, "address")}
                      rows="2"
                    />
                  </div>
                </div>
                </div>
                </form>

</>

              )}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
 
export default FamilyProfile;