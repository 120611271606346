import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";

const MapDesignationsAndPackage = ({ isOpen, onRequestClose, designationId, companycode }) => {
  const navigate = useNavigate();
  const url = "/api/packagedesignationmapping/create";
  const [admin, setAdmin] = useState({
    packageid: "",
    designationid: designationId,
    gender: [],
  });
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [designations, setDesignations] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const packageRes = await axios.get(`/api/package/companycode=${companycode}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const designationRes = await axios.get(`/api/designation/${designationId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPackages(packageRes.data);
        setDesignations(designationRes.data);
      } catch (error) {
       handleError(error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    if (admin.packageid && admin.designationid && admin.gender.length) {
      try {
        // Create an array of entries for each selected gender
        const payload = admin.gender.map(gender => ({
          packageid: admin.packageid,
          designationid: designationId,
          gender: gender
        }));
        
        await axios.post(
          url,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setLoading(false);
        onRequestClose();
        setAdmin({ packageid: "", designationid: "", gender: [] });
        toast("Designation and Packages mapped Successfully");
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    } else {
      setLoading(false);
      toast.error("Please fill out all the required fields.");
    }
  };
  
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const rhandle = (e) => {
    const { id, value, checked } = e.target;
    if (id === "gender") {
      setAdmin((prevState) => ({
        ...prevState,
        gender: checked
          ? [...prevState.gender, value]
          : prevState.gender.filter((g) => g !== value),
      }));
    } else {
      setAdmin((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Package and Designations Mapping</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form onSubmit={handleSubmit}>
                <div className="row">

                <div className="form-floating-sm mb-2">
                    <label htmlFor="designation">Designation</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Designation"
                      value={designations.designation}
                      readOnly
                    />
                  </div>
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="packageid">
                      Package <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      id="packageid"
                      value={admin.packageid}
                      onChange={(e) => rhandle(e)}
                      required
                    >
                      <option value="">Select Package</option>
                      {packages.map((pkg) => (
                        <option key={pkg.id} value={pkg.id}>
                          {pkg.packagename}
                        </option>
                      ))}
                    </select>
                  </div>
                 
                  <div className="form-floating-sm mb-2">
                    <label>
                      Gender <span style={{ color: "red" }}>*</span>
                    </label>
                    <div>
                      <label htmlFor="male">
                        <input
                          type="checkbox"
                          id="gender"
                          value="Male"
                          checked={admin.gender.includes("Male")}
                          onChange={(e) => rhandle(e)}
                        />{" "}
                        Male
                      </label>
                    </div>
                    <div>
                      <label htmlFor="female">
                        <input
                          type="checkbox"
                          id="gender"
                          value="Female"
                          checked={admin.gender.includes("Female")}
                          onChange={(e) => rhandle(e)}
                        />{" "}
                        Female
                      </label>
                    </div>
                  </div>
                </div>
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default MapDesignationsAndPackage;
