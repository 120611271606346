import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";

const CreateLocations = ({ isOpen, onRequestClose, locationId, companycode }) => {
  const navigate = useNavigate();
  const createUrl = "/api/geolocation/create";
  const updateUrl = `/api/geolocation/update`;
  const getLocationUrl = `/api/geolocation/${locationId}`;

  const [locations, setLocations] = useState({
    location: "",
    companyCode:companycode,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (locationId) {
      // Fetch existing category data
      const fetchLocations= async () => {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("data")).token;
        try {
          const response = await axios.get(getLocationUrl, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setLocations({
            location: response.data.location,
            companyCode: response.data.companyCode,
          });
        } catch (error) {
          handleError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchLocations();
    }
  }, [locationId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    if (locations.location) {
      const url = locationId ? updateUrl : createUrl;
      const data = {
        location: locations.location,
        companyCode: locations.companyCode,
        ...(locationId && { id: locationId }), // Add categoryId to the data if updating
      };

      axios({
        method: "post", // Use POST method for both create and update
        url: url,
        data: data,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          setLoading(false);
          onRequestClose();
          setLocations("");
          toast(locationId ? "Location Updated Successfully" : "Location Added Successfully");
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
          setLocations("");
        });
    } else {
      setLoading(false);
      toast.error("Please fill out all the required fields.");
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  function rhandle(e) {
    const { id, value } = e.target;
    setLocations((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{locationId ? "Update Location" : "Add Location"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="comapnyCode">Company Code</label>
                    <input
                      className="form-control"
                      id="comapnyCode"
                      type="text"
                      value={locations.companyCode}
                      onChange={(e) => rhandle(e)}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="location">
                      Location<span style={{ color: "red" }}>* </span>
                    </label>
                    <input
                      className="form-control"
                      id="location"
                      type="text"
                      placeholder="Location"
                      value={locations.location}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default CreateLocations;
