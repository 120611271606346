import React, { useEffect, useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';  
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import EmployeesDetails from "./EmployeesDetails";
import ViewEmployeeReportsByDoctor from "./viewEmployeeReportsByDoctor";

function ViewEmpProfileByDoctor() {
  const { userid } = useParams();

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('employeeprofile');
  const [profile, setProfile] = useState({});
  const [gender, setGender] = useState([]);
  const [designations, setDesignations] = useState([]);

  useEffect(() => {
    setLoading(true);
    
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/employeedetails/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails = response.data;
       setProfile(userProfileDetails);

       const des = await axios.get(`/api/designation/companycode=${response.data.companycode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDesignations(des.data);
      const gender = await axios.get(`/api/gender`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGender(gender.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
handleError(error);
      }
    };

    fetchProfile();

  }, [userid]);

  const handleBack = () => {
   navigate(-1);
  };

  const navigate = useNavigate(); 
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  return (
    <div>
      <AdminDashboard />
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <Tabs
              id="employee-profile"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
              className="mb-3"
            >
           <Tab eventKey="employeeprofile" title="Employee Profile">
              {activeTab === 'employeeprofile' && (
                <>
                  {loading && <div className="loading"></div>}
                  <div className="col-12 mx-xl-auto">
                    <div className="mb-2">
                      <button className="btn btn-primary" onClick={handleBack}>
                        Back
                      </button>
                    </div>
                    <div className="card card-raised">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
           
                          <div className="col-md-4 d-flex justify-content-center align-items-start">
                          <div className="card-title">Profile Details</div>
                            {profile.image ? (
                              <img
                                className="img-fluid circular-image"
                                id="image"
                                src={`${profile?.imageMeta},${profile?.image}`}
                                alt="User Profile"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "50%",
                                  background: "#ccc",
                                  lineHeight: "170px",
                                  fontSize: "24px",
                                  marginTop: "25px",
                                }}
                              />
                            ) : (
                              <div
                                className="img-fluid circular-image"
                                id="image"
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  borderRadius: "50%",
                                  background: "#ccc",
                                  lineHeight: "170px",
                                  fontSize: "24px",
                                  marginTop: "60px",
                                }}
                              />
                            )}
                          </div>

                          <div className="col-md-8">
                          
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>First Name:</strong> {profile.name}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>Last Name:</strong> {profile.lastname}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>Phone Number:</strong> {profile.phoneno}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>Email ID:</strong> {profile.email}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>Date Of Birth:</strong> {profile.DOB}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>Gender:</strong> {profile.gender}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>Employee Id:</strong> {profile.empid}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>Designation:</strong> {profile.designation}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>Date of Joining:</strong> {profile.dateOfJoining}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>PAN:</strong> {profile.pan}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>Aadhar Number:</strong> {profile.aadhaarnumber}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>Address:</strong> {profile.address}
                                </div>
                              </div>
                              <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <strong>Location :</strong> {profile.locations?.location}
                                </div>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Tab>
              <Tab eventKey="empoyeereport" title="Employee Report">
                {activeTab === 'empoyeereport' && (
                  <ViewEmployeeReportsByDoctor user={userid} />
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewEmpProfileByDoctor;
