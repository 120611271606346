import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import AddDocument from "./addDocuments";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";

function Documentation() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const token = JSON.parse(localStorage.getItem("data")).token;
  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/documentation`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDocuments(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 401) {
      navigate("/signin");
      toast.error("Your session has expired. Please login.");
    } else {
      toast.error(
        error.response?.data?.message || error.response?.data?.code || "An error occurred."
      );
    }
  };

  const handleAddDocuments = () => {
    setSelectedId(null); // For adding new documents
    setIsModalOpen(true);
  };

  const handleEdit = (id) => {
    setSelectedId(id); // Set the document id to be edited
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      setLoading(true);
      try {
        await axios.delete(`/api/documentation`, {
          headers: { Authorization: `Bearer ${token}` },
          data: { id }, // Pass the ID in the request body
        });
        toast.success("Document deleted successfully");
        getData();
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    }
  };
  
  const handleAddCloseModal = () => {
    setIsModalOpen(false);
    getData(); // Refresh data after adding or editing
  };

  const handleView = (fileMeta, fileContent, fileName) => {
    const linkSource = `data:${fileMeta};base64,${fileContent}`; // Assuming base64 encoded data
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName || "document.pdf"; // Use provided fileName or default
    downloadLink.click();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <AdminDashboard />
      <div className="col-10 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            {loading && <div className="loading"></div>}
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Document Settings
              <button className="btn btn-primary" onClick={handleAddDocuments}>
                ADD
              </button>
            </div>
            <br />
            <table className="table table-bordered">
              <thead>
                <tr className="table-header">
                  <th>Document Name</th>
                  <th>File</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {documents.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  documents.map((doc) => (
                    <tr key={doc.id}>
                      <td>{doc.fileName}</td>
                      <td><button className="btn btn-primary" style={{ cursor: "pointer" }}
                         onClick={() => handleView(doc.fileMeta, doc.file, doc.fileName)}>View File</button></td>
                      <td>
                        
                        <FaEdit
                          className="text-warning me-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleEdit(doc.id)}
                        />
                        <FaTrash
                          className="text-danger"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDelete(doc.id)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <AddDocument isOpen={isModalOpen} onRequestClose={handleAddCloseModal} id={selectedId} />
      )}
    </div>
  );
}

export default Documentation;
