import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChartJS from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { IoSearchOutline } from "react-icons/io5";
import AdminDashboard from "./admindashboard";

ChartJS.register(ChartDataLabels);

function LabsAnalytics() {
  const navigate = useNavigate();
  const [appointmentsData, setAppointmentsData] = useState("");
  const [labs, setLabs] = useState("");

  const [executed, setExecuted] = useState({
    date: "",
  });
  const [loader, setLoader] = useState(false);
  const [searchbymonth, setSearchBymonth] = useState(true);

  const getCurrentMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
  };

  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

  const currentDate1 = getCurrentMonth();

  const getData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoader(true);

      const labRes = await axios.get(`/api/labdetails/labcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLabs(labRes.data);

      const res = await axios.get(`/api/getAppointmentsByMonthAndLabcode/date=${currentDate1}/labcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAppointmentsData(res.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error); 
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const searchByMonth = async (e, text) => {
    e.preventDefault();
    setSearchBymonth(false);
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      const res = await axios.get(`/api/getAppointmentsByMonthAndLabcode/date=${text}/labcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAppointmentsData(res.data);
    } catch (error) {
      handleError(error);
    }
  };

  const rhandle = (e, field) => {
    e.preventDefault();
    let actualValue = e.target.value;
    setExecuted((prevState) => ({ ...prevState, [field]: actualValue }));
  };

  
   
    const processedData = [
      {
        labname: `${labs.labname}\n(${labs.labcode})`,
        totalAppointments: appointmentsData ? appointmentsData.totalAppointments : 0,
        totalAppExecuted: appointmentsData ? appointmentsData.totalAppExecuted : 0,
      },
    ];

    return (
      <div>
      <AdminDashboard />
      <div className="col-11 mx-xl-auto ps-xl-10" style={{ position: "relative", top: "100px" }}>
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div className="card-title form-group">Total Executed Appointments</div>
              <div className="d-flex align-items-center">
                <input
                  id="date"
                  type="month"
                  value={executed.date || getCurrentMonth()}
                  onChange={(e) => rhandle(e, "date")}
                />
                &nbsp;&nbsp;
                <button
                  type="submit"
                  value="search"
                  className="btn btn-primary"
                  id="btn"
                  onClick={(e) => searchByMonth(e, executed.date)}
                >
                  <IoSearchOutline id="IoSearchOutline" />
                </button>
              </div>
            </div>
            <div className="row" style={{ maxWidth: "100%" }}>
              <div>
                <Bar
                  data={{
                    labels: processedData.map((item) => item.labname),
                    datasets: [
                      {
                        label: "Executed Appointments",
                        data: processedData.map((item) => item.totalAppExecuted),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                      },
                      {
                        label: "Total Appointments",
                        data: processedData.map((item) => item.totalAppointments - item.totalAppExecuted),
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                      },
                    ],
                  }}
                  height={400}
                  width={800}
                  options={{
                    indexAxis: 'y',
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,
                      },
                    },
                    barThickness: 40,
                    plugins: {
                      datalabels: {
                        display: true,
                        color: 'white',
                        formatter: (value, context) => {
                          if (context.dataset.label === 'Total Appointments') {
                            const totalApp = value;
                            const executedApp = context.chart.data.datasets[0].data[context.dataIndex];
                            const difference = totalApp + executedApp;
                            return difference;
                          }
                          return value;
                        },
                      },
                      tooltip: {
                        callbacks: {
                          label: function(context) {
                            let label = context.dataset.label || '';
                            if (label) {
                              label += ': ';
                            }
                            label += context.formattedValue;
                            if (context.dataset.label === 'Total Appointments') {
                              const totalApp = context.raw;
                              const executedApp = context.chart.data.datasets[0].data[context.dataIndex];
                              const difference = totalApp + executedApp;
                              label = `Total Appointments: ${difference}`;
                            }
                            return label;
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <div className="loading"></div>}
    </div>
    );
  }
  
  export default LabsAnalytics;