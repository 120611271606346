import React, { useState, useEffect} from "react";
import { loginUser} from "../auth/service";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { doLogin } from "../auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../images/tmplLogo.png";
import "./Spinner.css";
import axios from "../api/baseurl";
import Footer from "./footer";
import backgroundImage from '../images/tmpl_bc_image.jpg';

function EmployeeLogin() {
  console.log(process.env.REACT_APP_BASE_URL);
  const navigate = useNavigate();
  const [logindata, setLogindata] = useState({
    username: "",
    password: "",
    orgcode: "",
    role:"ROLE_EMPLOYEE"
  });

  const [loading, setLoading] = useState(false);
  
  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setLogindata({ ...logindata, [field]: actualValue });
  };

  const [data, setData] = useState(null);
  const [versionResponse, setVersionResponse] = useState(null);
  const apiURL = process.env.REACT_APP_BASE_URL;

  // 15 min interval
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/version');
        console.log(response.data)
        const result = await response.json();
        setVersionResponse(result.version);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    

    fetchData();

    
  }, []);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(logindata);

    //validation
    if (logindata.username.trim() === "" || logindata.password.trim() === "") {
      toast.warn("username or password is required!!");
      setLoading(false);
      return;
    }
    //submit the data to server to generate token
    loginUser(logindata)
    .then((data) => {
      const userRole = data.role; // Assuming role is directly available in the response data

      if (userRole === "ROLE_EMPLOYEE") {
        // Save data to local storage
        localStorage.setItem("token", data.token);
        doLogin(data, () => {
          navigate("/users/dashboard");
        });
        toast.success("Login success");
      } else {
        toast.error("You do not have the required permissions to log in");
        setLoading(false);
      }
    })

      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          toast.error( "Incorrect Email Or Password") ;
        } else if (error.response.status === 404) {
          const logindetails = {
            username: logindata.username,
          };
          localStorage.setItem(
            "RegistrationData",
            JSON.stringify(logindetails)
          );
          toast.error(error.response.data.message);
      
        } else if (error.response.status === 400) {
          let errorMessage = "Something went wrong on the server!!";
          if (error.response.data && error.response.data.codes) {
            const codes = error.response.data.codes;
            if (codes.includes("IncorrectOrgcode")) {
              errorMessage = error.response.data.defaultMessage;

            } else if (codes.includes("IncorrectPassword")) {
              errorMessage = error.response.data.defaultMessage;
            
          } else if (codes.includes("EmailNotRegistered")) {
            errorMessage = error.response.data.defaultMessage;
          }
            
          }
          toast.error(errorMessage);
        } else {
          toast.error("Something went wrong on the server!!");
        }
        setLoading(false);
      });
  };

  return (
    <div class="bg-primary">
      {loading && <div className="loading"></div>}
     
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div
                  className="col-xxl-10 col-xl-10 col-lg-12"
                  style={{ height: "650px" }}
                >
                  <div className="card card-raised shadow-10 mt-5 mt-xl-10 mb-4">
                    <div className="row g-0">
                      <div
                        className="col- col-md-6 d-none d-md-block"
                        style={{
                          backgroundImage:
                          `url(${backgroundImage})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                      ></div>

                      <div className="col-lg-5 col-md-6">
                        <div className="card-body p-5">
                          <div className="text-center">
                            <img
                              class="mb-3"
                              src={logo}
                              alt="..."
                              style={{ height: "48px" }}
                            />

                            <h1 className="display-5 mb-0">TMPL</h1>
                            <div className="subheading-1 mb-5">Employee Login</div>
                          </div>

                          <form onSubmit={handleFormSubmit}>
                            <div className="form-floating">
                              <input
                                className="form-control"
                                id="username"
                                type="email"
                                placeholder="name@example.com"
                                value={logindata.username}
                                onChange={(e) => handleChange(e, "username")}
                                Outlined
                              />
                              <label for="email">E-mail</label>
                            </div>
                            <br />

                            <div className="form-floating">
                              <input
                                className="form-control"
                                id="password"
                                type="password"
                                placeholder="Password"
                                value={logindata.password}
                                onChange={(e) => handleChange(e, "password")}
                              />
                              <label for="password">Password</label>
                            </div>

                            <br />
                            <div className="form-floating">
                              <input
                                className="form-control"
                                id="orgcode"
                                type="text"
                                placeholder="Orgcode"
                                value={logindata.orgcode}
                                onChange={(e) => handleChange(e, "orgcode")}
                              />
                              <label for="orgcode">Organization code</label>
                            </div>

                            <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                              <Link
                                className="small fw-500 text-decoration-none"
                                to="/forgotpassword"
                                style={{ color: "darkblue" }}
                              >
                                Forgot Password?
                              </Link>
                              <input
                                type="submit"
                                disabled={loading}
                                value="Login"
                                className="btn btn-primary"
                              />{" "}
                            </div>
                          </form>
                          <br />
                          <br />
                          <div className="container">
  <div className="row justify-content-center mb-2">
    <div className="col-auto text-center">
      <Link className="small fw-500 text-decoration-none" to="/signin">
        Super Admin Signin?
      </Link>
    </div>

    <div className="col-auto text-center">
      <Link className="small fw-500 text-decoration-none" to="/employersignin">
        Employer Signin?
      </Link>
    </div>
    </div>

  <div className="row justify-content-center mb-2">
  <div className="col-auto text-center">
      <Link className="small fw-500 text-decoration-none" to="/labsignin">
        Lab Signin?
      </Link>
    </div>
  
    <div className="col-auto text-center">
      <Link className="small fw-500 text-decoration-none" to="/doctorsignin">
        Doctor Signin?
      </Link>
    </div>
  </div>
  <div className="row justify-content-center">
  <div className="col-auto text-center">
      <Link className="small fw-500 text-decoration-none" to="/labcorporatesignin">
        Lab Corporate Signin?
      </Link>
    </div>
  </div>
</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>

        <div id="layoutAuthentication_footer">
        <Footer/>
        </div>
      </div>
    </div>
  );
}
export default EmployeeLogin;
