import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { MdDeleteForever } from "react-icons/md";
import CreateTestsForPackage from "./addTestPackages";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import TestCsvFile from "./testsCsvFile";
import TestsCsvFormat from "./testCsvFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { BiEditAlt } from "react-icons/bi"; // Import the edit icon

function TestPackages() {
  const [testPackages, setTestPackages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null); // State to store the selected test for editing
  const [loader, setLoader] = useState(true);
  const [isCsvUploadModalOpen, setIsCsvUploadModalOpen] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
    getCategories();
  }, []);

  const getData = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/labtest`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTestPackages(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const getCategories = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/testcategory`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCategories(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleCreateCompany = () => {
    setSelectedTest(null); // Reset selected test for creating a new one
    setIsModalOpen(true);
  };

  const handleEditTest = (test) => {
    setSelectedTest(test); // Set the selected test for editing
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleDeleteTestPackage = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(id);
    try {
      await axios({
        url: "/api/labtest",
        method: "delete",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          id: `${id}`,
          orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
        },
      });
      toast.success("Deleted successfully");
      setLoader(false);
      getData();
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  // Group test packages by category
  const groupedTestPackages = testPackages.reduce((acc, test) => {
    const category = test.testCat?.categoryname || "";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(test);
    return acc;
  }, {});

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterTestPackages = (packages) => {
    let filteredPackages = packages;
    if (selectedCategory) {
      filteredPackages = filteredPackages.filter(
        (pkg) => pkg.testCat?.categoryname === selectedCategory
      );
    }
    if (searchQuery) {
      filteredPackages = filteredPackages.filter((pkg) =>
        pkg.testname.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return filteredPackages;
  };

  const uploadCsv = () => {
    setIsCsvUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsCsvUploadModalOpen(false);
    getData();
  };

  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  return (
    <div>
      <Dashboard />
      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              <span>Tests</span>
              <div>
                <button className="btn btn-primary me-5" onClick={handleShowImageModal}>
                  View Format
                </button>
                <button className="btn btn-primary me-5" onClick={uploadCsv}>
                  UPLOAD CSV
                </button>
                <button className="btn btn-primary" onClick={handleCreateCompany}>
                  ADD
                </button>
              </div>
            </div>
            <br />
            <div className="form-group d-flex align-items-center mt-4">
              <div className="row col-md-6">
                <div className="col-6">
                  <label htmlFor="categoryFilter">FILTER BY CATEGORY:</label>
                  <select
                    id="categoryFilter"
                    className="form-select me-3"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    <option value="">All Categories</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.categoryname}>
                        {category.categoryname}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6">
                <div className="form-group position-relative">
                  <label htmlFor="searchQuery"></label>
                  <input
                    id="searchQuery"
                    type="text"
                    className="form-control"
                    placeholder="Search Test Name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                   <span className="position-absolute" style={{ right: "10px", top: "70%", transform: "translateY(-50%)" }}>
    <FontAwesomeIcon icon={faSearch} />
  </span>
  </div>
                </div>
              </div>
            </div>
            <br />
            <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                  <th>Category Name</th>
                  <th>Test Name</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filterTestPackages(testPackages).length === 0 ? (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  Object.keys(groupedTestPackages).map((category) => (
                    <>
                      {filterTestPackages(groupedTestPackages[category]).map((test, index) => (
                        <tr key={test.id}>
                          {index === 0 && (
                            <td rowSpan={groupedTestPackages[category].length}>
                              {category}
                            </td>
                          )}
                          <td>{test.testname}</td>
                          <td>{test.description}</td>
                          <td
                            style={{ fontSize: "17px", cursor: "pointer" }}
                          >
                            <button
                              onClick={() => handleEditTest(test)}
                              style={{ border: "none", background: "white" }}
                            >
                              <BiEditAlt />
                            </button>
                            {/* <button
                              onClick={() => handleDeleteTestPackage(test.id)}
                              style={{ border: "none", background: "white" }}
                            >
                              <MdDeleteForever />
                            </button> */}
                          </td>
                        </tr>
                      ))}
                    </>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CreateTestsForPackage
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        test={selectedTest} // Pass the selected test to the modal
      />
      <TestsCsvFormat isOpen={showImageModal} onRequestClose={handleCloseImageModal} />
      <TestCsvFile isOpen={isCsvUploadModalOpen} onRequestClose={closeUploadModal} />
    </div>
  );
}

export default TestPackages;
