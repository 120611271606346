import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

function EmployeeReports() {
    const { userid } = useParams();
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    
    setLoader(true);
    try {
      const response = await axios.get(`/api/employeeappointment/userid=${userid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAppointmentDetails(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  useEffect(() => {
    getData();
  }, []);

//   const handleCancelAppointment = async (appointmentId) => {
//     const token = JSON.parse(localStorage.getItem("data")).token;
//     setLoader(true);
//     try{
//       await axios({
//       url: "/api/employeeappointment/update",
//       method: "post",
 
//       headers: { Authorization: `Bearer ${token}` },
 
//       data: {
//         id: `${appointmentId}`,
//         appointmentstatus:"Cancelled"
//       },
//     });
//     toast.success("Cancelled successfully");
//       getData();
//     } catch (error) {
//       console.error("Error cancelling appointment:", error);
//       setLoader(false);
//     }
//   };


  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div className="col-12 mx-xl-auto ps-xl-10" style={{ marginTop: "10%" }}>
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              <span>Report</span>
            </div>
            <br/>
            {/* <table className="table table-bordered">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  <th>Date</th>
                  <th>Time</th>
                  <th>Lab Name</th>
                  <th>Contact Number</th>
                  <th>Package Name</th>
                  <th>Status</th>
            
                </tr>
              </thead>
              <tbody>
                {appointmentDetails.length === 0 ? (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  appointmentDetails.map((appointment) => (
                    <tr key={appointment.id}>
                      <td>{appointment.date}</td>
                      <td>{appointment.time}</td>
                      <td>{appointment.labName}</td>
                      <td>{appointment.contact}</td>
                      <td>{appointment.packagename}</td>
                      <td>{appointment.appointmentstatus}</td>
                
                    </tr>
                  ))
                )}
              </tbody>
            </table> */}
          </div>
        </div>
        {loader && <div className="loading"></div>}
      </div>
    </div>

  );
}

export default EmployeeReports;
