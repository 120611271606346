import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "../api/baseurl"; // Adjust the import path as needed
import { Modal, Button, Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";

const CreateFinancialYear = ({ isOpen, onRequestClose }) => {
  const [fromMonth, setFromMonth] = useState("");
  const [toMonth, setToMonth] = useState("");

  // Month names to display in the dropdown
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Calculate end month automatically
  const handleStartMonthChange = (e) => {
    const selectedMonthIndex = parseInt(e.target.value) - 1;
    const selectedMonth = months[selectedMonthIndex];

    setFromMonth(selectedMonth);

    // Calculate the end month, exactly 1 year later
    const calculatedEndMonthIndex = selectedMonthIndex === 0 ? 11 : selectedMonthIndex - 1;
    setToMonth(months[calculatedEndMonthIndex]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      // Assuming the backend expects month names
      const response = await axios.post("/api/year-settings/create", {
        fromDate: fromMonth,
        toDate: toMonth,
      }, { headers: { Authorization: `Bearer ${token}` } });
      
      toast.success("Created successfully!");
      onRequestClose();
    } catch (error) {
    handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="fromDate">
            <Form.Label>From Date</Form.Label>
            <Form.Control
              as="select"
              value={months.indexOf(fromMonth) + 1} // Use index to match dropdown value
              onChange={handleStartMonthChange}
            >
              <option value="">From Date</option>
              {months.map((month, index) => (
                <option key={index} value={index + 1}>{month}</option>
              ))}
            </Form.Control>
          </Form.Group>
          {toMonth && (
            <Form.Group controlId="toDate">
              <Form.Label>To Date</Form.Label>
              <Form.Control type="text" value={toMonth} readOnly />
            </Form.Group>
          )}
          <Button type="submit" variant="primary" className="mt-3 me-3">
            Save
          </Button>
          <Button variant="secondary" onClick={onRequestClose} className="mt-3 ml-2">
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateFinancialYear;
