import React, { useEffect, useState } from "react";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import "./Spinner.css";
import { useNavigate } from "react-router";

const UploadReport = ({ isOpen, onRequestClose, empId }) => {

  const [labs, setlabs] = useState({
    id:empId,
    file: null,
    fileSize: "",
    fileContentType:""
  });

 
  const [loading, setLoading] = useState(false);

  const url = "/api/employeeappointment/upload";

  useEffect(() => {

  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      const response = await axios.post(url, labs, {
        headers: { Authorization: `Bearer ${token}` } ,
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Report uploaded successfully");
      onRequestClose();
      setlabs("");
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
  const handlePdfFileChange = async (e) => {
    const file = e.target.files[0];
    const convertedPdf = await getBase64FromFile(file);
    const pdfObject = {
      ...labs,

      file: convertedPdf.content,
      fileSize: file.size,
      fileContentType:convertedPdf.metadata
    };

    setlabs(pdfObject);
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered >
      <Modal.Header closeButton>
        <Modal.Title>Upload Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {loading && <div className="loading"></div>} 
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="file" className="me-1">Upload File:</label>
                    <input
                                  className="form-control smaller-input flex-grow-1"
                                  id="file"
                                  type="file"
                                  placeholder="Upload document"
                                  onChange={(e) => handlePdfFileChange(e)}
                                />
                  </div>
                </div>
                <br/>
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default UploadReport;
