import React, { useEffect, useState } from "react"; 
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import AdminDashboard from "./admindashboard";

  function ViewEmpProfile() {

  const userid = JSON.parse(localStorage.getItem("data")).userid;

  const [loader, setLoader] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [gender, setGender] = useState([]);

  const[loading ,setLoading]=useState(false);
  const [password, setPassword] = useState('');

  const [profile, setProfile] = useState({
    name:"",
    lastname:"",
    phoneno:"",
    gender:"",
    DOB:"",
    aadhaarnumber:"",
    companycode:"",
    email:"",
    address:"",
    pan:"",
    image:null,
    imageMeta:"",
    empid:"",
    designation:"",
    dateOfJoining:"",
    packagetype:""
  });
  
  useEffect(() => {
    setLoading(true);
    
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/employeedetails/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails = response.data;
       setProfile(userProfileDetails);

       const des = await axios.get(`/api/designation/companycode=${response.data.companycode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDesignations(des.data);
      const gender = await axios.get(`/api/gender`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGender(gender.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
handleError(error);
      }
    };

    fetchProfile();

  }, [userid]);
  

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       const [metadata, content] = reader.result.split(",");
       resolve({ metadata, content });};
       reader.onerror = function (error) {
       reject(error);};}); }

       const handleImageChange=async(event)=>{
        const convertedImage= await getBase64FromFile(event.target.files[0]);
        const imageObject = {...profile,
              imageMeta: convertedImage.metadata,
              image:convertedImage.content,};
              setProfile(imageObject);
      }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios.post(`/api/employeedetails/update`, profile, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Profile Updated Successfully");
      setLoading(false);

    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      const body = {
        id: profile.userid,
        password: password,
      };

      await axios.post(`/api/accounts/update-password`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success("Password Updated Successfully");
      setPassword("");
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  
const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setProfile({ ...profile, [field]: actualValue });
    
  };

const navigate = useNavigate();
const handleBack = async (orgcode) => {
  navigate(`/users/companydetails/${orgcode}`);
};
  return (
    <div>
    <div>
      <AdminDashboard />
    </div>    
     {loading && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">

        <div className="card card-raised">

          <div className="card-body">

            <div className="d-flex">

              <div className="card-title">Profile Details</div>{" "}
              <br/>
              <br/>
              <br/>
              <div >
                {profile.image ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="image"
                    src={`${profile?.imageMeta},${profile?.image}`}
                    alt="User Profile"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="image"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",

                    }}
                  >
                    {/* <FaRegUserCircle size={250} /> */}
                  </div>
                )}
                <input
                  className="col-7"
                  type="file"
                  id="image"
                  // style={{ marginLeft: "10%" }}
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    handleImageChange(event);
                  }}
                  accept="image/*"
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="organization">First Name :</label>
                    <input
                      className="form-control"
                      id="fisrtname"
                      type="text"
                      value={profile.name}
                      onChange={(e) => handleChange(e, "name")}
                    />
                  </div>
                </div>
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="organization">Last Name :</label>
                    <input
                      className="form-control"
                      id="lastname"
                      type="text"
                      value={profile.lastname}
                      onChange={(e) => handleChange(e, "lastname")}
                    />
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="organization">Phone Number :</label>
                    <input
                      className="form-control"
                      id="phone"
                      type="text"
                      value={profile.phoneno}
                      onChange={(e) => {
                        const inputText = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                        const limitedText = inputText.slice(0, 10); // Limit to 10 characters
                        setProfile({ ...profile, phone: limitedText }); // Update the state with limited text
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="organization">Email ID :</label>
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      value={profile.email}
                      readOnly
                      // onChange={(e) => handleChange(e,"email")}
                    />
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="DOB">Date Of Birth :</label>
                    <input
                      className="form-control"
                      id="DOB"
                      type="date"
                      value={profile.DOB}
                      onChange={(e) => handleChange(e, "DOB")}
                      readOnly
                    />
                  </div>
                </div>


                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="gender">Gender :</label>
                    <input
                      className="form-control"
                      id="gender"
                      type="text"
                      value={profile.gender}
                      onChange={(e) => handleChange(e, "gender")}
                      readOnly
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="empid">Employee Id:</label>
                    <input
                      className="form-control"
                      id="empid"
                      type="text"
                      value={profile.empid}
                      onChange={(e) => handleChange(e, "empid")}
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="designation">Designation:</label>
                    <input
                      className="form-control"
                      id="designation"
                      type="text"
                      value={profile.designation}
                      onChange={(e) => handleChange(e, "designation")}
                      readOnly
                    />
                  </div>
                </div>
                </div>


                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="dateOfJoining">Date of Joining :</label>
                    <input
                      className="form-control"
                      id="dateOfJoining"
                      type="date"
                      value={profile.dateOfJoining}
                      onChange={(e) => handleChange(e, "dateOfJoining")}
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="pan">PAN :</label>
                    <input
                      className="form-control"
                      id="pan"
                      type="text"
                      value={profile.pan}
                      onChange={(e) => handleChange(e, "pan")}
                      readOnly
                    />
                  </div>
                </div>
                </div>


                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="aadhaarnumber">Aadhar Number :</label>
                    <input
                      className="form-control"
                      id="aadhaarnumber"
                      type="text"
                      value={profile.aadhaarnumber}
                      onChange={(e) => handleChange(e, "aadhaarnumber")}
                      readOnly
                    />
                  </div>
                </div>
               
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="addressName">Address :</label>
                    <textarea
                      className="form-control"
                      id="addressName"
                      type="text"
                      value={profile.address}
                      onChange={(e) => handleChange(e, "address")}
                      rows="2"
                    />
                  </div>
                </div>
                      <div>
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label for="designation">Designation:</label>
                    <input
                      className="form-control"
                      id="designation"
                      type="text"
                      value={profile.designation}
                      onChange={(e) => handleChange(e, "designation")}
                      readOnly
                    />
                  </div>
                </div>
                </div>
          
                {/* <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="addressProof">Upload Address Proof</label>
    {handleFilePreview(profile.imageMeta, profile.addressProof)}

    <input
      className="form-control"
      id="addressProof"
      type="file" 
      onChange={(e) => handleImageChange0(e, "addressProof")} 
      accept="image/jpeg, image/png, application/pdf" 
    />
  </div>
</div>
                
               */}
                </div>

                      
                
                <br />
                <input
                  type="submit"
                  value="save changes"
                  className="btn btn-primary"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default ViewEmpProfile;