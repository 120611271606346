import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";

const CreateTestsForPackage = ({ isOpen, onRequestClose, test }) => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState({
    testname: "",
    description: "",
    categoryid: "",
  });
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const cat = await axios.get(`/api/testcategory/status=true`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCategories(cat.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch packages or designations.");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (test) {
      setAdmin({
        testname: test.testname,
        description: test.description,
        categoryid: test.categoryid,
      });
    } else {
      setAdmin({
        testname: "",
        description: "",
        categoryid: "",
      });
    }
  }, [test]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    if (admin.testname && admin.categoryid) {
      try {
        const requestBody = { ...admin };
        if (test) {
          requestBody.id = test.id; // Include id only if updating
          await axios.post(`/api/labtest/update`, requestBody, {
            headers: { Authorization: `Bearer ${token}` },
          });
          toast.success("Test updated successfully");
        } else {
          await axios.post(`/api/labtest/create`, [requestBody], {
            headers: { Authorization: `Bearer ${token}` },
          });
          toast.success("Test created successfully");
        }
        onRequestClose();
        setAdmin({
          testname: "",
          description: "",
          categoryid: "",
        });
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Please fill out all the required fields.");
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  function rhandle(e) {
    const { id, value } = e.target;
    setAdmin((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{test ? "Update Test" : "Add Test"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="form-floating-sm mb-2">
                  <label htmlFor="categoryid">
                    Category <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    id="categoryid"
                    value={admin.categoryid}
                    onChange={rhandle}
                    required
                  >
                    <option value="">Select Category</option>
                    {categories.map((cat) => (
                      <option key={cat.id} value={cat.id}>
                        {cat.categoryname}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="testname">
                      Test Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      id="testname"
                      type="text"
                      placeholder="Test Name"
                      value={admin.testname}
                      onChange={rhandle}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="description">Descriptions</label>
                    <textarea
                      className="form-control"
                      id="description"
                      placeholder="Description"
                      value={admin.description}
                      onChange={rhandle}
                      rows="2"
                    />
                  </div>
                </div>

                <input
                  type="submit"
                  value={test ? "Update" : "Save"}
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default CreateTestsForPackage;
