import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../api/baseurl";

const TestForPackage = ({ isOpen, onRequestClose, testDetails }) => {
  useEffect(() => {
    // Optional: You can fetch additional data or perform actions on component mount
  }, []);

  return (
    <>
      <Modal show={isOpen} onHide={onRequestClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Tests Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
            Package Name: <strong>{testDetails[0]?.pac?.packagename}</strong>
            </p>
            <ul>
              {testDetails.map((test) => (
                <li key={test.id}>
                  {test.lab?.testCat?.categoryname} : <strong>{test.lab?.testname}</strong>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onRequestClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestForPackage;
