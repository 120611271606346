import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Form } from "react-bootstrap";

const CancelAppointment = ({ isOpen, onRequestClose, payment}) => {
  const [appointments, setAppointments] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (payment) {
        fetchAppointmentData(payment.id);
    }
  }, [payment]);

  const fetchAppointmentData = async (paymentId) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/employeeappointment/paymentid=${paymentId}`,
        {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

     setAppointments(response.data);
      setLoading(false);
    } catch (error) {
     setLoading(false);
     handleError(error);
    }
  };

  const handleRefund = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
        const response = await axios.post('/api/employeeappointment/cancel-and-refund', {
            appointmentId: appointments.id,
            razorpayPaymentId: payment.razorpay_payment_id,
            refundAmount: payment.paymentAmount
        },  {
            headers: { Authorization: `Bearer ${token}` },
          });

        toast.success(response.data);
        onRequestClose();
    } catch (error) {
        setLoading(false);
      handleError(error); // Handle errors as needed
    }
  };

  const handleError = (error) => {
    if (error.response) {
      // Check if the server responded with an error
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        // Display the error message if it exists
        toast.error(error.response.data.message);
      } else if (error.response.data && typeof error.response.data === 'string') {
        // If the error message is a plain string (like Razorpay's "BAD_REQUEST_ERROR")
        toast.error(error.response.data);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      // If the error has a message (general JS error)
      toast.error(error.message);
    } else {
      // Fallback error
      toast.error("An error occurred.");
    }
  };
  

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
            Refund
     </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-2">
            <div className="card-body p-4">
              <p>Are you sure you want to cancel this appointment?</p>
              {payment && (
                <Form.Group >
                  <Form.Label>Refund Amount</Form.Label>
                  <Form.Control 
                    type="text" 
                    value={`₹ ${payment.paymentAmount}`} 
                    //  readOnly
                  />
                  <Form.Text className="text-muted">
                    This will be a full refund.
                  </Form.Text>
                </Form.Group>
              )}
              <div className="d-flex justify-content-end mt-2">
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
                <Button
                  variant="danger"
                  onClick={handleRefund}
                  style={{ marginLeft: '10px' }}
                >
                    Refund
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CancelAppointment;
