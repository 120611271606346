import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever, MdEdit } from "react-icons/md";
import CreateDesignations from "./addDesignations";
import MapDesignationsAndPackage from "./packagesAndDesignationsMapping";
import "./Spinner.css";
import "./Loader.css";
import UploadDesignationsCsvFile from "./designationCsvUpload";
import DesinationCsvFormat from "./designationCsvFormat";
import { useNavigate } from "react-router";

function Designations({ selectedOrgcode }) {
  const [designations, setDesignations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isMapDesignationAndPackage, setIsMapDesignationAndPackage] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [isCsvUplaodModalOpen, setIsCsvUplaodModalOpen] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/designation/companycode=${selectedOrgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const fetchedDesignations = await Promise.all(
        response.data.map(async (designation) => {
          const packages = await fetchPackagesAndGender(designation.id);
          return { ...designation, packages };
        })
      );

      setDesignations(fetchedDesignations);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const fetchPackagesAndGender = async (designationId) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/packagedesignationmapping/designationid=${designationId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.map((item) => ({
        packageName: item.pac.packagename,
        gender: item.gender,
      }));
    } catch (error) {
      handleError(error);
      return [];
    }
  };

  const handleCreateCompany = () => {
    setSelectedDesignation(null);
    setIsModalOpen(true);
  };

  const handleEditDesignation = (designation) => {
    setSelectedDesignation(designation);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleOpenMapPopup = (designationId) => {
    setSelectedDesignation(designationId);
    setIsMapDesignationAndPackage(true);
  };

  const handleCloseMapPopup = () => {
    setSelectedDesignation(null);
    setIsMapDesignationAndPackage(false);
    getData();
  };

  const handleDeleteDesignation = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true); // set loader to true when deleting
    try {
      await axios.delete(`/api/designation/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Deleted successfully");
      getData(); // reload data after deletion
    } catch (error) {
      toast.error("Failed to delete designation.");
    } finally {
      setLoader(false); // reset loader after deletion attempt
    }
  };

  const [showImageModal, setShowImageModal] = useState(false);

  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  const uploadCsv = () => {
    setIsCsvUplaodModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsCsvUplaodModalOpen(false);
    getData();
  };

  return (
    <div>
      <div className="company-profile">
        <div className="card card-raised mb-5">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              <span>Designations</span>
              <div>
                <button className="btn btn-primary me-5" onClick={handleShowImageModal}>
                  View Format
                </button>
                <button className="btn btn-primary me-5" onClick={uploadCsv}>
                  UPLOAD CSV
                </button>
                <button className="btn btn-primary" onClick={handleCreateCompany}>
                  ADD
                </button>
              </div>
            </div>
            <br />
            <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                  <th>Designation</th>
                  <th>Package Name</th>
                  <th>Gender</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      Loading...
                    </td>
                  </tr>
                ) : designations.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  designations.map((designation) => (
                    <React.Fragment key={designation.id}>
                      {designation.packages.length === 0 ? (
                        <tr>
                          <td>{designation.designation}</td>
                          <td colSpan="2" className="text-center">No Packages Added</td>
                          <td>
                            <button
                              className="btn btn-link text-primary"
                              style={{ marginRight: "10px" }}
                              onClick={() => handleOpenMapPopup(designation.id)}
                            >
                              Link Package
                            </button>
                            <MdEdit
                              style={{ cursor: "pointer", marginRight: "10px" }}
                              title="Edit"
                              onClick={() => handleEditDesignation(designation)}
                            />
                            {/* <MdDeleteForever
                              style={{ cursor: "pointer" }}
                              title="Delete"
                              // onClick={() => handleDeleteDesignation(designation.id)}
                            /> */}
                          </td>
                        </tr>
                      ) : (
                        designation.packages.map((pkg, index) => (
                          <tr key={`${designation.id}-${index}`}>
                            {index === 0 ? (
                              <td rowSpan={designation.packages.length}>{designation.designation}</td>
                            ) : null}
                            <td>{pkg.packageName}</td>
                            <td>{pkg.gender}</td>
                            {index === 0 ? (
                              <td rowSpan={designation.packages.length}>
                                <button
                                  className="btn btn-link text-primary"
                                  style={{ marginRight: "10px" }}
                                  onClick={() => handleOpenMapPopup(designation.id)}
                                >
                                  Link Package
                                </button>
                                <MdEdit
                                  style={{ cursor: "pointer", marginRight: "10px" }}
                                  title="Edit"
                                  onClick={() => handleEditDesignation(designation)}
                                />
                                {/* <MdDeleteForever
                                  style={{ cursor: "pointer" }}
                                  title="Delete"
                                  // onClick={() => handleDeleteDesignation(designation.id)}
                                /> */}
                              </td>
                            ) : null}
                          </tr>
                        ))
                      )}
                    </React.Fragment>
                  ))
                )}
              </tbody>
            </table>

            {isModalOpen && (
              <CreateDesignations
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                companycode={selectedOrgcode}
                designation={selectedDesignation}
              />
            )}

            {isCsvUplaodModalOpen && (
              <UploadDesignationsCsvFile isOpen={isCsvUplaodModalOpen} onRequestClose={closeUploadModal} companycode={selectedOrgcode} />
            )}

            {showImageModal && (
              <DesinationCsvFormat isOpen={showImageModal} onRequestClose={handleCloseImageModal} />
            )}

            {isMapDesignationAndPackage && (
              <MapDesignationsAndPackage
                isOpen={isMapDesignationAndPackage}
                onRequestClose={handleCloseMapPopup}
                designationId={selectedDesignation}
                companycode={selectedOrgcode}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Designations;
