import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import {getCurrentUserDetail, isLoggedIn } from "../auth";
import Admin from "./admindashboard";

function Dashboard() {

  const [login, setLogin] = useState(false);
  const [user, setUser] = useState(undefined);
  

  useEffect(() => {
    setLogin(isLoggedIn());
    setUser(getCurrentUserDetail());
  }, [login]);
  return (
    <div>
      <Admin />
    </div>
  );
}
export default Dashboard;
