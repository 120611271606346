import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";

const TestCsvFile = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [tests, setTests] = useState({
    categoryid: "",
    file: null,
  });

  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [categories, setCategories] = useState([]);

  const url = "/api/labtest/upload";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const catUrl = `/api/testcategory/status=true`;

      try {
        const response = await axios.get(catUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCategories(response.data);
      } catch (error) {
      handleError(error);
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const formData = new FormData();
    formData.append("file", csvFile);
    formData.append("categoryid", tests.categoryid);

    try {
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      toast.success("CSV file uploaded successfully");
      onRequestClose();
      setTests({ categoryid: "", file: null });
      setCsvFile(null);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const rhandle = (e) => {
    const { id, value } = e.target;
    setTests((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleCsvFileChange = (e) => {
    const file = e.target.files[0];
    setCsvFile(file);
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="categoryid">Category</label>
                    <select
                      className="form-select"
                      id="categoryid"
                      value={tests.categoryid}
                      onChange={rhandle}
                    >
                      <option value="">Select Category</option>
                      {categories.map((cat) => (
                        <option key={cat.id} value={cat.id}>
                          {cat.categoryname}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="file" className="me-1">Upload Document:</label>
                    <input
                      className="form-control smaller-input flex-grow-1"
                      id="file"
                      type="file"
                      placeholder="Upload document"
                      onChange={handleCsvFileChange}
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default TestCsvFile;
