import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import AdminDashboard from "./admindashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function BulkEnable() {
  const { companycode } = useParams();
  const [loading, setLoading] = useState(false);
  const [employeesDetails, setEmployeesDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/employeedetails/getByCompanycode/${companycode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userProfileDetails = response.data;
      setEmployeesDetails(userProfileDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setSelectedEmployees([]);
  };

  const handleSelectEmployee = (empid) => {
    if (selectedEmployees.includes(empid)) {
      setSelectedEmployees(selectedEmployees.filter(id => id !== empid));
    } else {
      setSelectedEmployees([...selectedEmployees, empid]);
    }
  };

  const handleEnableDisable = async (enable) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    
    // Create payload where each object has an individual id
    const requestPayload = selectedEmployees.map(id => ({
      id: id,
      annualCheckup: !enable // Send 'false' to enable, 'true' to disable
    }));
  
    try {
      await axios.post(
        "/api/employeedetails/bulk-enable",
        requestPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success(`Employees successfully ${enable ? "enabled" : "disabled"} for annual checkup.`);
      getData();
    } catch (error) {
      handleError(error);
    }
  };
  

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };


  const filteredEmployees = employeesDetails.filter((emp) => {
    const matchesSearch = `${emp.name} ${emp.lastname}`?.toLowerCase().includes(searchTerm.toLowerCase()) || emp.empid?.toString().includes(searchTerm);
    const matchesStatus = statusFilter ? (statusFilter === "enabled" ? !emp.annualCheckup : emp.annualCheckup) : true;
    return matchesSearch && matchesStatus;
  });

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div>
        {loading && <div className="loading"></div>}
        <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="mb-2">
      <button className="btn btn-primary" onClick={handleBack}>
      Back
    </button>
    </div>
          <div className="card card-raised">
            <div className="card-body">
              <div className="card-title form-group d-flex align-items-center justify-content-between mb-4">
                <span>Bulk Update</span>
             
      <div className="position-relative w-25">
        <input
          type="text"
          placeholder="Search by name or Employee ID"
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control"
          style={{ paddingLeft: "30px" }} // Ensure there's space for the icon inside the input
        />
        <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
          <FontAwesomeIcon icon={faSearch} />
        </span>
        </div>

                <select
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                  className="form-select w-25"
                >
                  <option value="">ALL</option>
                  <option value="enabled">ENABLED</option>
                  <option value="disabled">DISABLED</option>
                </select>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                    <th>Select</th>
                    <th>Employee Id</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEmployees.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    filteredEmployees.map((emp, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={emp.annualCheckup === false ? true : selectedEmployees.includes(emp.id)}
                            onChange={() => handleSelectEmployee(emp.id)}
                          />
                        </td>
                        <td>{emp.empid}</td>
                        <td>{emp.name} {emp.lastname}</td>
                        <td>{emp.email}</td>
                        <td>{emp.phoneno}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="d-flex justify-content-end mt-4">
  <button
    className="btn btn-success me-2"
    onClick={() => handleEnableDisable(true)}
    disabled={selectedEmployees.length === 0}
  >
    Enable
  </button>
  <button
    className="btn btn-danger"
    onClick={() => handleEnableDisable(false)}
    disabled={selectedEmployees.length === 0}
  >
    Disable
  </button>
</div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BulkEnable;
