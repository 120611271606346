import React, { useEffect, useState } from "react"; 
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";

  function EmployeeFamilyDetails(props) {

  const { userid } = useParams();
  const[loading ,setLoading]=useState(false);
  const[familyDetails ,setFamilyDetails]=useState([]);
  const[isModalOpen ,setIsModalOpen]=useState(false);
  const[isEditModalOpen ,setIsEditModalOpen]=useState(false);
  const[selectedId ,setSelectedId]=useState("");

  const getData = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/familydetails/userid=${userid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userProfileDetails = response.data;
     setFamilyDetails(userProfileDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  useEffect(() => {
    getData();
  }, [userid]);

  const handleAddFamily = () => {
    setIsModalOpen(true);
  };
 
  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleEdit = async (id) => {
    setSelectedId(id);
    setIsEditModalOpen(true); 
  };
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    getData();
 
  };
  const onClickhandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/familydetails",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    setLoading(false); 
    getData();
  } catch (error) {
    setLoading(false); 
    handleError(error);
  }
};

const navigate = useNavigate();
const handleBack = async (orgcode) => {
  navigate(`/users/companydetails/${orgcode}`);
};

const openFamilyProfileModal = async (memberid) => {
  navigate(`/users/family-profile/${memberid}`);
};

  return (
    <div>
               
     {loading && <div className="loading"></div>} 
       
        <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="mb-2">  
        <button className="btn btn-primary" 
        // onClick={() => handleBack(profile.companycode)}
        >
      Back
    </button>
        </div>
        <div className="card card-raised">
          <div className="card-body">
            <div >
              <div className="card-title">Family details</div>{" "}
              <br />
              <br/>
              <br />
              <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                    <th>Name </th>
                    <th>Date of Birth</th>
                    <th>Gender</th>
                    <th>Phone Number</th>
                    <th>Relationship Status</th>
                    {/* <th>Marital Status</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {familyDetails.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                  familyDetails.map((members, index) => {
                    return (
                      <tr key={index}>
                        <td 
                        style={{
                          cursor: "pointer",
                        }}
                        title="View Appointments"
                        onClick={() => openFamilyProfileModal(members.id)}
                        onMouseOver={(e) => {
                          e.target.style.color = "blue";
                          e.target.style.textDecoration = "underline";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.color = "";
                          e.target.style.textDecoration = "";
                        }}
                      >
                        {members.name}</td>
                        <td>{members.dob}</td>
                        <td>{members.gender}</td>
                        <td>{members.phoneNumber}</td>
                        <td>{members.relationship}</td>
                        {/* <td>{members.maritalstatus}</td> */}
                        <td> 
                     <button
                      onClick={() => handleEdit(members.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              members.id,
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                       
                      
                   </td>
                      </tr>
                    );
                  }))}
                </tbody>
              </table>
              </div>
              </div>
    </div>
    </div>
    </div>
  );
}
 
export default EmployeeFamilyDetails;