import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import "./Spinner.css";
import "./Loader.css";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Pagination, Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AddExternalAccounts from './addExternalAccount';
 
function ExternalAccountDetails() {
  const [loading, setLoading] = useState(false);
  const [externalAccounts, setExternalAccounts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  
  const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
    const navigate = useNavigate();
    useEffect(() => {
      if (searchTerm) {
        searchData();
      } else {
        getData(currentPage);
      }
    }, [searchTerm, currentPage]);
  
   
    const searchData = async () => {
        setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token
      try {
        const searchUrl =`/api/externalaccount`;
        const response = await axios.get(searchUrl,  {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        setExternalAccounts(response.data);
        setLoading(false);
  
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    };
    const getData = async (pageNumber = 0) => {
      setLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
    
        const trans = await axios.get(`/api/externalaccount/getAll`, {
          params: { page: pageNumber }, 
          headers: { Authorization: `Bearer ${token}` },
        });
    
        console.log(trans);
    
        setExternalAccounts(trans.data.content);
        setCurrentPage(trans.data.number);
        setTotalPages(trans.data.totalPages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    };
    useEffect(() => {
      getData();
    }, []);
    const handlePageChange = (pageNumber) => {
      getData(pageNumber);
    };

    const handleAddAccounts = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
      getData();
  
    };

    const filteredData = externalAccounts.filter((agent) =>
      (agent.username && agent.username.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (agent.appname && agent.appname.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    
    const renderPageNumbers = () => {
      const pageNumbers = [];
      const maxPageNumbersToShow = 5;
      let startPage = Math.max(0, currentPage - 2);
      let endPage = Math.min(totalPages - 1, currentPage + 2);
  
      if (currentPage <= 2) {
        endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
      }
  
      if (currentPage >= totalPages - 3) {
        startPage = Math.max(0, totalPages - maxPageNumbersToShow);
      }
  
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
            {i + 1}
          </Pagination.Item>
        );
      }
      return pageNumbers;
    };
  
    function handleError(error) {
      if (error.response) {
        if (error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        }  else if (error.response.data && error.response.data.code) {
          toast.error(error.response.data.code);
        }
         else {
          toast.error("An error occurred.");
        }
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred.");
      }
    }

   
    return (
     <div>
  <div>
    <Dashboard />
  </div>
  {loading && <div className="loading"></div>}
    <div className="col-13  mx-xl-auto ps-xl-10">
      <div className="card card-raised " style={{marginTop:"10%"}}>
        <div className="card-body " >
        <div className="card-title form-group d-flex align-items-center justify-content-between">
          <span>External Accounts Details</span>
  
  <div>
   
      <button className="btn btn-primary" onClick={handleAddAccounts}>
        ADD ACCOUNT
      </button>
    </div>
             </div>
  
    <div className="col d-flex justify-content-center">
      <div style={{ position: "relative", width: "250px" }}>
        <input
          type="text"
          className="form-control ps-14"
          placeholder="Search by App name or Email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ paddingLeft: "30px" }}
        />
        <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
          <FontAwesomeIcon icon={faSearch} />
        </span>
      </div>
    </div>
    <div className="col-auto"></div> {/* Empty column to balance the layout */}
  </div>
  
  
              <br/>
              <Row>
              {filteredData.map((accd) => (
                
              <Col key={accd.id} md={4} className="mb-3">
   <div>
      <div className="card-body">
        <Card>
          <Card.Body>
        
                     <div className="card-title text-center">
   
                  <Card.Text>{accd.appname}</Card.Text>
                  </div>
                    <br/>
                    <Card.Text><strong>Email:</strong> {accd.username}</Card.Text>
                    <Card.Text><strong>Validity:</strong>  {accd.validity}</Card.Text>
                    <Card.Text><strong>Token:</strong>  {accd.token}</Card.Text>
                  </Card.Body>
                </Card>
                </div>
                </div>
               
                </Col>
               
            ))}
                  </Row>
                  <div className="d-flex justify-content-center">
                  <Pagination>
                    <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                    <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                    {renderPageNumbers()}
                    <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                    <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
                  </Pagination>
                </div>
              </div>
            </div>
            {isModalOpen && (
          <AddExternalAccounts isOpen={isModalOpen} onRequestClose={handleCloseModal}/>
        )}
</div>
  );
}
 
export default ExternalAccountDetails;