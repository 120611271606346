import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiInfo } from "react-icons/fi";
import TestForPackage from "./testsByPackagePopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function ViewEmployeeReportsByDoctor({user}) {
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    
    setLoader(true);
    try {
      const response = await axios.get(`/api/employeeappointment/getapproved-reports/userid=${user}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAppointmentDetails(response.data);
      setFilteredData(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    handleError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

//   const handleCancelAppointment = async (appointmentId) => {
//     const token = JSON.parse(localStorage.getItem("data")).token;
//     setLoader(true);
//     try{
//       await axios({
//       url: "/api/employeeappointment/update",
//       method: "post",
 
//       headers: { Authorization: `Bearer ${token}` },
 
//       data: {
//         id: `${appointmentId}`,
//         appointmentstatus:"Cancelled"
//       },
//     });
//     toast.success("Cancelled successfully");
//       getData();
//     } catch (error) {
//       console.error("Error cancelling appointment:", error);
//       setLoader(false);
//     }
//   };

const [pdfUrl, setPdfUrl] = useState(null);
const handleViewPdf = async (appointmentId) => {
  const token = JSON.parse(localStorage.getItem("data")).token;
  await axios({
    url: `/api/employeeappointment/download/id=${appointmentId}`,
    method: "GET",
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => {
    console.log("filedata;", response);
    const contentType = response.headers["content-type"];
    let extension = "";

    // Determine the file extension based on content type
    if (contentType.includes("application/pdf")) {
      extension = "pdf";
    } else if (contentType.includes("image/jpeg")) {
      extension = "jpg";
    } else if (contentType.includes("image/png")) {
      extension = "png";
    } else {
      console.error("Unsupported file type:", contentType);
      return;
    }

    // Create a blob URL and download link for the file
    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `file.${extension}`);
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  });
}
const handleBack = async () => {
  navigate(-1);
};

function handleError(error) {
  if (error.response) {
    if (error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else if (error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    }  else if (error.response.data && error.response.data.code) {
      toast.error(error.response.data.code);
    }
     else {
      toast.error("An error occurred.");
    }
  } else if (error.message) {
    toast.error(error.message);
  } else {
    toast.error("An error occurred.");
  }
}

const handleSearch = (e) => {
  const term = e.target.value.toLowerCase();
  setSearchTerm(term);
  console.log("Search term:", term); // Debug log
  const filtered = appointmentDetails.filter(
    (appointment) => {
      const reprtId = appointment.reportId.toLowerCase();
      return reprtId.includes(term);
    }
  );
  console.log("Filtered data:", filtered); // Debug log
  setFilteredData(filtered);
};

const [isTestsModalOpen, setIsTestsModalOpen] = useState(false);
  const [tests, setTests] = useState([]);

  const openTestsModal = async (pkg) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/packagetestmapping/packageid=${pkg}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTests(response.data);

      setIsTestsModalOpen(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
     handleError(error);
    }
  };
  
  const closeDenominationModal = () => {
    setIsTestsModalOpen(false);
   
  };

  return (
    <div>
      <div className="col-12 mx-xl-auto">
        <div className="mb-2">
      <button className="btn btn-primary" onClick={handleBack}>
      Back
    </button>
    </div>
        <div className="card card-raised">
          <div className="card-body">
          <div className="form-group position-relative" style={{ width: '300px' }}>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by Report Id"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{ paddingLeft: "2rem", width: "100%" }}
                />
                <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
              <br/>
            <div className="d-flex">
             
            <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                  <th>Employee Name</th>
                  <th>Date</th>
                  <th>Lab Name</th>
                  <th>Package Name</th>
                  <th>Report Id</th>
                  <th>Report</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length === 0 ? (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  filteredData.map((appointment) => (
                    <tr key={appointment.id}>
                        <td>{appointment.emplo?.name} {appointment.emplo?.lastname}</td>
                      <td>{appointment.date}</td>
                      <td>{appointment.labs.labname}</td>
                      <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{appointment.pack?.packagename}</span>
              <FiInfo
                 onClick={() => openTestsModal(appointment.packageid)}
                style={{
                  cursor: 'pointer'
                }}
              />
                      </td>
                      <td>{appointment.reportId}</td>
                      <td>
  {appointment.file ? (
    appointment.reportstatus === 'APPROVED' ? (
      <button
        className="btn btn-primary"
        onClick={() => handleViewPdf(appointment.id)}
      >
        View
      </button>
    ) : (
      "Approval Pending"
    )
  ) : (
    "No Report Added"
  )}
</td>
                                 
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        {isTestsModalOpen && (
<TestForPackage
        isOpen={isTestsModalOpen}
        onRequestClose={closeDenominationModal}
        testDetails={tests}
      />
)}
        {loader && <div className="loading"></div>}
      </div>
    </div>
    </div>
  );
}

export default ViewEmployeeReportsByDoctor;
