import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import "./Spinner.css";
import "./Loader.css";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import CreateAdminAccount from "./createAdminAccount";
import { toast } from "react-toastify";

function AdminDetails() {
  const [adminDetails, setAdminDetails] = useState([]);
  const [bioDetails, setBioDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const trans = await axios.get(`/api/accounts/role=ROLE_ADMIN`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log(trans);

      setAdminDetails(trans.data);

      // Fetch bio details for each admin
      const bioDataPromises = trans.data.map(async (admin) => {
        const accBio = await axios.get(`/api/account-bio/username=${admin.username}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        return { userId: admin.id, bio: accBio.data };
      });

      const bioDataArray = await Promise.all(bioDataPromises);

      // Map bio details with user IDs
      const bioDetailsMap = {};
      bioDataArray.forEach((bioData) => {
        bioDetailsMap[bioData.userId] = bioData.bio;
      });

      setBioDetails(bioDetailsMap);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleCreateCompany = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleClick = (userid) => {
    navigate(`/users/admin-profile/${userid}`);
  };

  const handleDelete = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true); // set loader to true when deleting
    try {
      await axios.delete(`/api/accounts/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Deleted successfully");
      getData(); // reload data after deletion
    } catch (error) {
     handleError(error);
    } finally {
      setLoader(false); // reset loader after deletion attempt
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>
        <Dashboard />
      </div>
      {loader && <div className="loading"></div>}
      <div>
        <div className="col-12 mx-xl-auto ps-xl-10" style={{ marginTop: "10%" }}>
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title form-group d-flex align-items-center justify-content-between">
                Admin Details
                <button className="btn btn-primary" onClick={handleCreateCompany}>
                  ADD
                </button>
              </div>

              <br />
              <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                    <th>Email</th>
                    <th>Name</th>
                    <th>Contact</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {adminDetails.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    adminDetails.map((admin) => {
                      const bio = bioDetails[admin.id] || {};
                      return (
                        <tr key={admin.id}>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClick(admin.id)}
                            onMouseOver={(e) => {
                              e.target.style.color = "blue";
                              e.target.style.textDecoration = "underline";
                            }}
                            onMouseOut={(e) => {
                              e.target.style.color = "";
                              e.target.style.textDecoration = "";
                            }}
                          >
                            {admin.username}
                          </td>
                          <td>{bio?.firstname} {bio?.lastname}</td>
                          <td>{bio.phone}</td>
                          <td>
                            <div style={{cursor:"pointer"}} title="Delete" onClick={() => handleDelete(admin.id)}>
                              <MdDeleteForever />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>

              <div>
                {isModalOpen && (
                  <CreateAdminAccount isOpen={isModalOpen} onRequestClose={handleCloseModal} />
                )}
              </div>
            </div>
          </div>
        </div>
        {loader && <div className="loading"></div>}
      </div>
    </div>
  );
}

export default AdminDetails;
