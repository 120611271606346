import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";

const CreatePackages = ({ isOpen, onRequestClose, selectedPackage }) => {
  const navigate = useNavigate();

  const createUrl = "/api/package/create";
  const updateUrl = `/api/package/update`; // Update URL

  const [admin, setAdmin] = useState({
    packagename: "",
    description: "",
    companyCode: "",
    price:"",
  });
  const [loading, setLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState([]);

  useEffect(() => {
    if (selectedPackage) {
      setAdmin({
        packagename: selectedPackage.packagename,
        description: selectedPackage.description,
        companyCode: selectedPackage.companyCode,
        price:selectedPackage.price,
      });
    }
  }, [selectedPackage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const url = selectedPackage ? updateUrl : createUrl;
    const method = "POST";

    if (admin.packagename && admin.companyCode) {
      try {
        const response = await axios({
          url,
          method,
          data: {
            id: selectedPackage ? selectedPackage.id : null,
            packagename: admin.packagename,
            description: admin.description,
            companyCode: admin.companyCode,
            price:admin.price
          },
          headers: { Authorization: `Bearer ${token}` },
        });

        setLoading(false);
        onRequestClose();
        setAdmin({
          packagename: "",
          description: "",
          companyCode: "",
          price:""
        });

        const action = selectedPackage ? "Updated" : "Added";
        toast.success(`Package ${action} Successfully`);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    } else {
      setLoading(false);
      toast.error("Please fill out all the required fields.");
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  function handleChange(e) {
    const { id, value } = e.target;
    if (id === "companyCode") {
      const companyCode = e.target.value;
      setAdmin((prevState) => ({
        ...prevState,
        companyCode: companyCode,
        packagename: `${companyCode}-`,
      }));
    } else if (id === "packagename") {
      const companyCodeWithHyphen = admin.companyCode ? `${admin.companyCode}-` : "";
      if (value.startsWith(companyCodeWithHyphen)) {
        setAdmin((prevState) => ({
          ...prevState,
          packagename: value,
        }));
      }
    }
      else if (id === "price") {
        if (/^\d*\.?\d*$/.test(value)) {
          setAdmin((prevState) => ({
            ...prevState,
           price: value,
          }));
        }
    } else {
      setAdmin((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  }

  const handleKeyDown = (e) => {
    const { id, selectionStart, key } = e.target;
    const companyCodeWithHyphen = admin.companyCode ? `${admin.companyCode}-` : "";
    if (id === "packagename" && selectionStart <= companyCodeWithHyphen.length && key === "Backspace") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const companyRes = await axios.get("/api/companydetails", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCompanyDetails(companyRes.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch company details.");
      }
    };
    fetchData();
  }, []);

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{selectedPackage ? "Update Package" : "Add Package"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="form-floating-sm mb-2">
                  <label htmlFor="companyCode">
                    Company <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    id="companyCode"
                    value={admin.companyCode}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Company</option>
                    {companyDetails.map((company) => (
                      <option key={company.id} value={company.companycode}>
                        {company.companyname}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="packagename">
                      Package Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      id="packagename"
                      type="text"
                      placeholder="Package Name"
                      value={admin.packagename}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="price">
                      Package Price <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      id="price"
                      type="text"
                      placeholder="0"
                      value={admin.price}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="description">Description</label>
                    <textarea
                      className="form-control"
                      id="description"
                      type="text"
                      placeholder="Description"
                      value={admin.description}
                      onChange={handleChange}
                      rows="2"
                    />
                  </div>
                </div>

                <input
                  type="submit"
                  value={selectedPackage ? "Update" : "Save"}
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default CreatePackages;
