import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import "./a4shett.css";
import tmplLogo from "../images/tmplLogo.png";

function PaymentReference() {
  const [amount, setAmount] = useState([]);
  const [appcode, setAppcode] = useState({});
  const [costs, setCost] = useState({});
  const [order, setOrder] = useState({});
  const [serialnumber, setSerialnumber] = useState(1);
  const [username, setUsername] = useState(""); //Declare and initialize the username state
  const date = new Date();
const [data , setdata]= useState([]);

const {id, familyid} = useParams();
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let nextyear = year + 1;
  let currentDate = `${day}-${month}-${year}`;

  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("data")).token;

  const getData = async () => {
    await axios.get(`/api/package/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((response) => {
      setCost(response.data);
    });
  };

  useEffect(() => {
    const fetchEmp = async () => {
      try {
        const res = await axios.get(
          `/api/employeedetails/userid=${JSON.parse(localStorage.getItem("data")).userid}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
        setdata(res.data);
        console.log(res.data);
      } catch (error) {
        console.error("Error fetching exempted customers:", error);
      }
    };

    fetchEmp();
  }, []);

  // const storedData = localStorage.getItem("RegisterationDatat").username;
  useEffect(() => {
    setUsername(JSON.parse(localStorage.getItem("data")).username);
    const storedPaymentId = localStorage.getItem('razorpay_payment_id');
    if (storedPaymentId) {
      setInvoice(storedPaymentId);
    }
    getData();
  }, []);
  function Printbill(e) {
    window.print();
    navigate(`/users/family-profile/${familyid}`);
  }
  let appcd = `${appcode.appCode}`;
  const [invoice, setInvoice] = useState("");

  const handleContinue =()=>
{
    navigate(`/users/family-profile/${familyid}`);
}
 
const price = parseFloat(costs.price);
  
// Calculate GST and platform fee
const gstRate = 0.00;
const platformFeeRate = 0.03;
const platformGstRate = 0.18;

const cgst = (price * gstRate) / 2;
const sgst = (price * gstRate) / 2;
const cost=( price + cgst + sgst);
const platformFee = cost * platformFeeRate;
const platformFeeGst = platformFee * platformGstRate;

const totalTransactionCost = price + cgst + sgst + platformFee + platformFeeGst;

  return (
    <div>
      <div id="layoutDrawer_content">
        <main>
          <div className="a4-sheet">
            <header className="bg-primary">
              <div className="container-xl px-5">
                <h1 class="text-white py-3 mb-0 display-6"></h1>
              </div>
            </header>
            <br />
            <br /> <br />
            <form>
             
                  <div>
                    <div className="d-flex">
                      <div>
                        <h5>
                          <b>RECEIPT</b>
                        </h5>
                        <br />
                        <h6>
                          <b>Timespan pvt ltd.</b>{" "}
                        </h6>{" "}
                        {/* <h6> 1010/27 Mysore Road</h6>{" "} */}
                        <h6>Bangalore , Karnataka</h6>
                        <h6> PIN </h6>
                        <h6>GSTIN- 29AAJCC7045D1ZM </h6>
                      </div>
                      <div>
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <img src={tmplLogo} width="125px" />
                      </div>
                    </div>
                    <br />
                    <div className="d-flex">
                      {" "}
                      <div>
                        <h6>
                          <b>Bill To</b>
                        </h6>

                        <h6>{`${username}`}</h6>
                        <h6>{data.address}</h6>
                      <h6>PAN-{data.pan}</h6>
                      </div>
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <h6>
                        {" "}
                        <b>Reference Number:</b>
                      </h6>
                      &ensp;&nbsp;
                      <h6>{`${invoice}`}</h6>
                    </div>
                    <hr style={{ height: "8px", color: "black" }} />
                    <div className="d-flex">
                      {" "}
                      <h2>Total Amount</h2>{" "}
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <h2>{totalTransactionCost.toFixed(2)} ₹</h2>
                    </div>

                    <hr style={{ height: "8px", backgroundColor: "black" }} />
                    <div className="d-flex">
                      <div></div>&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;
                      <div>
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        <div>
                          {" "}
                          <h6>Amount: {price.toFixed(2)}</h6>
        <h6>CGST: {cgst.toFixed(2)}</h6>
        <h6>SGST: {sgst.toFixed(2)}</h6>
        <h6>Platform Fee: {platformFee.toFixed(2)}</h6>
        <h6>Platform Fee GST: {platformFeeGst.toFixed(2)}</h6>
      
      </div>
      <h2 style={{ color: "black" }}>
        Total Amount: {totalTransactionCost.toFixed(2)} ₹
      </h2>
                      </div>
                    </div>

                    <br />
                    <div className="text-end d-flex">
                      &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                      &emsp;&emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;
                      &emsp;&emsp;&emsp; &emsp;&emsp;&emsp;
                      <button
                        onClick={(e) => Printbill(e)}
                        className="btn btn-primary"
                      >
                        Print
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        onClick={(e) => handleContinue()}
                        className="btn btn-primary"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PaymentReference;
