import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import "./Spinner.css";
import "./Loader.css";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CreateLabCorporate from "./addLabCorporate";

function LabCorporateDetails() {
  const [labCorporateDetails, setLabCorporateDetails] = useState([]);
  const [linkedLabs, setLinkedLabs] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();

  const getData = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const trans = await axios.get(`/api/lab-corporate`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setLabCorporateDetails(trans.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

 
  const handleAdd = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleClick = (orgcode) => {
    navigate(`/users/lab-corporate-profile/${orgcode}`);
  };

  const handleViewLabs = (corporateId) => {
    navigate(`/users/corporate-labs/${corporateId}`);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <div>
      {loader && <div className="loading"></div>}
        <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title form-group d-flex align-items-center justify-content-between">
                Lab Corporate Details
                <button className="btn btn-primary" onClick={handleAdd}>
                  ADD
                </button>
              </div>

              <br />
              <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                    <th>Email</th>
                    <th>Name</th>
                    <th>Orgcode</th>
                    <th>Contact</th>
                    <th>Client Code</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {labCorporateDetails.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    labCorporateDetails.map((accd) => (
                      <tr key={accd.id}>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClick(accd.orgcode)}
                          onMouseOver={(e) => {
                            e.target.style.color = "blue";
                            e.target.style.textDecoration = "underline";
                          }}
                          onMouseOut={(e) => {
                            e.target.style.color = "";
                            e.target.style.textDecoration = "";
                          }}
                        >
                          {accd.email}
                        </td>
                        <td>{accd.name}</td>
                        <td>{accd.orgcode}</td>
                        <td>{accd.contact}</td>
                        <td>{accd.clientId}</td>
                        <td style={{ fontSize: "17px", cursor: "pointer" }}>
                          <div>
                          <button
                            className="btn btn-primary"
                            onClick={() => handleViewLabs(accd.id)}
                          >
                            View Labs
                          </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <div>
                {isModalOpen && (
                  <CreateLabCorporate
                    isOpen={isModalOpen}
                    onRequestClose={handleCloseModal}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {loader && <div className="loading"></div>}
      </div>
    </div>
  );
}

export default LabCorporateDetails;
