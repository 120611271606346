import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";

const CreateCategories = ({ isOpen, onRequestClose, categoryId }) => {
  const navigate = useNavigate();
  const createUrl = "/api/testcategory/create";
  const updateUrl = `/api/testcategory/update`;
  const getCategoryUrl = `/api/testcategory/${categoryId}`;

  const [admin, setAdmin] = useState({
    categoryname: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (categoryId) {
      // Fetch existing category data
      const fetchCategory = async () => {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("data")).token;
        try {
          const response = await axios.get(getCategoryUrl, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setAdmin({
            categoryname: response.data.categoryname,
            description: response.data.description,
          });
        } catch (error) {
          handleError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchCategory();
    }
  }, [categoryId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    if (admin.categoryname) {
      const url = categoryId ? updateUrl : createUrl;
      const data = {
        categoryname: admin.categoryname,
        description: admin.description,
        ...(categoryId && { id: categoryId }), // Add categoryId to the data if updating
      };

      axios({
        method: "post", // Use POST method for both create and update
        url: url,
        data: data,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          setLoading(false);
          onRequestClose();
          setAdmin("");
          toast(categoryId ? "Category Updated Successfully" : "Category Added Successfully");
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
          setAdmin("");
        });
    } else {
      setLoading(false);
      toast.error("Please fill out all the required fields.");
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  function rhandle(e) {
    const { id, value } = e.target;
    setAdmin((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{categoryId ? "Update Category" : "Add Category"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="categoryname">
                      Category Name <span style={{ color: "red" }}>* </span>
                    </label>
                    <input
                      className="form-control"
                      id="categoryname"
                      type="text"
                      placeholder="Category Name"
                      value={admin.categoryname}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="description">Descriptions</label>
                    <textarea
                      className="form-control"
                      id="description"
                      placeholder="Description"
                      value={admin.description}
                      onChange={(e) => rhandle(e)}
                      rows="2"
                    />
                  </div>
                </div>

                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default CreateCategories;
