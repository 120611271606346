import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";

import labCsvFormat from '../images/labsCsvFormat.png';

const LabCsvFormat = ({ isOpen, onRequestClose }) => {
  

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>CSV Format</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
      <img src={labCsvFormat} alt="CSV Format" style={{ width: '100%' }} />
      </div>
      <br/>
      <h6>
        Follow this Format for Lab CSV upload.
      </h6>

      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default LabCsvFormat;
