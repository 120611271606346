import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router";
import CreateYearSettings from "./addYearSettings";

function FinancialYearSettings() {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null); 
  const [selectedYear, setSelectedYear] = useState(null); 
  const token = JSON.parse(localStorage.getItem("data")).token;
  const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
  const [selectedId, setSelectedId] = useState(null);

  const getData = async () => {
    setLoading(true);

    try {
      const performanceTargetResponse = await axios.get(`/api/year-settings`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    
      setProduct(performanceTargetResponse.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  useEffect(() => {
    getData();
  }, [selectedDate, selectedMonth, selectedYear]);

  const handleCloseModal = () => {
    setShowModal(false);
    getData();
    setModalData([]);
  };

  const handleAddProduct = () => {
    setIsModalOpen(true);
    setProduct([]);
  };

  const handleEdit = async (id) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };

  const handleAddCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  // let date = new Date();
  // let month = date.getMonth(); // This returns the current month as a number (0-11)
  // let year = date.getFullYear(); // This returns the current year as a number

  // let currentYear = year;
  // let nextYear = year + 1;

  // // If the month is January (0), February (1), or March (2)
  // if (month <= 2) {
  //   currentYear = year - 1;
  //   nextYear = year;
  // }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    try {
      await axios.post(`/api/performance/update`, modalData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Target Updated Successfully");
      handleCloseModal(); 
    } catch (error) {
     handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const monthMap = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  return (
    <div>
      <AdminDashboard />
      <div className="col-10 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            {loading && <div className="loading"></div>}
            <div className="card-title form-group d-flex align-items-center justify-content-between">Settings
           <button className="btn btn-primary" onClick={handleAddProduct}>
             ADD
           </button> 
         </div>
            <br />
            <table className="table table-bordered">
              <thead>
                <tr className="table-header">
                  <th>From Date</th>
                  <th>To Date</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {product.length === 0 ? (
                  <tr>
                    <td colSpan="2" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  product.map((currentProduct, index) => {
                    // let date = new Date();
                    // let currentMonth = date.getMonth() + 1; // Current month as number (1-12)
                    // let productFromMonth = monthMap[currentProduct.fromDate]; // Convert month name to number

                    // // Default year values
                    // let currentYear = date.getFullYear();
                    // let nextYear = currentYear + 1;

                    // if (currentMonth < productFromMonth) {
                    //   currentYear -= 1;
                    //   nextYear = currentYear + 1;
                    // }

                    return (
                      <tr key={currentProduct.id}>
                            <td>{currentProduct.fromDate} </td>
                            <td>{currentProduct.toDate}</td>
                            {/* <td>
                             
                            </td> */}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* {showModal && (
        <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Performance Target Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {loading && <div className="loading"></div>}
              <div>
                <form onSubmit={handleSubmit}>
                  <div>Financial Year: {selectedDate}</div>
                  <div>Orgcode: {orgCode}</div> 
                  <br/>
                  {modalData.length > 0 ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Product Code</th>
                          <th>Product Name</th>
                          <th>Target</th>
                          <th>Annual Quantity</th>
                          <th>Monthly Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modalData.map((data, index) => (
                          <tr key={data.id}>
                            <td>{data.productDetails.productcode}</td>
                            <td>{data.productDetails.productname}</td>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={data.target}
                                onChange={(e) => handleChange(e, index)}
                              />
                            </td>
                            <td>{data.annualQuantity}</td>
                            <td>{data.monthlyQuantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No data available for the selected date</p>
                  )}
                  <br />
                  <div className="d-flex justify-content-end">
                    <Button type="submit" className="btn btn-primary">
                      Save Changes
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal} className="ms-2">
                      Close
                    </Button>
                  </div>
                </form>
              </div>
          </Modal.Body>
        </Modal>
      )} */}

      {isModalOpen && (
        <CreateYearSettings isOpen={isModalOpen} onRequestClose={handleAddCloseModal} 
        id={selectedId}
        />
      )}
    </div>
  );
}

export default FinancialYearSettings;
