import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "../api/baseurl";
import { Button, Modal } from "react-bootstrap";
import DownloadDocuments from "./downloadDocuments";


function Footer() {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

    const currentYear = new Date().getFullYear();


	function cynefianwebsite(e){
		window.open("http://www.cynefian.com","_blank");
	  }

	//   useEffect(() => {
	// 	getData();
	// }, []);
	//   const mappedBuildEnv = appcode.buildEnv ? appcode.buildEnv.charAt(0) : "";
	//   const shortGitHash = appcode.gitHash ? appcode.gitHash.slice(0, 8) : "";

	
    return(
//         <div>

// {/* <!-- Start Footer Area --> */}
// 	<footer class="footer">
// 		{/* <!-- Footer Top --> */}
// 		<div class="footer-top section">
// 			<div class="container">
// 				<div class="row">
// 					<div class="col-lg-5 col-md-6 col-12">
// 						{/* <!-- Single Widget --> */}
// 						<div class="single-footer about">
// 							<div class="logo">
// 								<a href="index.html"><img src={logoimage} alt="#"/></a>
// 							</div>
// 							<p class="text">Praesent dapibus, neque id cursus ucibus, tortor neque egestas augue,  magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p>
// 							<p class="call">Got Question? Call us 24/7<span><a href="tel:123456789">+0123 456 789</a></span></p>
// 						</div>
// 						{/* <!-- End Single Widget --> */}
// 					</div>
// 					<div class="col-lg-2 col-md-6 col-12">
// 						{/* <!-- Single Widget --> */}
// 						<div class="single-footer links">
// 							<h4>Information</h4>
// 							<ul>
// 								<li><a href="#">About Us</a></li>
// 								<li><a href="#">Faq</a></li>
// 								<li><a href="#">Terms & Conditions</a></li>
// 								<li><a href="#">Contact Us</a></li>
// 								<li><a href="#">Help</a></li>
// 							</ul>
// 						</div>
// 						{/* <!-- End Single Widget --> */}
// 					</div>
// 					<div class="col-lg-2 col-md-6 col-12">
// 						{/* <!-- Single Widget --> */}
// 						<div class="single-footer links">
// 							<h4>Customer Service</h4>
// 							<ul>
// 								<li><a href="#">Payment Methods</a></li>
// 								<li><a href="#">Money-back</a></li>
// 								<li><a href="#">Returns</a></li>
// 								<li><a href="#">Shipping</a></li>
// 								<li><a href="#">Privacy Policy</a></li>
// 							</ul>
// 						</div>
// 						{/* <!-- End Single Widget --> */}
// 					</div>
// 					<div class="col-lg-3 col-md-6 col-12">
// 						{/* <!-- Single Widget --> */}
// 						<div class="single-footer social">
// 							<h4>Get In Tuch</h4>
// 							{/* <!-- Single Widget --> */}
// 							<div class="contact">
// 								<ul>
// 									<li>NO. 342 - London Oxford Street.</li>
// 									<li>012 United Kingdom.</li>
// 									<li>info@eshop.com</li>
// 									<li>+032 3456 7890
// </li>
// 								</ul>
// 							</div>						<ul>
// 								<li><a href="#"><i className="material-icons"><FaFacebookF/></i></a></li>
// 								<li><a href="#"><i className="material-icons"><FiTwitter/></i></a></li>
// 								<li><a href="#"><i class="material-icons"><TfiFlickr/></i></a></li>
// 								<li><a href="#"><i class="material-icons"><FaInstagram/></i></a></li>
// 							</ul>
// 						</div>
// 						{/* <!-- End Single Widget --> */}
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 		{/* <!-- End Footer Top --> */}
// 		<div class="copyright">
// 			<div class="container">
// 				<div class="inner">
// 					<div class="row">
// 						<div class="col-lg-6 col-12">
// 							<div class="left">
// 							<div className="fw-500 text-white">
//                   Copyright &copy;
//                   <Link style={{color:"white",textDecoration:"none"}} onClick={(e)=>cynefianwebsite(e)}>
//                   {""}
//                   cynefian website
//                   </Link>{""}
//                   &ensp;2023 -  All Rights Reserved

//                 </div>
// 							</div>
// 						</div>
// 					    <div className="col-lg-5 col-10" style={{color:"white",textDecoration:"none"}} >
//         <h6>{`VER : ${appcode.buildVersion} - #${shortGitHash} - ${mappedBuildEnv}`}</h6>
//       </div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	</footer>
// 	{/* <!-- /End Footer Area --> */}
//         </div>
<footer className="p-4">
            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between small">
              <div className="me-sm-3 mb-2 mb-sm-0">
                <div className="fw-500 text-white">
                  Copyright &copy;
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    onClick={(e) => cynefianwebsite(e)}
                  >
                    {" "}
                    Cynefian
                  </Link>{" "}
                  {currentYear}
                  
                 </div>
             

              </div>
              


              <div className="ms-sm-3">
                <Link
                  className="fw-500 text-decoration-none link-white"
                  to="/privacypolicy"
                >
                  Privacy
                </Link>
                <Link
                  className="fw-500 text-decoration-none link-white mx-4"
                  to="/termsandcondition"
                >
                  Terms
                </Link>
           <a  style={{ cursor: "pointer" }}
              className="fw-500 text-decoration-none link-white"
              onClick={handleShow} // Open modal on click
          >
            Help
          </a>
              </div>
            </div>
           {showModal && (
             <DownloadDocuments isOpen={showModal} onRequestClose={handleClose}/>
           )}
            
          </footer>

    )
}
 
export default Footer;