import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import "./Spinner.css";
import "./Loader.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateCompanyDetails from "./createCompanyDetails";
import UploadCompaniesCsvFile from "./uploadCompaniesCsvFile";
import CsvFormat from "./csvFormatImage";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CompanyDetails() {
  const [companyDetails, setCompanyDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCsvUplaodModalOpen, setIsCsvUplaodModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCompany, setFilteredCompany] = useState([]);

  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      const trans = await axios.get("/api/companydetails", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCompanyDetails(trans.data);
      setFilteredCompany(trans.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handleCreateCompany = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const uploadCsv = () => {
    setIsCsvUplaodModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsCsvUplaodModalOpen(false);
    getData();
  };

  const handleViewCompanyDetails = (orgcode) => {
    navigate(`/users/companydetails/${orgcode}`);
  };

  useEffect(() => {
    getData();
  }, []);

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = companyDetails.filter(
      (company) =>
        company.companyname?.toLowerCase().includes(term) || company.companycode?.toLowerCase().includes(term)
    );
    setFilteredCompany(filtered);
  };

  const [showImageModal, setShowImageModal] = useState(false);

  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <div>
        <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title form-group d-flex align-items-center justify-content-between">
                <span>Company Details</span>
                <div className="form-group position-relative" style={{ width: '300px' }}> {/* Adjust width as needed */}
  <input
    type="search"
    className="form-control pl-4"
    placeholder="Search by company name or company code"
    value={searchTerm}
    onChange={handleSearch}
    style={{ paddingLeft: "2rem", width: "100%" }} // Ensure the input takes the full width of the parent div
  />
  <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
    <FontAwesomeIcon icon={faSearch} />
  </span>
</div>

                <div>

                <button className="btn btn-primary me-5" onClick={handleShowImageModal}>
                View Format
              </button>
                  <button className="btn btn-primary me-5" onClick={uploadCsv}>
                    UPLOAD CSV
                  </button>
                  <button className="btn btn-primary" onClick={handleCreateCompany}>
                    ADD
                  </button>
                </div>
              </div>

              <br />
              <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                    <th>Company Name</th>
                    <th>Email</th>
                    <th>Company Code</th>
                    <th>Contact Number</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCompany.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    filteredCompany.map((accd) => (
                      <tr key={accd.comapnycode}>
                        <td
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => handleViewCompanyDetails(accd.companycode)}
                          onMouseOver={(e) => {
                            e.target.style.color = "blue";
                            e.target.style.textDecoration = "underline";
                          }}
                          onMouseOut={(e) => {
                            e.target.style.color = "";
                            e.target.style.textDecoration = "";
                          }}
                        >
                          {accd.companyname}
                        </td>
                        <td>{accd.username}</td>
                        <td>{accd.companycode}</td>
                        <td>{accd.contact}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <div>
                {isModalOpen && (
                  <CreateCompanyDetails isOpen={isModalOpen} onRequestClose={handleCloseModal} />
                )}
                {isCsvUplaodModalOpen && (
                  <UploadCompaniesCsvFile isOpen={isCsvUplaodModalOpen} onRequestClose={closeUploadModal} />
                )}

          {showImageModal && (
           <CsvFormat isOpen={showImageModal} onRequestClose={handleCloseImageModal} />
           )}
              </div>
            </div>
          </div>
        </div>
        {loader && <div className="loading"></div>}
      </div>
    </div>
  );
}

export default CompanyDetails;
