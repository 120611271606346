import React, { useState} from "react";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AdminDashboard from "./admindashboard";

function Security() {
  const token = JSON.parse(localStorage.getItem("data")).token;

  const [pass, setpass] = useState({
    password: "",
    passwordConfirm: "",
  });
  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setpass({ ...pass, [field]: actualValue });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(pass);

    //submit the data to server to generate token
    axios
      .post("/api/changePassword", pass, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data)
      .then(
        (result) => {
          console.log(result);
          navigate("/Signin");
          alert("Password Change Successfully");
        },
        (error) => {
          this.setState({ error });
        }
      );
  };
  return (
    <main>
      <div>
        <AdminDashboard />
      </div>{" "}
      <header className="bg-dark">
        <div className="container-xl px-5">
          <h1 className="text-white py-3 mb-0 display-6">
            Account Settings - Security
          </h1>
        </div>
      </header>
      <div
        className="container-xl p-10 px-11"
        style={{ position: "relative", left: "100px" }}
      >
        &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <mwc-tab-bar style={{ marginBottom: "-1px" }} activeIndex="3">
          {/* <Link
            to="/users/subscriptionbilling"
            style={{ textDecoration: "none" }}
          >
            <mwc-tab
              label="Subscriptions"
              icon="account_balance"
              stacked
              onClick='location.href="app-account-billing.html"'
            ></mwc-tab>
          </Link>{" "}
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
          <Link to="/users/notification" style={{ textDecoration: "none" }}>
            {" "}
            <mwc-tab
              label="Notifications"
              icon="notifications"
              stacked
              onClick='location.href="app-account-notifications.html"'
            ></mwc-tab>
          </Link>
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <mwc-tab
            label="Security"
            icon="security"
            stacked
            activeIndex="3"
          ></mwc-tab>
        </mwc-tab-bar>
        <hr className="mt-0 mb-5" />
        <div className="container-xl px-4">
          <h1 className="text-black py-3 mb-0 display-6">
            Account Settings - Security
          </h1>
        </div>
        <div className="row gx-5">
          <div className="col-lg-7">
            <div className="card card-raised mb-5">
              <div className="card-body p-5">
                <div className="card-title">Change Password</div>
                <div className="card-subtitle mb-4">
                  New passwords must contain at least 8 characters.
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="mb-4">
                    <input
                      class="form-control"
                      label="New Password"
                      outlined
                      placeholder="Enter new password"
                      type="password"
                      id="password"
                      value={pass.password}
                      onChange={(e) => handleChange(e, "password")}
                    ></input>
                  </div>

                  <div className="mb-8">
                    <input
                      class="form-control"
                      label="Confirm New Password"
                      outlined
                      placeholder="Confirm password"
                      type="password"
                      id="passwordConfirm"
                      value={pass.passwordConfirm}
                      onChange={(e) => handleChange(e, "passwordConfirm")}
                    ></input>
                  </div>
                  <div className="text-end">
                    <button className="btn btn-primary" type="submit">
                      Reset Password
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="card card-raised mb-5">
              <div className="card-body p-5">
                <div className="card-title">Security Preferences</div>
                <div className="card-subtitle mb-5">
                  Change how other people view your account.
                </div>

                <div className="h6 mb-1">Account Privacy</div>
                <p className="small text-muted">
                  By setting your account to private, your profile information
                  and posts will not be visible to users outside of your user
                  groups.
                </p>
                <form>
                  <mwc-formfield label="Public (posts are available to all users)">
                    <mwc-radio name="securityPreference" checked></mwc-radio>
                  </mwc-formfield>
                  <mwc-formfield label="Private (posts are available to only users in your groups)">
                    <mwc-radio name="securityPreference"></mwc-radio>
                  </mwc-formfield>
                </form>
                <hr className="my-5" />

                <div className="h6 mb-1">Data Sharing</div>
                <p className="small text-muted">
                  Sharing usage data can help us to improve our products and
                  better serve our users as they navigation through our
                  application. When you agree to share usage data with us, crash
                  reports and usage analytics will be automatically sent to our
                  development team for investigation.
                </p>
                <form>
                  <mwc-formfield label="Yes, share data and crash reports with app developers">
                    <mwc-radio name="dataSharing" checked></mwc-radio>
                  </mwc-formfield>
                  <mwc-formfield label="No, limit my data sharing with app developers">
                    <mwc-radio name="dataSharing"></mwc-radio>
                  </mwc-formfield>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default Security;
