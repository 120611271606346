import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import "./a4shett.css";
import tmplLogo from "../images/tmplLogo.png";

const convertCurrencyValToNum = (value) => {
  if (!value) return 0;
  return Number(value?.toString()?.replace(/[^0-9.-]+/g, ""));
};

function Payment() {
  const [costs, setCost] = useState("");
  const [order, setOrder] = useState({});
  const [serialnumber, setSerialnumber] = useState(1);
const [data , setdata]= useState([]);
const [storedData, setStoredData] = useState({});

const {id}=useParams();
const razorpayKey = process.env.REACT_APP_RAZORPAY_KEY;
const razorpaySecret = process.env.REACT_APP_RAZORPAY_SECRET;

const token = JSON.parse(localStorage.getItem("data")).token;

  const date = new Date();

  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;
  const [invoice, setInvoice] = useState(
    "IN" - currentDate - "xxx" - serialnumber
  );
  const navigate = useNavigate();
  const userid = JSON.parse(localStorage.getItem("data"))?.userid;
  const username = JSON.parse(localStorage.getItem("data"))?.username;

  useEffect(() => {
    const fetchEmp = async () => {
      try {
        const res = await axios.get(
          `/api/employeedetails/userid=${JSON.parse(localStorage.getItem("data")).userid}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
        setdata(res.data);
        console.log(res.data);
      } catch (error) {
        console.error("Error fetching exempted customers:", error);
      }
    };

    fetchEmp();
  }, []);
  
  const handlePayment = async (e, totalamount) => {
    e.preventDefault();
  
    // Convert the total amount to paise (smallest currency unit) and ensure it's an integer
    const amountInPaise = Math.round(totalamount * 100);
    const orderId = order.orderId;
  
      try {
        const response = await axios.post('/api/payment/createOrder', {
            amount: amountInPaise,
            currency: 'INR',
            orderId: orderId,
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const orderData = response.data;
        setOrder(orderData);
        if (window.Razorpay) {
          var options = {
            key: razorpayKey,
            key_secret: razorpaySecret,
            amount: amountInPaise, // Amount in paise
            currency: "INR",
            name: "cynefian",
            orderId: response.data.orderId,
            description: "for testing purpose",
            handler: function (response) {
              console.log(response, "aaa");
              localStorage.setItem('razorpay_payment_id', response.razorpay_payment_id);
              var values = {
                razorpay_payment_id: response.razorpay_payment_id,
                amount: amountInPaise / 100, // Store amount in rupees for your database
                paymentStatus: response.paymentStatus,
                currency: "INR",
                razorpay_order_id: response.razorpay_order_id,
                userid:JSON.parse(localStorage.getItem("data")).userid,
                orgcode:JSON.parse(localStorage.getItem("data")).orgcode
              };
      
              var appointment = {
                entity:{
                familyid: storedData.familyid,
                date: storedData.date,
                labcode: storedData.labcode,
                packageid: id,
                userid: userid,
                },
                payment: {
                  username: username,
                  id: response.id,
                  razorpay_payment_id: response.razorpay_payment_id,
                  amount: amountInPaise / 100, // Store amount in rupees for your database
                  paymentStatus: response.paymentStatus,
                  currency: "INR",
                  razorpay_order_id: response.razorpay_order_id,
                  userid:userid,
                  orgcode:JSON.parse(localStorage.getItem("data")).orgcode
                },
              };
                    axios.post(`/api/employeeappointment/create-familyappointment`, appointment, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    axios.post(`/api/payment/processPayment`, values, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                        .then((res) => {
                          navigate(`/users/payment-reference/${id}/${storedData.familyid}`);
                            toast.success("Payment successful and appointment booked successfully");
                        });
                }
            };
            const pay = new window.Razorpay(options);
            pay.open();
        } else {
            toast.error('Razorpay script not loaded');
        }
    } catch (error) {
      console.log(error);
    }
  };
  
  const getData = async () => {
    // await axios.get(`/api/paymentgatewaytax`).then((response) => {
    //   setAmount(response.data);
    // });

      const response = await axios.get(`/api/package/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
     setCost(response.data);
  };


  useEffect(() => {
    const storedAppointmentData = JSON.parse(localStorage.getItem("appointmentData"));
    setStoredData(storedAppointmentData); 
    getData();
  }, []);

  const price = parseFloat(costs.price);
  
  // Calculate GST and platform fee
  const gstRate = 0.0;
  const platformFeeRate = 0.03;
  const platformGstRate = 0.18;

  const cgst = (price * gstRate) / 2;
  const sgst = (price * gstRate) / 2;
  const cost=( price + cgst + sgst);
  const platformFee = cost * platformFeeRate;
  const platformFeeGst = platformFee * platformGstRate;

  const totalTransactionCost = price + cgst + sgst + platformFee + platformFeeGst;

  const handleClose = async (e,memberid) => {
    e.preventDefault();
      navigate(`/users/family-profile/${memberid}`);
    };

  return (
    <div>
      <div id="layoutDrawer_content">
        <main>
          <div className="a4-sheet">
            <header className="bg-primary">
              <div className="container-xl px-5">
                <h1 class="text-white py-3 mb-0 display-6"></h1>
              </div>
            </header>
            <br />
            <br /> <br />
            <form>
                  <div>
                    <div className="d-flex">
                      <div>
                        <h6>
                          <b>Timespan pvt ltd.</b>{" "}
                        </h6>{" "}
                        {/* <h6> 1010/27 Mysore Road</h6>{" "} */}
                        <h6>Bangalore , Karnataka</h6>
                        <h6> PIN </h6>
                        <h6>GSTIN- 29AAJCC7045D1ZM </h6>
                      </div>
                      <div>
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <img src={tmplLogo} width="125px" height={"70px"} />
                      </div>
                    </div>
                    <br />
                    <div>
                      {" "}
                      <h6> 
                        <b>To</b>
                      </h6>
                      <h6>{`${username}`}</h6>
                      
                      <h6>{data.address}</h6>
                      <h6>PAN-{data.pan}</h6>

                      
                    </div>
                    <hr style={{ height: "8px", color: "black" }} />
                    <div className="d-flex">
                      {" "}
                      <h2>Total Amount</h2>{" "}
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <h2>{totalTransactionCost.toFixed(2)} ₹</h2>
                    </div>

                    <hr style={{ height: "8px", backgroundColor: "black" }} />
                    <div className="d-flex">
                      <div></div>&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;
                      <div>
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        <div>
                          {" "}
                          <h6>Amount: {price.toFixed(2)}</h6>
        <h6>CGST: {cgst.toFixed(2)}</h6>
        <h6>SGST: {sgst.toFixed(2)}</h6>
        <h6>Platform Fee: {platformFee.toFixed(2)}</h6>
        <h6>Platform Fee GST: {platformFeeGst.toFixed(2)}</h6>
      
      </div>
      <h2 style={{ color: "black" }}>
        Total Amount: {totalTransactionCost.toFixed(2)} ₹
      </h2>
                      </div>
                    </div>
                    <br />
                    <div className="text-end d-flex">
                      &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                      &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <button
                        onClick={(e) => handleClose(e, storedData.familyid)}
                        className="btn btn-primary"
                      >
                        Cancel
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        onClick={(e) => handlePayment(e, totalTransactionCost)}
                        className="btn btn-primary"
                      >
                        Proceed
                      </button>
                     
                      
                    </div>
                  </div>
                {/* ); */}
              {/* })} */}
            </form>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Payment;
