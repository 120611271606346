import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import CreateCategories from "./addCategory";
import { BiEditAlt } from "react-icons/bi";
import AdminDashboard from "./admindashboard";

function TestCategories() {
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [loader, setLoader] = useState(true);

  const getData = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/testcategory/status=true`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setCategories(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  const handleCreateCategory = () => {
    setEditCategoryId(null); // Clear editCategoryId for creating a new category
    setIsModalOpen(true);
  };

  const handleEditCategory = (id) => {
    setEditCategoryId(id); // Set the category ID to be edited
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDeleteTestPackage = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(id);
    try {
      await axios({
        url: "/api/testcategory",
        method: "delete",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          id: `${id}`,
          orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
        },
      });
      toast.success("Deleted successfully");
      setLoader(false);
      getData();
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  return (
    <div>
      <AdminDashboard />
      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Test Categories
              <button className="btn btn-primary" onClick={handleCreateCategory}>
                ADD
              </button>
            </div>
            <br />
            <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                  <th>Category Name</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {categories.length === 0 ? (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  categories.map((test) => (
                    <tr key={test.id}>
                      <td>{test.categoryname}</td>
                      <td>{test.description}</td>
                      <td style={{ fontSize: "17px", cursor: "pointer" }}>
                        <button
                          onClick={() => handleEditCategory(test.id)}
                          style={{ border: "none", background: "white", size: "150px" }}
                        >
                          <BiEditAlt />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div>
              {isModalOpen && (
                <CreateCategories
                  isOpen={isModalOpen}
                  onRequestClose={handleCloseModal}
                  categoryId={editCategoryId} // Pass the category ID to the modal
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {loader && <div className="loading"></div>}
    </div>
  );
}

export default TestCategories;
