import React, { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "../api/baseurl";
import { toast} from "react-toastify";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";

const CreateLabDetails = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate();

  const url = "/api/CreateCompanyandLab";
  const [labDetails, setLabDetails] = useState(
    {
     username:"",
     role:"ROLE_LAB",
     organization:{
     username:"",
     labname:"",
     labcode:"",
     contact:"",
     description:"",
     pan:"",
     gstin:"",
     tan:"",
     addressLine1:"",
     addressLine2:"",
     city:"",
     pincode:"",
     state:"",
     createddate:"",
     latitude:"",
     longitude:"",
     coOrdinatorName:"",
     coOrdinatorEmail:"",
     coOrdinatorMobileno:""
     }
      },
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      try {
        const emailCheckResponse = await axios.get(`/api/accounts/getByEmail=${labDetails.username}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (emailCheckResponse.data) {
          toast.error("Email already exist.");
          setLoading(false);
          return;
        }
      } catch (error) {
        handleError(error);
        setLoading(false);
        return;
      }
      axios
        .post(url, {
          username: labDetails.username,
          role: "ROLE_LAB",
          labDetails: {
            username:labDetails.username,
            labname: labDetails.labname,
            labcode:labDetails.labcode,
            contact:labDetails.contact,
            description:labDetails.description,
            pan:labDetails.pan,
            gstin:labDetails.gstin,
            tan:labDetails.tan,
            addressLine1:labDetails.addressLine1,
            addressLine2:labDetails.addressLine2,
            city:labDetails.city,
            pincode:labDetails.pincode,
            state:labDetails.state,
            createddate:labDetails.createddate,
            latitude:labDetails.latitude,
            longitude:labDetails.longitude,
            coOrdinatorEmail:labDetails.coOrdinatorEmail,
            coOrdinatorMobileno:labDetails.coOrdinatorMobileno,
            coOrdinatorName:labDetails.coOrdinatorName
          }
        }, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          setLoading(false);
          onRequestClose();
          setLabDetails("");
          toast("Lab account created successfully and Password sent to their email");
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
          setLabDetails("");
        });
  
  }

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }



  function rhandle(e) {
    const { id, value } = e.target;
    setLabDetails((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
    <Modal.Title>Create Lab Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {loading && <div className="loading"></div>} 
    <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="labname">Lab Name <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="labname"
                      type="text"
                      placeholder="Lab Name"
                      value={labDetails.labname}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                   </div>
                  </div>
               
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="labcode">Lab Code <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="labcode"
                      type="text"
                      placeholder="Lab Code"
                      value={labDetails.labcode}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
               </div>
               </div>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="username">Email <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="username"
                      type="email"
                      placeholder="Email"
                      value={labDetails.username}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="contact">Contact Number <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="contact"
                      type="text"
                      placeholder="contact number"
                      value={labDetails.contact}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="coordinator">Co-ordinator Name <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="coOrdinatorName"
                      type="text"
                      placeholder="Co-ordinator Name"
                      value={labDetails.coOrdinatorName}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="coordinatoremail">Co-ordinator Email <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="coOrdinatorEmail"
                      type="text"
                      placeholder="Co-ordinator Email"
                      value={labDetails.coOrdinatorEmail}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="coordinator">Co-ordinator Mobile No <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="coOrdinatorMobileno"
                      type="text"
                      placeholder="Co-ordinator Mobile Number"
                      value={labDetails.coOrdinatorMobileno}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>


                <div className="row">
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="gstin">GSTIN <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="gstin"
                      type="text"
                      placeholder="GSTIN"
                      value={labDetails.gstin}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="pan">PAN </label>
                    <input
                      className="form-control"
                      id="pan"
                      type="text"
                      placeholder="PAN"
                      value={labDetails.pan}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="tan">TAN</label>
                    <input
                      className="form-control"
                      id="tan"
                      type="text"
                      placeholder="TAN"
                      value={labDetails.tan}
                      onChange={(e) => rhandle(e)}
                    />
                    
                  </div>
                </div>
                </div>
                <br/>
                <div><h5>Lab Address:</h5></div>
             
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="addressline1">Address Line1 <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="addressLine1"
                      type="text"
                      placeholder="Address"
                      value={labDetails.addressLine1}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="addressline2">Address Line2</label>
                    <input
                      className="form-control"
                      id="addressLine2"
                      type="text"
                      placeholder="Address"
                      value={labDetails.addressLine2}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="latitude">Latitude<span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="latitude"
                      type="text"
                      placeholder="Latitude"
                      value={labDetails.latitude}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="longitude">Longitude <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="longitude"
                      type="text"
                      placeholder="Longitude"
                      value={labDetails.longitude}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="city">City <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="city"
                      type="text"
                      placeholder="City"
                      value={labDetails.city}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="pincode">Pincode <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="pincode"
                      type="text"
                      placeholder="Pincode"
                      value={labDetails.pincode}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="state">State <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="state"
                      type="text"
                      placeholder="State"
                      value={labDetails.state}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>
                
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
            </div>
          </div>
        </div>
              </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
}
export default CreateLabDetails;
