import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { Modal} from "react-bootstrap";

import empCsvFormat from '../images/employeeCsvFormat.png';

const EmployeesCsvFormat = ({ isOpen, onRequestClose }) => {
  

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>CSV Format</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
      <img src={empCsvFormat} alt="CSV Format" style={{ width: '100%' }} />
      </div>
      <br/>
      <h6>
        Follow this Format for Employees CSV upload.
      </h6>

      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default EmployeesCsvFormat;
