import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const MapLabCorporateAndLabs = ({ isOpen, onRequestClose, labCorporateDetails }) => {
  const [labs, setLabs] = useState([]);
  const [selectedLabs, setSelectedLabs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mappedLabs, setMappedLabs] = useState([]);
  const [filteredLabs, setFilteredLabs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch available labs and already mapped labs
  useEffect(() => {
    const fetchLabsAndMappedLabs = async () => {
      setLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;

        // Fetch available labs
        const labsResponse = await axios.get(`/api/labdetails`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Fetch mapped labs for the current lab corporate
        const mappedLabsResponse = await axios.get(
          `/api/labcorporate-labs-mapping`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Set available labs and mapped labs in state
        setLabs(labsResponse.data);
        setFilteredLabs(labsResponse.data);
        setMappedLabs(mappedLabsResponse.data.map((mappedLab) => mappedLab.labId)); // Only store lab IDs

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    };

    if (labCorporateDetails.id) {
      fetchLabsAndMappedLabs();
    }
  }, [labCorporateDetails.id]);

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  // Handle checkbox selection for labs
  const handleLabsSelection = (labId) => {
    setSelectedLabs((prevSelected) =>
      prevSelected.includes(labId)
        ? prevSelected.filter((id) => id !== labId)
        : [...prevSelected, labId]
    );
  };

  // Handle saving mapping of labs to the lab corporate
  const handleSaveMapping = async (e) => {
    e.preventDefault();
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoading(true);
      await axios.post(
        `/api/labcorporate-labs-mapping/create`,
        selectedLabs.map((labId) => ({
          labCorporateId: labCorporateDetails.id,
          labId: labId,
        })),
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success("Labs mapped successfully");
      setLoading(false);
      onRequestClose();
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = labs
      .filter((lab) => !mappedLabs.includes(lab.id)) // Filter out labs that are already mapped
      .filter(
        (lab) =>
          lab.labname.toLowerCase().includes(term) ||
          lab.labcode.toLowerCase().includes(term)
      );
    setFilteredLabs(filtered);
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Map Labs to Lab Corporate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <Form onSubmit={handleSaveMapping}>
          <Form.Group className="mb-3">
            <Form.Label>Organization Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Organization Code"
              value={labCorporateDetails.orgcode}
              readOnly
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Available Labs
            <div className="form-group position-relative" style={{ width: '300px' }}> {/* Adjust width as needed */}
              <input
                type="search"
                className="form-control pl-4"
                placeholder="Search by Lab name or Lab code"
                value={searchTerm}
                onChange={handleSearch}
                style={{ paddingLeft: "2rem", width: "100%" }} // Ensure the input takes the full width of the parent div
              />
              <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </div>
            </Form.Label>
            <div style={{ maxHeight: "300px", overflowY: "auto", padding: "10px", border: "1px solid #ccc", borderRadius: "5px" }}>
              <Row>
                {filteredLabs
                 .filter((lab) => !mappedLabs.includes(lab.id))
                .map((lab, index) => (
                  <Col key={lab.id} md={4} className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label={`${lab.labname} - ${lab.labcode}`}
                      checked={selectedLabs.includes(lab.id)}
                      onChange={() => handleLabsSelection(lab.id)}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Form.Group>

          <Button variant="primary" type="submit" disabled={loading}>
            Save
          </Button>{" "}
          <Button variant="secondary" onClick={onRequestClose}>
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MapLabCorporateAndLabs;
