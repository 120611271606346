import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";
import MapDesignationsAndPackage from "./packagesAndDesignationsMapping";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";
import "./Loader.css";

function MappedPackagesAndDesignations() {
  const [designations, setDesignations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();

  const getPackageById = async (id, token) => {
    const response = await axios.get(`/api/package/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.packagename;
  };

  const getDesignationById = async (id, token) => {
    const response = await axios.get(`/api/designation/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.designation;
  };

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const trans = await axios.get(`/api/packagedesignationmapping`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const updatedData = await Promise.all(
        trans.data.map(async (item) => {
          const packageName = await getPackageById(item.packageid, token);
          const designationName = await getDesignationById(item.designationid, token);
          return { ...item, packageName, designationName };
        })
      );

      setDesignations(updatedData);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleCreateCompany = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDeleteTestPackage = async (id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(id);
    try{
      await axios({
      url: "/api/packagedesignationmapping",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("Deleted successfully");
    setLoader(false); 
    getData();
  } catch (error) {
    setLoader(false); 
  }
};

  return (
    <div>
      <div>
        <Dashboard />
      </div>
      {loader && <div className="loading"></div>}
      <div>
        <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title form-group d-flex align-items-center justify-content-between">
                Packages And Designations
                <button className="btn btn-primary" onClick={handleCreateCompany}>
                  ADD
                </button>
              </div>
              <br />
              <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                    <th>Package Name</th>
                    <th>Gender</th>
                    <th>Designation</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {designations.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    designations.map(accd => (
                      <tr key={accd.id}>
                        <td> {accd.packageName}</td>
                        <td> {accd.gender}</td>
                        <td> {accd.designationName}</td>
                        <td style={{fontSize:"17px", cursor:"pointer"}} title="Delete" 
                        // onClick={() =>
                        //     handleDeleteTestPackage(accd.id)
                        //   } 
                          >
                          <MdDeleteForever/>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div>
                {isModalOpen && (
                  <MapDesignationsAndPackage isOpen={isModalOpen} onRequestClose={handleCloseModal} />
                )}
              </div>
            </div>
          </div>
        </div>
        {loader && <div className="loading"></div>}
      </div>
    </div>
  );
}

export default MappedPackagesAndDesignations;
