import React, {useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import Agentinsurance from "./Agentinsurance";
import EmployeeProfile from "./employeeProfile";
import EmpAppointments from "./empAppointments";
import ViewAppointmentsByEmployer from "./viewAppointmentsByEmployer";
import EmployeeFamilyDetails from "./employeeFamilyDetails";
 
  function EmployeeProfileTab() {

  const[loading ,setLoading]=useState(false);
  const [activeTab, setActiveTab] = useState('Agentprofile');

  return (
    <div>
      <AdminDashboard />
    
      <div className="d-flex align-items-center ">
        <div className="col-10  align-items-center mx-xl-auto  ps-xl-10">
      <div
        className="container-xl p-10 px-11"
        style={{ position: "relative", left: "100px" }}
      >
        <mwc-tab-bar style={{ marginBottom: "-1px", marginLeft:'-220px'}}  activeIndex="0"
       >
            <mwc-tab
              label="Profile"
              icon="person"
              stacked
              active={activeTab === "Agentprofile"}
              onClick={() => setActiveTab("Agentprofile")}

            ></mwc-tab>

         <mwc-tab
              label="Appointments"
              icon="calendar_today"
              stacked
              active={activeTab === "Appointment"}
              onClick={() => setActiveTab("Appointment")}
            ></mwc-tab>

            <mwc-tab
              label="Family Details"
              icon="people"
              stacked
              active={activeTab === "familyDetails"}
              onClick={() => setActiveTab("familyDetails")}
            ></mwc-tab>
         

          <mwc-tab
              label="Insurance"
              icon="real_estate_agent"
              stacked
              active={activeTab === "insurance"}
              onClick={() => setActiveTab("insurance")}
            ></mwc-tab>

{/*          
            <mwc-tab
              label="Salary"
              icon="attach_money"
              stacked
              active={activeTab === "salary"}
              onClick={() => setActiveTab("salary")}
            ></mwc-tab>
          */}
        </mwc-tab-bar>
        </div>
        </div>
        </div>
       
{/* Tab content */}
     
          {/* Render content based on activeTab */}
            {activeTab === "Agentprofile" && (
            <EmployeeProfile/>
          )}

          
          {activeTab === "Appointment" && (
            JSON.parse(localStorage.getItem("data")).role === 'ROLE_EMPLOYER' ? (
              <ViewAppointmentsByEmployer />
            ) : (
              <EmpAppointments />
            )
          )}

      {activeTab === "familyDetails" && (
            <EmployeeFamilyDetails/>
          )}
          {activeTab === "insurance" && (
            <Agentinsurance  />
          )}
          {/* {activeTab === "salary" && <Salary  />} */}
        </div>
        
  );
}
 
export default EmployeeProfileTab;