import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./Spinner.css";

const AddExternalAccounts = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    appname: "",
    validity: "",
    username: "",
  });
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedData = JSON.parse(localStorage.getItem("data"));

      if (!storedData || !storedData.token) {
        console.error("Token not found in localStorage");
        return;
      }

      const token = storedData.token;

      const response = await axios.post(`/api/externalaccount/create`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(false);
      toast.success("External Account Created successfully!");
      onRequestClose();
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const handleSearch = async (searchTerm) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`api/accounts/key=${searchTerm}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSearchResults(
        response.data.filter((user) => user.username.startsWith(searchTerm))
      );
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  function rhandle(e) {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = format(date, "dd/MMM/yyyy HH:mm:ss");
    setFormData((prevState) => ({
      ...prevState,
      validity: formattedDate,
    }));
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add External Accounts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="username">
                      Email<span style={{ color: "red" }}>* </span>
                    </label>
                    <input
                      className="form-control"
                      id="username"
                      type="text"
                      placeholder="Email"
                      value={formData.username}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="appname">
                      App Name <span style={{ color: "red" }}>* </span>
                    </label>
                    <input
                      className="form-control"
                      id="appname"
                      placeholder="App Name"
                      value={formData.appname}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="validity">
                      Validity <span style={{ color: "red" }}>* </span>
                    </label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      timeFormat="HH:mm:ss"
                      timeIntervals={15}
                      dateFormat="dd/MMM/yyyy HH:mm:ss"
                      className="form-control"
                      placeholderText="Select Date"
                      required
                    />
                  </div>
                </div>

                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default AddExternalAccounts;
