import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { BiEditAlt } from "react-icons/bi";
import CreateLocations from "./addLocations";
import UploadLocationsCsvFile from "./uploadLocationsCsvFile";
import LocationsCsvFormat from "./locationsCsvFormat";

function GeoLocations({selectedOrgcode}) {
  const [geoLocations, setGeoLocations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const [loader, setLoader] = useState(true);

  const getData = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/geolocation/companycode=${selectedOrgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setGeoLocations(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  const handleCreateLocation = () => {
    setLocationId(null); // Clear editCategoryId for creating a new category
    setIsModalOpen(true);
  };

  const handleEditLocation = (id) => {
    setLocationId(id); // Set the category ID to be edited
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const [isCsvUplaodModalOpen, setIsCsvUplaodModalOpen] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  const uploadCsv = () => {
    setIsCsvUplaodModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsCsvUplaodModalOpen(false);
    getData();
  };

  return (
    <div>
      <div className="company-profile">
        <div className="card card-raised mb-5">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
            <span>Locations</span>
            <div>
                <button className="btn btn-primary me-5" onClick={handleShowImageModal}>
                  View Format
                </button>
                <button className="btn btn-primary me-5" onClick={uploadCsv}>
                  UPLOAD CSV
                </button>
                <button className="btn btn-primary" onClick={handleCreateLocation}>
                  ADD
                </button>
              </div>
            </div>
            <br />
            <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                  <th>Location</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {geoLocations.length === 0 ? (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  geoLocations.map((loc) => (
                    <tr key={loc.id}>
                      <td>{loc.location}</td>
                      <td style={{ fontSize: "17px", cursor: "pointer" }}>
                        <button
                          onClick={() => handleEditLocation(loc.id)}
                          style={{ border: "none", background: "white", size: "150px" }}
                        >
                          <BiEditAlt />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div>
            {isCsvUplaodModalOpen && (
              <UploadLocationsCsvFile isOpen={isCsvUplaodModalOpen} onRequestClose={closeUploadModal} companycode={selectedOrgcode} />
            )}

            {showImageModal && (
              <LocationsCsvFormat isOpen={showImageModal} onRequestClose={handleCloseImageModal} />
            )}

              {isModalOpen && (
                <CreateLocations
                  isOpen={isModalOpen}
                  onRequestClose={handleCloseModal}
                  locationId={locationId} // Pass the category ID to the modal
                  companycode={selectedOrgcode}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {loader && <div className="loading"></div>}
    </div>
  );
}

export default GeoLocations;
