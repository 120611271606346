import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import "./Spinner.css";
import "./Loader.css";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import AdminDashboard from "./admindashboard";
import { MdDeleteForever } from "react-icons/md";
import MapLabCorporateAndLabs from "./labCorporateAndLabsMapping";

function ViewCorporateLabsByAdmin() {
    const {corporateId} = useParams();
  const [labDetails, setLabDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCsvUplaodModalOpen, setIsCsvUplaodModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLabs, setFilteredLabs] = useState([]);
  const [isMapLabCorporateAndLabs, setIsMapLabCorporateAndLabs] = useState(false);
  const [selectedLabCorporate, setSelectedLabCorporate] = useState(null);

  const navigate = useNavigate();
  const userid = JSON.parse(localStorage.getItem("data")).userid;
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      const trans = await axios.get(`/api/labcorporate-labs-mapping/labcorporateid=${corporateId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(trans);
      setLabDetails(trans.data);
      setFilteredLabs(trans.data);
      const corporate = await axios.get(`/api/lab-corporate/${corporateId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSelectedLabCorporate(corporate.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleViewLabDetails = (orgcode) => {
    navigate(`/users/corporate-lab-profile/${orgcode}`);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = labDetails.filter(
      (lab) =>
        lab.labDetails?.labname.toLowerCase().includes(term) || lab.labDetails?.labcode.toLowerCase().includes(term)
    );
    setFilteredLabs(filtered);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleDeleteLab = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      // Using axios.request to send DELETE with data in body
      await axios.request({
        url: `/api/labcorporate-labs-mapping`,
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
        data: { id: id }, // Passing id in the body
      });
  
      toast.success("Lab removed successfully");
     getData();
    } catch (error) {
      handleError(error);
    }
  };
  
  const handleOpenMapPopup = () => {
    setIsMapLabCorporateAndLabs(true);
  };

  const handleCloseMapPopup = () => {
    setSelectedLabCorporate(null);
    setIsMapLabCorporateAndLabs(false);
    getData();
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div>
        <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
 <div className="mb-2">
      <button className="btn btn-primary" onClick={handleBack}>
      Back
    </button>
    </div>
          <div className="card card-raised ">
            <div className="card-body ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
                <span>Lab Details</span>
                <div className="form-group position-relative" style={{ width: '300px' }}> {/* Adjust width as needed */}
  <input
    type="search"
    className="form-control pl-4"
    placeholder="Search by Lab name or Lab code"
    value={searchTerm}
    onChange={handleSearch}
    style={{ paddingLeft: "2rem", width: "100%" }} // Ensure the input takes the full width of the parent div
  />
  <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
    <FontAwesomeIcon icon={faSearch} />
  </span>
</div>
<div>
  <button className="btn btn-primary" onClick={handleOpenMapPopup}>
    LINK LABS
  </button>
</div>

               
              </div>
             
              <br />
              <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                    <th>Lab Name</th>
                    <th>Lab Code</th>
                    <th>Email</th>
                    <th>Contact Number</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                {filteredLabs.length === 0 ? (
                  
            <tr>
               {/* setLoader(false); */}
              <td colSpan="7" className="text-center">
                No Data Available
              </td>
            </tr>
          
          ) : (

                  filteredLabs.map((accd) => {
                    return (
                      <tr key={accd.name}>
 <td 
  style={{ 
    cursor: 'pointer'
  }}
  onClick={() => handleViewLabDetails(accd.labDetails.labcode)}
  onMouseOver={(e) => { e.target.style.color = 'blue'; e.target.style.textDecoration = 'underline'; }}
  onMouseOut={(e) => { e.target.style.color = ''; e.target.style.textDecoration = ''; }}
>
  {accd.labDetails.labname}
</td>
                        <td>{accd.labDetails.labcode}</td>
                        <td>{accd.labDetails.username}</td>
                        <td>{accd.labDetails.contact}</td>

                        <td  style={{ cursor: 'pointer' }}>
                        <MdDeleteForever
            style={{ cursor: "pointer", fontSize:"20px"}} title="Remove Lab"
            onClick={() => handleDeleteLab(accd.id)}
          />
                        </td>

                       
                        
                      </tr>
                    );
                  }))}
                </tbody>
              </table>
            </div>
            {isMapLabCorporateAndLabs && (
                  <MapLabCorporateAndLabs
                    isOpen={isMapLabCorporateAndLabs}
                    onRequestClose={handleCloseMapPopup}
                    labCorporateDetails={selectedLabCorporate}
                  />
                )}
          </div>
        </div>
        {loader && <div className="loading"></div>} 
      </div>
    </div>
  );
}

export default ViewCorporateLabsByAdmin;
