import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import "./Spinner.css";
import "./Loader.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import CreatePackages from "./addpackages";
import LinkPackagesAndTests from "./linkPackagesAndTests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FiInfo } from "react-icons/fi";
import TestForPackage from "./testsByPackagePopup";

function ViewPackages() {
    const { orgcode } = useParams();
  const [packages, setPackages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isMapPackageAndTest, setIsMapPackageAndTest] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [clientPackageCodes, setClientPackageCodes] = useState({}); // State to store input values
  const [corporateDetails, setCorporateDetails] = useState(null);
  const navigate = useNavigate();

  const getData = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const trans = await axios.get(`/api/package`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const packageData = trans.data;
      setPackages(packageData);

      const response = await axios.get(`/api/lab-corporate/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const corporateData = response.data;
     setCorporateDetails(response.data);
     
     // Fetch client package codes for each package
     const clientCodes = {};
     for (const pkg of packageData) {
       const clientCodeResponse = await axios.get(`/api/package-clientpackagecode/packageid=${pkg.id}/corporateid=${corporateData.id}`, {
         headers: { Authorization: `Bearer ${token}` },
       });
       clientCodes[pkg.id] = clientCodeResponse.data.clientPackageCode || "";
     }

     setClientPackageCodes(clientCodes); 
     setLoader(false);
     
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };


  const getCompanies = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/companydetails`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCompanies(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getCompanies();
    getData();
  }, []);

  const handleDeletePackage = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(id);
    try {
      await axios({
        url: "/api/package",
        method: "delete",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          id: `${id}`,
          orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
        },
      });
      toast.success("Deleted successfully");
      setLoader(false);
      getData();
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const filteredPackages = packages.filter((pkg) => {
    const matchesCompany =
      selectedCompany === "" || (pkg.company && pkg.company.companyname === selectedCompany);
    const matchesSearchQuery = pkg.packagename.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCompany && matchesSearchQuery;
  });

  const handleBack = () => {
    navigate(-1);
  };

  const [isTestsModalOpen, setIsTestsModalOpen] = useState(false);
  const [tests, setTests] = useState([]);
  const openTestsModal = async (pkg) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/packagetestmapping/packageid=${pkg}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTests(response.data);

      setIsTestsModalOpen(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
     handleError(error);
    }
  };

  const closeTestModal = () => {
    setIsTestsModalOpen(false);  
  };

  const handleClientPackageCodeChange = (packageId, value) => {
    setClientPackageCodes((prevCodes) => ({
      ...prevCodes,
      [packageId]: value,
    }));
  };
  const handleUpdateClientPackageCode = async (packageId, value) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    console.log("code",value);
    try {
      await axios.post(
        `/api/package-clientpackagecode/create`,
        {
          packageid: packageId,
          clientPackageCode: value,
          corporateId:corporateDetails.id
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Client Package Code updated successfully");
    } catch (error) {
      handleError(error);
    }
  };


  return (
    <div>
      {loader && <div className="loading"></div>}
      <div>
        <div className="col-12 mx-xl-auto">
        <div className="mb-2">
      <button className="btn btn-primary" onClick={handleBack}>
      Back
    </button>
    </div>
          <div className="card card-raised">
            <div className="card-body">
              <div className="card-title form-group d-flex align-items-center justify-content-between">
                Packages
              </div>

              <br />
              <div className="form-group d-flex align-items-center ">
              <div className="row col-md-6">
                <div className="col-6">
                  <label htmlFor="companyFilter">FILTER BY COMPANY:</label>
                  <select
                    id="companyFilter"
                    className="form-select me-3"
                    value={selectedCompany}
                    onChange={(e) => setSelectedCompany(e.target.value)}
                  >
                    <option value="">All Companies</option>
                    {companies.map((comp) => (
                      <option key={comp.id} value={comp.companyname}>
                        {comp.companyname}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6">
                <div className="form-group position-relative">
                  <label htmlFor="searchQuery"></label>
                  <input
                    id="searchQuery"
                    type="text"
                    className="form-control"
                    placeholder="Search by Package Name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                   <span className="position-absolute" style={{ right: "10px", top: "70%", transform: "translateY(-50%)" }}>
    <FontAwesomeIcon icon={faSearch} />
  </span>
  </div>
                </div>
              </div>
            </div>

              <br />
              <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                    <th>Package Name</th>
                    <th>Company Name</th>
                    <th>Package Code</th>
                    <th>Client Package Code</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPackages.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    filteredPackages.map((pkg) => (
                      <tr key={pkg.id}>
                       <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{pkg.packagename}</span>
              <FiInfo
                 onClick={() => openTestsModal(pkg.id)}
                style={{
                  cursor: 'pointer'
                }}
              />
                      </td>
                        <td>{pkg.company?.companyname}</td>
                        <td>{pkg.packageCode}</td>
<td>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      type="text"
      value={clientPackageCodes[pkg.id] !== undefined ? clientPackageCodes[pkg.id] : (pkg.clientPackageCode || "")}
      onChange={(e) =>
        handleClientPackageCodeChange(pkg.id, e.target.value)
      }
      className="form-control"
      placeholder="Enter Client Package Code"
      style={{ marginRight: '10px' }} // Add space between input and button
    />
    <button 
      className="btn btn-primary" 
      onClick={() => handleUpdateClientPackageCode(pkg.id, clientPackageCodes[pkg.id] || "")}>
      Update
    </button>
  </div>
</td>


                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <div>
              </div>
            </div>
          </div>
        </div>
        {loader && <div className="loading"></div>}
      </div>
      {isTestsModalOpen && (
<TestForPackage
        isOpen={isTestsModalOpen}
        onRequestClose={closeTestModal}
        testDetails={tests}
      />
)}
    </div>
  );
}

export default ViewPackages;
