import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const LinkPackagesAndTests = ({ isOpen, onRequestClose, packageId }) => {
  const [packageDetails, setPackageDetails] = useState("");
  const [tests, setTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch package details by packageId
  useEffect(() => {
    const fetchPackageDetails = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/package/${packageId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPackageDetails(response.data);
      } catch (error) {
        handleError(error);
      }
    };
    if (isOpen && packageId) {
      fetchPackageDetails();
    }
  }, [isOpen, packageId]);

  // Fetch available tests
  useEffect(() => {
    setLoading(true);
    const fetchTests = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/labtest`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTests(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    };
    fetchTests();
  }, []);

  const navigate=useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  // Handle checkbox selection for tests
  const handleTestSelection = (testId) => {
    setSelectedTests((prevSelected) =>
      prevSelected.includes(testId)
        ? prevSelected.filter((id) => id !== testId)
        : [...prevSelected, testId]
    );
  };

  // Handle saving mapping of tests to package
  const handleSaveMapping = async (e) => {
    e.preventDefault();
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoading(true);
      await axios.post(
        `/api/packagetestmapping/create`,
        selectedTests.map((testId) => ({
          packageid: packageId,
          testid: testId,
        })),
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success("Tests mapped successfully");
      setLoading(false);
      onRequestClose();
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Map Tests to Package</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <Form onSubmit={handleSaveMapping}>
          <Form.Group className="mb-3">
            <Form.Label>Package Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Package Name"
              value={packageDetails.packagename}
              readOnly
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Available Tests</Form.Label>
            <Row>
              {tests.map((test, index) => (
                <Col key={test.id} md={4} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label={`${test.testCat?.categoryname} : ${test.testname}`}
                    checked={selectedTests.includes(test.id)}
                    onChange={() => handleTestSelection(test.id)}
                  />
                </Col>
              ))}
            </Row>
          </Form.Group>
          <Button variant="primary" type="submit" disabled={loading}>
            Save
          </Button>{" "}
          <Button variant="secondary" onClick={onRequestClose}>
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LinkPackagesAndTests;
