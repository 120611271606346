import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaCircle } from "react-icons/fa"; // Importing FontAwesome circle icon
import AdminDashboard from "../components/admindashboard";

function Settings() {
  const navigate = useNavigate();

  return (
    <div>
      <AdminDashboard />

      <div className="col-9 mx-xl-auto ps-xl-10 mt-10">
        {/* Operational Report Card */}
        <div className="card card-raised mb-5">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Settings
            </div>
            <br />
            <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/financial-year-settings" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Financial Year Settings
                </Link>
              </div>
              </div>
              <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/documentation" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Documentation
                </Link>
              </div>
            </div>

          </div>
        </div>

    
      </div>
    </div>
  );
}

export default Settings;
