import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { FiInfo } from "react-icons/fi";

const DisclaimerPopup = ({ isOpen, onRequestClose, userid}) => {
  const [employeeDetails, setEmpDetails] = useState(null);
  const [compDetails, setCompanyDetails] = useState(null);
    const [designations, setDesignations] = useState(null);
    const [financialYearSettings, setFinancialYearSettings] = useState(null);
    const [annualCheckup, setAnnualCheckup] = useState(null);
    const [locationMapping, setLocationMapping] = useState(null);
    const [packageMapping, setPackageMapping] = useState(null);
    const [employeeEnabled, setEmployeeEnabled] = useState(null);
    const [loader, setLoader] = useState(false);
    const [designationErrorModal, setDesignationErrorModal] = useState(false);
    const [yearSettingsErrorModal, setYearSettingErrorModal] = useState(false);
    const [annualCheckupErrorModal, setAnnualCheckuperrorModal] = useState(false);
    const [packageMappingErrorModal, setPackageMappingErrorModal] = useState(false);
    const [locationMappingErrorModal, setLocationMappingErrorModal] = useState(false);
    const [employeeEnabledErrorModal, setEmployeeEnabledErrorModal] = useState(false);
  
    const navigate = useNavigate();
  
    const handleBookAppointment = () => {
      navigate("/users/book-appointment");
    };
  
    const getData = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoader(true);
      try {
        const empRes = await axios.get(`/api/employeedetails/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEmpDetails(empRes.data);
        const compRes = await axios.get(`/api/companydetails/companycode=${empRes.data.companycode}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCompanyDetails(compRes.data);
        const designationRes = await axios.get(`/api/employeedetails/checkDesignationMapped/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setDesignations(designationRes.data);
  
        const locationRes = await axios.get(`/api/employeedetails/checkLocationMapped/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setLocationMapping(locationRes.data);
  
        const yearSettings = await axios.get(`/api/year-settings/checkFinancialYears`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setFinancialYearSettings(yearSettings.data);
  
        const enableRes = await axios.get(`/api/employeedetails/checkEmplyeeEnabled/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEmployeeEnabled(enableRes.data);
  
        const packageRes = await axios.get(`/api/employeedetails/checkPackageMapped/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPackageMapping(packageRes.data);
  
        const annualCheckup = await axios.get(`/api/employeedetails/checkAnnualCheckup/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAnnualCheckup(annualCheckup.data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        handleError(error);
      }
    };
  
    function handleError(error) {
      if (error.response) {
        if (error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.response.data && error.response.data.code) {
          toast.error(error.response.data.code);
        } else {
          toast.error("An error occurred.");
        }
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred.");
      }
    }
  
    useEffect(() => {
      getData();
    }, []);
  
    const allDetailsTrue =
      designations?.allAccounted === true &&
      financialYearSettings?.allAccounted === true &&
      annualCheckup?.allAccounted === true &&
      packageMapping?.allAccounted === true &&
      employeeEnabled?.allAccounted === true &&
      locationMapping?.allAccounted === true;
  
    const closeDesignationErrorModal = () => {
      setDesignationErrorModal(false);
    };
  
    const closeYearSettingErrorModal = () => {
      setYearSettingErrorModal(false);
    };
  
    const closeAnnualCheckupErrorModal = () => {
      setAnnualCheckuperrorModal(false);
    };
  
    const closePackageErrorModal = () => {
      setPackageMappingErrorModal(false);
    };
  
    const closeLocationErrorModal = () => {
      setLocationMappingErrorModal(false);
    };
  
    const closeEmployeeEnabledErrorModal = () => {
      setEmployeeEnabledErrorModal(false);
    };
  
    return (
      <>
        <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {loader && <div className="loading"></div>}
  
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* Left Side: Conditions */}
              <div style={{ width: "45%" }}>
                <p>
                  Designation: {designations?.allAccounted === true ? "✓" : "❌"}
                  {designations?.allAccounted === false && (
                    <span style={{ marginLeft: "40px" }}>
                      <FiInfo onClick={() => setDesignationErrorModal(true)} />
                    </span>
                  )}
                </p>

                <p>
                  Location: {locationMapping?.allAccounted === true ? "✓" : "❌"}
                  {locationMapping?.allAccounted === false && (
                    <span style={{ marginLeft: "40px" }}>
                      <FiInfo onClick={() => setLocationMappingErrorModal(true)} />
                    </span>
                  )}
                </p>

                <p>
                  Enabled Personnel:{" "}
                  {employeeEnabled?.allAccounted === true ? "✓" : "❌"}
                  {employeeEnabled?.allAccounted === false && (
                    <span style={{ marginLeft: "40px" }}>
                      <FiInfo onClick={() => setEmployeeEnabledErrorModal(true)} />
                    </span>
                  )}
                </p>
  
                <p>
                  Financial Year Settings:{" "}
                  {financialYearSettings?.allAccounted === true ? "✓" : "❌"}
                  {financialYearSettings?.allAccounted === false && (
                    <span style={{ marginLeft: "40px" }}>
                      <FiInfo onClick={() => setYearSettingErrorModal(true)} />
                    </span>
                  )}
                </p>
  
                <p>
                  Package Mapping: {packageMapping?.allAccounted === true ? "✓" : "❌"}
                  {packageMapping?.allAccounted === false && (
                    <span style={{ marginLeft: "40px" }}>
                      <FiInfo onClick={() => setPackageMappingErrorModal(true)} />
                    </span>
                  )}
                </p>

                <p>
                  Annual Health Checkup:{" "}
                  {annualCheckup?.allAccounted === true ? "✓" : "❌"}
                  {annualCheckup?.allAccounted === false && (
                    <span style={{ marginLeft: "40px" }}>
                      <FiInfo onClick={() => setAnnualCheckuperrorModal(true)} />
                    </span>
                  )}
                </p>
  
                {!allDetailsTrue && (
                  <p style={{ color: "red" }}>Please contact your administration for the above issues.</p>
                )}
              </div>
  
              {/* Right Side: Disclaimer */}
              <div style={{ width: "45%", textAlign: "justify" }}>
                <Modal.Title>Disclaimer</Modal.Title>
                <br />
                <p>
                  Executive annual medicals for <strong>{compDetails?.companyname}</strong> are facilitated by TMPL,
                  providing multiple medical facilities across India for the
                  convenience of executives. The choice of the available center lies with the executive. All processes
                  followed by the selected center must be adhered to by the executive meticulously, for best results.
                </p>
  
                <p>
                  TMPL has no role in the execution of specified tests or reporting by the center based on the results;
                  this interaction is solely between the executive and the center. Positive identification is mandatory
                  between the center and the executive. Any additional tests require approval by the respective HR. TMPL
                  is only responsible for scheduling appointments through its portal and access of reports by the center
                  to authorized personnel.
                </p>
              </div>
            </div>
          </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="me-3" onClick={onRequestClose}>
          Decline
        </Button>
        {allDetailsTrue && (
        <Button variant="primary" onClick={handleBookAppointment}>
          Accept
        </Button>
        )}
      </Modal.Footer>
    </Modal>
    
     <Modal show={designationErrorModal} onHide={closeDesignationErrorModal} centered>
     <Modal.Header closeButton>
       <Modal.Title>Designation Error</Modal.Title>
     </Modal.Header>
     <Modal.Body>
       <p style={{color:"red"}}>{designations?.errorMessage}</p>
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={closeDesignationErrorModal}>
         Close
       </Button>
     </Modal.Footer>
   </Modal>

   <Modal show={yearSettingsErrorModal} onHide={closeYearSettingErrorModal} centered>
     <Modal.Header closeButton>
       <Modal.Title>Financial year settings Error</Modal.Title>
     </Modal.Header>
     <Modal.Body>
     <p style={{color:"red"}}>{financialYearSettings?.errorMessage}</p>
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={closeYearSettingErrorModal}>
         Close
       </Button>
     </Modal.Footer>
   </Modal>

   <Modal show={annualCheckupErrorModal} onHide={closeAnnualCheckupErrorModal} centered >
     <Modal.Header closeButton>
       <Modal.Title>Annual Checkup Error</Modal.Title>
     </Modal.Header>
     <Modal.Body>
       <p style={{color:"red"}}>{annualCheckup?.errorMessage}</p>
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={closeAnnualCheckupErrorModal}>
         Close
       </Button>
     </Modal.Footer>
   </Modal>
   <Modal show={locationMappingErrorModal} onHide={closeLocationErrorModal} centered >
     <Modal.Header closeButton>
       <Modal.Title>Location Mapping Error</Modal.Title>
     </Modal.Header>
     <Modal.Body>
       <p style={{color:"red"}}>{locationMapping?.errorMessage}</p>
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={closeLocationErrorModal}>
         Close
       </Button>
     </Modal.Footer>
   </Modal>
   <Modal show={employeeEnabledErrorModal} onHide={closeEmployeeEnabledErrorModal} centered >
     <Modal.Header closeButton>
       <Modal.Title>Enabled Personnel Error</Modal.Title>
     </Modal.Header>
     <Modal.Body>
       <p style={{color:"red"}}>{employeeEnabled?.errorMessage}</p>
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={closeEmployeeEnabledErrorModal}>
         Close
       </Button>
     </Modal.Footer>
   </Modal>
   <Modal show={packageMappingErrorModal} onHide={closePackageErrorModal} centered >
     <Modal.Header closeButton>
       <Modal.Title>Package Mapping Error</Modal.Title>
     </Modal.Header>
     <Modal.Body>
       <p style={{color:"red"}}>{packageMapping?.errorMessage}</p>
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={closePackageErrorModal}>
         Close
       </Button>
     </Modal.Footer>
   </Modal>
   </>
  );
};

export default DisclaimerPopup;
