import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

 
 
function CompanyProfile({selectedOrgcode}) {
  // const { orgcode } = useParams();

    const[loading ,setLoading]=useState(false);
    const [password, setPassword] = useState('');
  
    const [orgProfile, setOrgProfile] = useState({
      username:"",
      companyname:"",
      companycode:"",
      contact:"",
      description:"",
      pan:"",
      gstin:"",
      tan:"",
      addressLine1:"",
      addressLine2:"",
      city:"",
      pincode:"",
      state:"",
      createddate:""
    });
    useEffect(() => {
      const fetchProfile = async () => {
        try {
          setLoading(true);
          const token = JSON.parse(localStorage.getItem("data")).token;
         // const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
          const response = await axios.get(`/api/companydetails/companycode=${selectedOrgcode}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const userProfileDetails = response.data;
          setOrgProfile(userProfileDetails);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          handleError(error);
        }
      };
  
      fetchProfile();
    }, []);
  
    function getBase64FromFile(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
         const [metadata, content] = reader.result.split(",");
         resolve({ metadata, content });};
         reader.onerror = function (error) {
         reject(error);};}); }
  
   const handleImageChange=async(event)=>{
     const convertedImage= await getBase64FromFile(event.target.files[0]);
     const imageObject = {...orgProfile,
           imageMeta: convertedImage.metadata,
           image:convertedImage.content,};
           setOrgProfile(imageObject);
   }
  
  
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
  
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        await axios.post(`/api/companydetails/update`, orgProfile, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Company Profile Updated Successfully");
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    };
  
  
    
  const handleChange = (event, field) => {
      let actualValue = event.target.value;
  
      setOrgProfile({ ...orgProfile, [field]: actualValue });
      
    };

    const handlePasswordSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
  
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
  
        const body = {
          id: orgProfile.userid,
          password: password,
        };
  
        await axios.post(`/api/accounts/update-password`, body, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        toast.success("Password Updated Successfully");
        setPassword("");
      } catch (error) {
       handleError(error);
      } finally {
        setLoading(false);
      }
    };

    const navigate = useNavigate();
    function handleError(error) {
      if (error.response) {
        if (error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        }  else if (error.response.data && error.response.data.code) {
          toast.error(error.response.data.code);
        }
         else {
          toast.error("An error occurred.");
        }
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred.");
      }
    }
  
  return (
    <div>
    
    {loading && <div className="loading"></div>} 
    <div className="company-profile">
      <div className="card card-raised mb-5">
        <div className="card-body">
              <div className="card-title">Company Profile</div>
              <div className="d-flex">
              <div className="me-4">
              {orgProfile.image ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="image"
                    src={`${orgProfile?.imageMeta},${orgProfile?.image}`}
                    alt="User Profile"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="image"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",

                    }}
                  >
                    {/* <FaRegUserCircle size={250} /> */}
                  </div>
                )}
                <input
                  className="col-7"
                  type="file"
                  id="image"
                  // style={{ marginLeft: "10%" }}
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    handleImageChange(event);
                  }}
                  accept="image/*"
                />
              </div>
              <div className="flex-grow-1">
                <form onSubmit={handleSubmit} className="w-100">

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organizationname">Company Name:</label>
                      <input
                        className="form-control"
                        id="companyname"
                        type="text"
                        value={orgProfile.companyname}
                        onChange={(e) => handleChange(e, "companyname")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Company Code:</label>
                      <input
                        className="form-control"
                        id="comapnycode"
                        type="text"
                        value={orgProfile.companycode}
                        onChange={(e) => handleChange(e, "comapnycode")}
                      />
                    </div>
                    </div>
                  </div>

                  <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Email ID:</label>
                      <input
                        className="form-control"
                        id="username"
                        type="email"
                        value={orgProfile.username}
                        readOnly
                        // onChange={(e) => handleChange(e, "username")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Contact Number:</label>
                      <input
                        className="form-control"
                        id="contact"
                        type="text"
                        value={orgProfile.contact}
                        onChange={(e) => handleChange(e, "contact")}
                      />
                    </div>
                  </div>
                  </div>

                  <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">GSTIN:</label>
                      <input
                        className="form-control"
                        id="gstin"
                        type="text"
                        value={orgProfile.gstin}
                        onChange={(e) => handleChange(e, "gstin")}
                      />
                    </div>
                  </div>
                 
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="state">PAN:</label>
                      <input
                        className="form-control"
                        id="pan"
                        type="text"
                        value={orgProfile.pan}
                        onChange={(e) => handleChange(e, "pan")}
                      />
                    </div>
                  </div>

                <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">TAN:</label>
                      <input
                        className="form-control"
                        id="tan"
                        type="text"
                        value={orgProfile.tan}
                        onChange={(e) => handleChange(e, "tan")}
                      />
                    </div>
                  </div>
                 </div>

                 <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="addressLine1">Address Line 1:</label>
                      <input
                        className="form-control"
                        id="addressLine1"
                        type="text"
                        value={orgProfile.addressLine1}
                        onChange={(e) => handleChange(e, "addressLine1")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="addressLine2">Address Line 2:</label>
                      <input
                        className="form-control"
                        id="addressLine2"
                        type="text"
                        value={orgProfile.addressLine2}
                        onChange={(e) => handleChange(e, "addressLine2")}
                      />
                    </div>
                  </div>
                  </div>

                  <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">City:</label>
                      <input
                        className="form-control"
                        id="city"
                        type="text"
                        value={orgProfile.city}
                        onChange={(e) => handleChange(e, "city")}
                      />
                    </div>
                  </div>

               
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="pincode">Pincode:</label>
                      <input
                        className="form-control"
                        id="pincode"
                        type="text"
                        value={orgProfile.pincode}
                        onChange={(e) => handleChange(e, "pincode")}
                      />
                    </div>
                  </div>
                
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="state">State:</label>
                      <input
                        className="form-control"
                        id="state"
                        type="text"
                        value={orgProfile.state}
                        onChange={(e) => handleChange(e, "state")}
                      />
                    </div>
                  </div>
                  </div>

                <br />
                <input
                  type="submit"
                  value="Save Changes"
                  className="btn btn-primary"
                />
              </form>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div>
      <div className="card card-raised mb-5">
        <div className="card-body">
      <div className="card-title mb-3">Update Password</div>
      <form className="d-flex justify-content-center" onSubmit={handlePasswordSubmit}>
            <div className="col-md-6 mt-1">
              <div className="form-floating-sm mb-2">
                <label htmlFor="password">Password :</label>
                <input
                  className="form-control"
                  id="password"
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="row justify-content-center">
          <div className="col-md-6 text-center mt-4">
            <button type="submit" className="btn btn-primary">Update</button>
          </div>
        </div>
          </form>
    </div>
  </div>
  </div>
    </div>
  );
};

export default CompanyProfile;