import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiSolidCheckCircle, BiSolidXCircle } from "react-icons/bi";
import UploadReport from "./uploadReport";
import TestForPackage from "./testsByPackagePopup";
import { FiInfo } from "react-icons/fi";

function EmpAppointments() {
    const { userid } = useParams();
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    
    setLoader(true);
    try {
      const response = await axios.get(`/api/employeeappointment/userid=${userid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAppointmentDetails(response.data);
      setLoader(false);
    } catch (error) {
      handleError(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

//   const handleCancelAppointment = async (appointmentId) => {
//     const token = JSON.parse(localStorage.getItem("data")).token;
//     setLoader(true);
//     try{
//       await axios({
//       url: "/api/employeeappointment/update",
//       method: "post",
 
//       headers: { Authorization: `Bearer ${token}` },
 
//       data: {
//         id: `${appointmentId}`,
//         appointmentstatus:"Cancelled"
//       },
//     });
//     toast.success("Cancelled successfully");
//       getData();
//     } catch (error) {
//       console.error("Error cancelling appointment:", error);
//       setLoader(false);
//     }
//   };

const [pdfUrl, setPdfUrl] = useState(null);
const handleViewPdf = async (appointmentId) => {
  const token = JSON.parse(localStorage.getItem("data")).token;
  await axios({
    url: `/api/employeeappointment/download/id=${appointmentId}`,
    method: "GET",
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => {
    console.log("filedata;", response);
    const contentType = response.headers["content-type"];
    let extension = "";

    // Determine the file extension based on content type
    if (contentType.includes("application/pdf")) {
      extension = "pdf";
    } else if (contentType.includes("image/jpeg")) {
      extension = "jpg";
    } else if (contentType.includes("image/png")) {
      extension = "png";
    } else {
      console.error("Unsupported file type:", contentType);
      return;
    }

    // Create a blob URL and download link for the file
    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `file.${extension}`);
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  });
}

const handleBack = () => {
  navigate(-1);
};

const [loading, setLoading] = useState(null);
function rsubmit(e, id, status) {
    e.preventDefault();
    setLoading(id);
  
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    const entitiesToUpdate = ({
      id: id,
      reportstatus: status,
    });
  
    axios
      .post(
        "/api/employeeappointment/approve",
        entitiesToUpdate,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(null);
        getData();
      })
      .catch((error) => {
       handleError(error);
      });
  }


  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);

  const handleUploadReport = (appointmentId) => {
    setSelectedAppointmentId(appointmentId);
    console.log("s",selectedAppointmentId);
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
    getData();
  };

  const [isTestsModalOpen, setIsTestsModalOpen] = useState(false);
  const [tests, setTests] = useState([]);

  const openTestsModal = async (pkg) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/packagetestmapping/packageid=${pkg}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTests(response.data);

      setIsTestsModalOpen(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
     handleError(error);
    }
  };
  
  const closeDenominationModal = () => {
    setIsTestsModalOpen(false);
   
  };

  return (
    <div>
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="mb-2">
      <button className="btn btn-primary" onClick={handleBack}>
      Back
    </button>
    </div>
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
             
            <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                <th>Employee Name</th>
                  <th>Date</th>
                  <th>Lab Name</th>
                  <th>Contact Number</th>
                  <th>Package Name</th>
                  <th>Appointment Status</th>
                  <th>Report</th>
                  <th>Report Status</th>
            
                </tr>
              </thead>
              <tbody>
                {appointmentDetails.length === 0 ? (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  appointmentDetails.map((appointment) => (
                    <tr key={appointment.id}>
                        <td
                        style={{
                          cursor: "pointer",
                        }}
                        title="Upload Report"
                        onClick={() => handleUploadReport(appointment.id)}
                        onMouseOver={(e) => {
                          e.target.style.color = "blue";
                          e.target.style.textDecoration = "underline";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.color = "";
                          e.target.style.textDecoration = "";
                        }}
                      >
                        {appointment.emplo.name} {appointment.emplo.lastname}
                      </td>
                      <td>{appointment.date}</td>
                      <td>{appointment.labs.labname}</td>
                      <td>{appointment.contact}</td>
                      <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{appointment.pack?.packagename}</span>
              <FiInfo
                 onClick={() => openTestsModal(appointment.packageid)}
                style={{
                  cursor: 'pointer'
                }}
              />
                      </td>
                      <td> {appointment.appStatus?.appointmentStatus === "APPOINTMENT RESCHEDULED" ? (
    `Appointment Rescheduled on ${appointment.appStatus?.dateTime} by ${appointment.appStatus?.updatedBy}`
  ) : appointment.appStatus?.appointmentStatus === "APPOINTMENT CANCELLED" ? (
    `Appointment Cancelled on ${appointment.appStatus?.dateTime} by ${appointment.appStatus?.updatedBy}`
  ) : 
  appointment.appStatus?.appointmentStatus === "APPOINTMENT CONFIRMED" ? (
    `Appointment Confirmed on ${appointment.appStatus?.dateTime} by ${appointment.appStatus?.updatedBy}`
  ) : (
    appointment.appStatus?.appointmentStatus
  )}
  </td>
                      <td>
                      {appointment.file ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => handleViewPdf(appointment.id)}
                          >
                            View
                          </button>
                        ) : (
                          "No Report Added"
                        )}
                        </td>
                                  <td>
                        {appointment.file ? (
                          <>
                            {appointment.reportstatus === "REQUESTED" ? (
                              <td id="icon" value={appointment.reportstatus}>
                                &nbsp;
                                {appointment.reportstatus} &nbsp;
                                {loading && (
                                  <div
                                    className="loader"
                                    style={{
                                      position: "relative",
                                      left: "75px",
                                      top: "10px",
                                    }}
                                  ></div>
                                )}{" "}
                                <BiSolidCheckCircle
                                  className="icon"
                                  id="appointment.reportstatus"
                                  style={{ backgroundColor: "green" }}
                                  value="APPROVED"
                                  onClick={(e) =>
                                    rsubmit(e, appointment.id, "APPROVED")
                                  }
                                />
                                &ensp;
                                <BiSolidXCircle
                                  className="icon"
                                  value="REJECTED"
                                  style={{ backgroundColor: "red" }}
                                  id="appointment.reportstatus"
                                  onClick={(e) =>
                                    rsubmit(e, appointment.id, "REJECTED")
                                  }
                                />
                              </td>
                            ) : (
                              <td id="icon" value={appointment.reportstatus}>
                                {" "}
                                {appointment.reportstatus}
                              </td>
                            )}
                          </>
                        ) : (
                          "No Report Added"
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        {isUploadModalOpen && (
          <UploadReport
            isOpen={isUploadModalOpen}
            onRequestClose={handleCloseUploadModal}
            empId={selectedAppointmentId}
          />
        )}

{isTestsModalOpen && (
<TestForPackage
        isOpen={isTestsModalOpen}
        onRequestClose={closeDenominationModal}
        testDetails={tests}
      />
)}
        {loader && <div className="loading"></div>}
      </div>
    </div>
    </div>
  );
}

export default EmpAppointments;
