
import logo from "../images/tmplLogo.png";

function Termsandcondition() {
  return (
    <>
      <div className="col-xl-12 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4 ">
            <div className="col-xl-12 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div
                  className="card-body p-4 "
                  style={{ backgroundColor: "blue" }}
                >
                  <div
                    className="card-title d-flex"
                    style={{
                      fontSize: "25px",
                    }}
                  >
                    {" "}
                    <img
                      class="mb-3"
                      src={logo}
                      alt="..."
                      style={{ height: "40px", position: "relative" }}
                    />
                    <h4
                      style={{
                        position: "relative",
                        left: "430px",
                        top: "20px",
                        color: "white",
                      }}
                    >
                      Terms & Conditions
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <h4>1. INTRODUCTION</h4>
            <h6>
              {" "}
              Welcome to Timespan Management Private Limited! These Terms and Conditions
              ("Terms") govern your use of our website located at
              timespan.in and our services. Please read these Terms
              carefully. By accessing our site or using our services, you agree
              to be bound by these Terms and all terms incorporated by
              reference. If you do not agree to all of these terms, do not use
              our site or services.
            </h6>{" "}
            <h4> 2. SERVICES </h4>
            <h6>
              We provide various IT products and services through our website.
              In order to use these services, you may be required to provide
              personal details or other information.
            </h6>{" "}
            <h4> 3. USER OBLIGATIONS</h4>
            <h6>
              By using this website and our services, you warrant that all data
              provided by you is accurate and up-to-date. Use of personal
              information submitted to or via the Site is governed by our
              Privacy Policy.
            </h6>{" "}
            <h4> 4. INTELLECTUAL PROPERTY</h4>
            <h6>
              {" "}
              The Company owns all intellectual property rights in the website
              and in the material published on it. These works are protected by
              copyright laws worldwide. All such rights are reserved.
            </h6>{" "}
            <h4> 5. LIMITATION OF LIABILITY</h4>
            <h6>
              {" "}
              Our liability for direct losses arising out of our negligence,
              breach of contract or any other cause of action arising out of or
              in connection with these Terms shall be limited to the total price
              of your Order. In no event shall we be liable for any loss of
              profits, goodwill, loss of business, loss of data or any other
              indirect or consequential loss or damage whatsoever.
            </h6>{" "}
            <h4> 6. CHANGES TO TERMS AND CONDITIONS</h4>
            <h6>
              {" "}
              We reserve the right, at our discretion, to modify these Terms at
              any time. Changes will be posted on the website with a new
              effective date. Your continued use of our Services following the
              posting of changes will mean you accept those changes.
            </h6>{" "}
            <h4> 7. GOVERNING LAWS AND JURISDICTION </h4>
            <h6>
              These Terms are governed by the laws of India. Any disputes
              arising in connection with these terms shall be subject to the
              exclusive jurisdiction of the Courts of India.
            </h6>{" "}
            <h4>8. CONTACT US</h4>
            <h6>
              {" "}
              If you have any questions about these Terms, please contact us at:
              info@timespan.in
            </h6>
          </div>
        </div>{" "}
      </div>
    </>
  );
}
export default Termsandcondition;
